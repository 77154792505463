// import { data } from 'autoprefixer';
import jwtDecode from "jwt-decode";

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : "https://modate-backend.herokuapp.com/";

export const getRefreshToken = async (
  token,
  setLoggedIn,
  setToken,
  navigate
) => {
  console.log("getting refresh token");
  try {
    const { exp } = jwtDecode(token);
    if (Date.now() >= exp * 1000) {
      const response = await fetch(`${url}api/user/refresh_token`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      });

      const responeJson = await response.json();

      if (responeJson.status === "success") {
        console.log("setting new token because old one was expired");
        setLoggedIn(true);
        setToken(responeJson.token);
      } else {
        console.log(responeJson);
        setLoggedIn(false);
        setToken("");
        navigate("/");
      }
    } else {
      console.log("token is good");
    }
  } catch (error) {
    try {
      const response = await fetch(`${url}api/user/refresh_token`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      });

      const responeJson = await response.json();

      if (responeJson.status === "success") {
        console.log("setting new token because old one was expired");
        setLoggedIn(true);
        setToken(responeJson.token);
      } else {
        console.log(responeJson);
        setLoggedIn(false);
        setToken("");
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const login = async (
  data,
  setToken,
  setLoggedIn,
  setFormError,
  navigate
) => {
  try {
    const register = await fetch(`${url}api/user/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });
    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      setToken(response.token);
      setLoggedIn(true);
      if (response.isConnector) {
        navigate("/dashboard/connectorDashboard");
      } else if (response.isModater) {
        navigate("/dashboard/modaterDashboard");
      } else {
        navigate("/");
      }
    } else {
      setFormError(response.error);
    }
  } catch (error) {
    console.log(error);
  }
};

export const logout = async (
  setLoggedIn,
  setUser,
  setToken,
  setIsOpen,
  navigate,
  setMatches
) => {
  try {
    const register = await fetch(`${url}api/user/logout`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // 'auth-token': `bearer ${token}`,
      },
      credentials: "include",
    });

    const response = await register.json();

    if (response.status === "success") {
      setLoggedIn(false);
      setUser(null);
      setToken("");
      navigate("/");
      setIsOpen(false);
      setMatches([]);
    } else {
      console.log("couldnt log out");
    }
  } catch (error) {
    console.log(error);
  }
};

export const registerConnector = async (
  data,
  setError,
  setDisabled,
  navigate,
  token
) => {
  console.log(data);
  console.log(token);

  try {
    setDisabled(true);
    const register = await fetch(`${url}api/user/registerConnector`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify(data),
    });
    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      navigate("/dashboard/connectorDashboard");
      setDisabled(false);
    } else {
      setError((error) => [...error, response]);
      setDisabled(false);
    }
  } catch (error) {
    console.log(error);
  }
};

export const registerUser = async (
  data,
  setToken,
  setLoggedIn,
  setFormError,
  navigate,
  route,
  setDisabled
) => {
  try {
    setDisabled(true);
    const register = await fetch(`${url}api/user/register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ email: data.email, password: data.password }),
    });
    const response = await register.json();

    if (response.status === "success") {
      setToken(response.token);
      setLoggedIn(true);
      navigate(route);
    } else {
      setFormError(response.error);
      setDisabled(false);
    }
  } catch (error) {
    console.log(error);
  }
};

export const forgotPassword = async (
  data,
  setDisabled,
  setFormError,
  setSuccess
) => {
  try {
    setDisabled(true);
    const register = await fetch(`${url}api/user/forgotPassword`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ email: data.email }),
    });
    const response = await register.json();

    if (response.status === "success") {
      setFormError(null);
      setSuccess(true);
    } else {
      setFormError(response.error);
      setDisabled(false);
      setSuccess(false);
    }
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (token, data, setFormError, setSuccess) => {
  try {
    const register = await fetch(`${url}api/user/resetPassword/${token}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ password: data.password }),
    });
    const response = await register.json();

    if (response.status === "success") {
      setFormError(null);
      setSuccess(true);
    } else {
      setSuccess(false);
      setFormError(response.error);
    }
  } catch (error) {
    console.log(error);
  }
};

// Admin Routes
export const getAllUserActions = async (
  setActions,
  pageNumber,
  _isMounted,
  token
) => {
  try {
    const register = await fetch(`${url}api/admin?page=${pageNumber}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setActions(response.data);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMatchesStats = async (
  setStats,
  setStatsLoading,
  _isMounted,
  token
) => {
  try {
    setStatsLoading(true);
    const register = await fetch(`${url}api/admin/matchStats`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setStats(response.data);
      setStatsLoading(false);
    } else {
      console.log(response);
      setStatsLoading(false);
    }
  } catch (error) {
    console.log(error);
    setStatsLoading(false);
  }
};

export const getAllConnectorsAdmin = async (
  setConnectors,
  pageNumber,
  _isMounted,
  token
) => {
  try {
    const register = await fetch(
      `${url}api/admin/connectors?page=${pageNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      console.log(response);
      setConnectors(response.data);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllConnectorsToBeVerifiedAdmin = async (
  setConnectors,
  pageNumber,
  _isMounted,
  token
) => {
  try {
    const register = await fetch(
      `${url}api/admin/connectorsToBeVerified?page=${pageNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      console.log(response);
      setConnectors(response.data);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getConnectorOptionsAdmin = async (input, _isMounted, token) => {
  return new Promise(async (resolve) => {
    const register = await fetch(
      `${url}api/admin/connectors?name=${input.trim()}&connectorsOnly=${true}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();
    // console.log(response)
    if (!_isMounted.current) return;
    if (response.status === "success") {
      resolve(
        response.data.map((connector) => {
          return {
            value: connector._id,
            label: `${connector.firstName} ${connector.lastName}`,
          };
        })
      );
    } else {
      console.log(response);
    }
  });
};

export const getModaterOptionsAdmin = async (
  input,
  flag,
  _isMounted,
  token
) => {
  return new Promise(async (resolve) => {
    const register = await fetch(
      `${url}api/admin/matches?name=${input.trim()}&modatersOnly=${true}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();
    console.log(response.data)
    if (!_isMounted.current) return;
    if (response.status === "success") {
      if (flag === "DELETE") {
        resolve(
          response.data.map((modater) => {
            return {
              value: modater._id,
              label: `${modater.firstName} ${modater.lastName}`,
              img: modater.photoUrls[0]?.url,
            };
          })
        );
      } else {
        resolve(
          response.data.map((modater) => {
            return {
              value: modater._id,
              label: `${modater.firstName} ${modater.lastName}`,
              img: modater.photoUrls[0]?.url,
            };
          })
        );
      }
    } else {
      console.log(response);
    }
  });
};

export const getConnectorAdmin = async (
  setQueryResult,
  name,
  _isMounted,
  token
) => {
  try {
    const register = await fetch(
      `${url}api/admin/connectors?name=${name}&connectorsOnly=${false}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setQueryResult(response.data);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getModaterAdmin = async (
  setQueryResult,
  name,
  _isMounted,
  activatedMatchesBool,
  token
) => {
  try {
    const register = await fetch(
      `${url}api/admin/matches?name=${name}&modatersOnly=${false}&activated=${activatedMatchesBool}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setQueryResult(response.data);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getNumberOfActionPages = async (
  setTotalPages,
  setCurrentPage,
  _isMounted,
  token
) => {
  try {
    const register = await fetch(`${url}api/admin/numActionsPages`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setTotalPages(response.numberOfPages);
      setCurrentPage(
        +localStorage.getItem("currentActionPage") || response.numberOfPages
      );
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getNumberOfConnectorPages = async (
  setTotalPages,
  setCurrentPage,
  _isMounted,
  token
) => {
  try {
    const register = await fetch(`${url}api/admin/numConnectorPages`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();
    // console.log(response)
    if (!_isMounted.current) return;
    if (response.status === "success") {
      setTotalPages(response.numberOfPages);
      setCurrentPage(
        +localStorage.getItem("currentConnectorPage") || response.numberOfPages
      );
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getNumberOfConnectorToBeVerifiedPages = async (
  setTotalPages,
  setCurrentPage,
  _isMounted,
  token
) => {
  try {
    const register = await fetch(`${url}api/admin/numConnectorToBeVerifiedPages`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();
    // console.log(response)
    if (!_isMounted.current) return;
    if (response.status === "success") {
      setTotalPages(response.numberOfPages);
      setCurrentPage(
        +localStorage.getItem("currentConnectorVerifiedPage") || response.numberOfPages
      );
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};


export const getNumberOfMatchesPages = async (
  setTotalPages,
  setCurrentPage,
  _isMounted,
  dropDownSelected,
  isFiltering,
  setModaterStatusTotals,
  token
) => {
  try {
    const register = await fetch(
      `${url}api/admin/numMatchesPages?q=${dropDownSelected}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();
    // console.log(response)
    if (!_isMounted.current) return;
    if (response.status === "success") {
      setModaterStatusTotals({
        active: response.numActiveModaters,
        inactive: response.numInactiveModaters,
        dating: response.numDatingModaters,
      });
      setTotalPages(response.numberOfPages);
      setCurrentPage(
        +localStorage.getItem(
          !isFiltering ? "currentMatchesPage" : "currentMatchesFilterPage"
        ) <= response.numberOfPages
          ? +localStorage.getItem(
              !isFiltering ? "currentMatchesPage" : "currentMatchesFilterPage"
            )
          : response.numberOfPages
      );
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteTotalAccount = async (
  accountId,
  page,
  activatedMatchesBool,
  setToken,
  setModal,
  setUserToBeDeleted,
  setDisabled,
  setError,
  setSuccess,
  _isMounted,
  token
) => {
  try {
    setDisabled(true);
    const register = await fetch(
      `${url}api/admin/deleteAccount?accountId=${accountId}&page=${page}&activated=${activatedMatchesBool}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      console.log(response);
      setDisabled(false);
      setModal(false);
      setUserToBeDeleted(null);
      setSuccess("Account was successfully deleted");
      setToken(response.token);
    } else {
      console.log(response);
      setDisabled(false);
      setError((error) => [...error, response]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllUserTotals = async (
  setTotals,
  setTotalsLoading,
  _isMounted,
  token
) => {
  try {
    setTotalsLoading(true);
    const register = await fetch(`${url}api/admin/totals`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setTotals(response.data);
      setTotalsLoading(false);
    } else {
      console.log(response);
      setTotalsLoading(false);
    }
  } catch (error) {
    console.log(error);
    setTotalsLoading(false);
  }
};

export const getAllModaterMatches = async (
  setModaterMatches,
  pageNumber,
  dropDownSelected,
  _isMounted,
  activatedMatchesBool,
  controller,
  setIsLoading,
  token
) => {
  console.log(dropDownSelected);
  try {
    setIsLoading(true);
    const register = await fetch(
      `${url}api/admin/matches?page=${pageNumber}&activated=${activatedMatchesBool}&q=${dropDownSelected}`,
      {
        signal: controller.signal,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setIsLoading(false);
      setModaterMatches(response.data);
    } else {
      setIsLoading(false);
      console.log(response);
    }
  } catch (error) {
    setIsLoading(false);
    console.log(error);
  }
};

export const getMostRecentLikedMatches = async (
  setData,
  setMostRecentLikesLoading,
  _isMounted,
  token
) => {
  try {
    setMostRecentLikesLoading(true);
    const register = await fetch(`${url}api/admin/matches/mostRecentLiked`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setData(response.data);
      setMostRecentLikesLoading(false);
    } else {
      console.log(response);
      setMostRecentLikesLoading(false);
    }
  } catch (error) {
    console.log(error);
    setMostRecentLikesLoading(false);
  }
};

export const createMatchBetweenModaters = async (
  data,
  setError,
  setSuccess,
  setModal,
  setLoading,
  setToken,
  token
) => {
  try {
    setLoading(true);
    const register = await fetch(`${url}api/admin/matches/createMatch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify(data),
    });
    const response = await register.json();

    if (response.status === "success") {
      setSuccess("Successfully Created Match");
      setModal(false);
      setLoading(false);
      setToken(response.token);
    } else {
      setError((error) => [...error, response]);
      setLoading(false);
    }
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const connectorCreateMatchBetweenModaters = async (
  data,
  setError,
  setSuccess,
  setDisabled,
  setStep,
  setSuggestions,
  token
) => {
  try {
    setDisabled(true);
    const register = await fetch(`${url}api/user/matches/createMatch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify(data),
    });
    const response = await register.json();

    if (response.status === "success") {
      setSuccess("Successfully Created Match");
      setDisabled(false);
      setSuggestions((prevSuggestions) => {
        return {
          details: prevSuggestions.details,
          suggestions: prevSuggestions.suggestions.filter(
            (match) => match.modater._id !== data.modaterIds[1]
          ),
        };
      });
      setStep(1);
    } else {
      setError((error) => [...error, response]);
      setDisabled(false);
    }
  } catch (error) {
    console.log(error);
    setDisabled(false);
  }
};

export const cancelSuggestionForModater = async (
  data,
  setError,
  setSuccess,
  setDisabled,
  setSuggestions,
  setSelectedMatch,
  token
) => {
  try {
    setDisabled(true);
    const register = await fetch(`${url}api/user/matches/cancelSuggestion`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify(data),
    });
    const response = await register.json();

    if (response.status === "success") {
      setSuccess("Successfully deleted suggestion");
      setDisabled(false);
      setSuggestions((prevSuggestions) => {
        return {
          details: prevSuggestions.details,
          suggestions: prevSuggestions.suggestions.filter(
            (match) => match.modater._id !== data.modaterIds[1]
          ),
        };
      });
      setSelectedMatch(null);
    } else {
      setError((error) => [...error, response]);
      setDisabled(false);
    }
  } catch (error) {
    console.log(error);
    setDisabled(false);
  }
};

export const getAllModaterDates = async (
  setCurrentDates,
  setCurrentDatesLoading,
  _isMounted,
  token
) => {
  try {
    setCurrentDatesLoading(true);
    const register = await fetch(`${url}api/admin/modaterDates`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setCurrentDates(response.data);
      setCurrentDatesLoading(false);
    } else {
      console.log(response);
      setCurrentDatesLoading(false);
    }
  } catch (error) {
    console.log(error);
    setCurrentDatesLoading(false);
  }
};

export const getAggregateLikesForModater = async (
  setData,
  setAggregateLikesLoading,
  _isMounted,
  token
) => {
  try {
    setAggregateLikesLoading(true);
    const register = await fetch(`${url}api/admin/aggregateLikes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setData(response.data);
      setAggregateLikesLoading(false);
    } else {
      console.log(response);
      setAggregateLikesLoading(false);
    }
  } catch (error) {
    console.log(error);
    setAggregateLikesLoading(false);
  }
};

export const getAllPastModaterDates = async (
  setPastDates,
  setPastDatesLoading,
  _isMounted,
  token
) => {
  try {
    setPastDatesLoading(true);
    const register = await fetch(`${url}api/admin/modaterPastDates`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setPastDates(response.data);
      setPastDatesLoading(false);
    } else {
      console.log(response);
      setPastDatesLoading(false);
    }
  } catch (error) {
    console.log(error);
    setPastDatesLoading(false);
  }
};

export const getAllUserSignUps = async (
  setSignUps,
  setSignUpsLoading,
  _isMounted,
  token
) => {
  try {
    setSignUpsLoading(true);
    const register = await fetch(`${url}api/admin/dailySignUps`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setSignUps(response.data);
      setSignUpsLoading(false);
    } else {
      console.log(response);
      setSignUpsLoading(false);
    }
  } catch (error) {
    console.log(error);
    setSignUpsLoading(false);
  }
};

// User Routes
export const getUser = async (setUser, token) => {
  try {
    console.log("getting my Profile");
    const register = await fetch(`${url}user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (response.status === "success") {
      console.log("setting new users");
      setUser(response.user);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMatchesSuggestionForModater = async (
  setSuggestions,
  setLoading,
  id,
  token
) => {
  try {
    console.log("getting modater suggestions");
    setLoading(true);
    setSuggestions(null);
    const register = await fetch(`${url}users/suggestions?id=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (response.status === "success") {
      console.log("setting suggestions");
      setSuggestions(response.result);
    } else {
      console.log(response);
    }
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const getModatersMatches = async (setModaters, token) => {
  try {
    console.log("getting modaters matches");
    const register = await fetch(`${url}users/matches`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (response.status === "success") {
      console.log("setting modater matches");
      setModaters(response.results);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getStatusOfMatchesConnector = async (setMatches, token) => {
  try {
    console.log("getting matches status");
    const register = await fetch(`${url}users/activatedMatches`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (response.status === "success") {
      console.log("setting matches status");
      setMatches(response.results);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getStatusOfMatchesModater = async (
  setMatches,
  setMatchesLoading,
  token
) => {
  try {
    const register = await fetch(`${url}users/modater/matches/status`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    setMatchesLoading(true);
    const response = await register.json();

    if (response.status === "success") {
      console.log("setting matches status");
      setMatches(response.data);
    } else {
      console.log(response);
    }
    setMatchesLoading(false);
  } catch (error) {
    console.log(error);
    setMatchesLoading(false);
  }
};

export const getConnectorById = async (setConnector, connectorId, token) => {
  try {
    const register = await fetch(`${url}users/connector/${connectorId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (response.status === "success") {
      setConnector(response.connector);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getModatersUnreviewedMatches = async (
  setUnreviewedMatches,
  _isMounted,
  token
) => {
  try {
    console.log("getting modaters unreviewed matches");
    const register = await fetch(`${url}users/unreviewedActiveMatches`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();
    console.log(response);

    if (!_isMounted.current) return;
    if (response.status === "success") {
      console.log("setting modater unreviewed matches");
      setUnreviewedMatches(response.results);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMyModaters = async (
  setModater,
  _isMounted,
  setLoading,
  token
) => {
  try {
    setLoading(true);
    const register = await fetch(`${url}users/connnector/modaters`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setModater(response.data);
      setLoading(false);
    } else {
      console.log(response);
      setLoading(false);
    }
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const getMyConnectorVerificationStatus = async (
  setIsVerifiedConnector,
  _isMounted,
  token
) => {
  try {
    const register = await fetch(`${url}users/connector/verificationStatus`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) return;
    if (response.status === "success") {
      setIsVerifiedConnector(response.verificationStatus === 'VERIFIED');
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllConnectors = async (input, _isMounted, token) => {
  return new Promise(async (resolve) => {
    const register = await fetch(
      `${url}users/connectors?name=${input.trim()}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      }
    );
    const response = await register.json();
    // console.log(response)
    if (!_isMounted.current) return;
    if (response.status === "success") {
      resolve(
        response.connectors.map((connector) => {
          return {
            value: connector._id,
            label: `${connector.firstName} ${connector.lastName}`,
          };
        })
      );
    } else {
      console.log(response);
    }
  });
  // try {
  //     console.log('getting all Connectors')
  //     // console.log(token)
  //     const register = await fetch(`${url}users/connectors`, {
  //         method: 'GET',
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'auth-token': `bearer ${token}`,
  //         },
  //         credentials: 'include',
  //     })
  //     const response = await register.json();

  //     if (!componentIsMounted.current) {
  //         return
  //     }
  //     if (response.status === 'success') {
  //         console.log('setting connectors')
  //         setConnectors(response.connectors)
  //     }
  //     else {
  //         console.log(response)
  //     }
  // } catch (error) {
  //     console.log(error)
  // }
};

export const getUnverifiedModaters = async (
  setUnverified,
  _isMounted,
  token
) => {
  try {
    console.log("getting unverified Modaters");
    const register = await fetch(`${url}users/connnector/unverifiedModaters`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();
    console.log(response);

    if (!_isMounted.current) return;
    if (response.status === "success") {
      console.log("setting unverified Modaters");
      setUnverified(response.data);
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getModaterStageMatches = async (
  setMatches,
  setMatchesLoading,
  _isMounted,
  token
) => {
  try {
    console.log("getting matches that are at the modater stage");
    setMatchesLoading(true);
    const register = await fetch(`${url}users/modater/matches`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (!_isMounted.current) {
      console.log("not mounnted returning");
      setMatchesLoading(false);
      return;
    }
    if (response.status === "success") {
      console.log("setting matches at modater stage");
      setMatches(response.matchesForModater);
    } else {
      console.log(response);
    }
    setMatchesLoading(false);
  } catch (error) {
    console.log(error);
    setMatchesLoading(false);
  }
};

export const getCurrentDate = async (setDate, setError, token) => {
  try {
    console.log("getting modater that im currently dating");
    const register = await fetch(`${url}users/modater/currentDate`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      console.log("setting current date");
      setDate(response.currentDate);
    } else {
      setError((error) => [...error, response]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMatchById = async (
  id,
  setMatches,
  setError,
  _isMounted,
  navigate,
  token
) => {
  try {
    console.log("getting match by id");
    const register = await fetch(`${url}users/modater/match?id=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    if (_isMounted.current) {
      console.log(response);
      if (response.status === "success") {
        console.log("setting matche by id");
        setMatches(response.match);
      } else {
        if (response.status === "error") {
          setError((error) => [...error, response]);
          navigate("/dashboard/modaterDashboard");
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getModater = async (id, setModater, setError, navigate, token) => {
  try {
    console.log(`getting modater ${id}`);
    const register = await fetch(`${url}users/modater?id=${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();

    console.log(response);
    if (response.status === "success") {
      console.log("setting modater by id");
      setModater(response.user);
    } else {
      setError((error) => [...error, response]);
      navigate("/dashboard/connectorDashboard");
    }
  } catch (error) {
    console.log(error);
  }
};

export const registerModater = async (
  data,
  files,
  setDisabled,
  navigate,
  setUser,
  setError,
  setSuccess,
  token
) => {
  try {
    console.log("registering new modater");
    setDisabled(true);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("image", files[i]);
    }

    function buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    }
    buildFormData(formData, data);

    const register = await fetch(`${url}api/user/registerModater`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        // 'Content-Type': 'application/json',
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
      body: formData,
    });

    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      navigate("/dashboard/modaterDashboard");
    } else {
      setDisabled(false);
      setError((error) => [...error, response]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const editModater = async (
  data,
  files,
  setDisabled,
  navigate,
  setUser,
  setError,
  setSuccess,
  token
) => {
  try {
    console.log("updating modaters info");
    setDisabled(true);

    console.log(data);
    console.log(files);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("image", files[i]);
    }

    function buildFormData(formData, data, parentKey) {
      // console.log(data, parentKey)

      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data === null ? "" : data;

        formData.append(parentKey, value);
      }
    }
    buildFormData(formData, data);

    const register = await fetch(`${url}api/user/updateModaterProfile`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
      body: formData,
    });

    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      setUser(response.user);
      setSuccess("Your Information was Successfully Updated!");
      navigate("/dashboard/profile");
    } else {
      setDisabled(false);
      setError((error) => [...error, response]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const deletePhoto = async (
  key,
  setDisabled,
  setModal,
  setUser,
  setSuccess,
  token
) => {
  try {
    console.log("deleting photo");
    setDisabled(true);

    const register = await fetch(`${url}api/user/deletePhoto?key=${key}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      setUser(response.user);
      setModal((prev) => !prev);
      setDisabled(false);
      setSuccess("Photo Has Been Successfully Deleted.");
    } else {
      setDisabled(false);
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteMyAccount = async (
  setDisabled,
  setModal,
  setError,
  navigate,
  setLoggedIn,
  token
) => {
  try {
    setDisabled(true);
    const register = await fetch(`${url}api/user/deleteAccount`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });
    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      setModal((prev) => !prev);
      setDisabled(false);
      setLoggedIn(false);
      navigate("/");
    } else {
      setDisabled(false);
      setError((error) => [...error, response]);
    }
  } catch (error) {
    console.log(error);
    setDisabled(false);
  }
};

export const confirmModater = async (
  data,
  id,
  reset,
  setSelected,
  setDisabled,
  setToken,
  setError,
  setSuccess,
  token
) => {
  try {
    console.log("confirming modater");
    setDisabled(true);

    const newData = {
      ...data,
      reviewed: true,
    };

    const register = await fetch(`${url}api/user/confirmModater?id=${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify(newData),
    });

    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      console.log("confirmed modater");
      reset({});
      setSelected([]);
      setDisabled(false);
      setToken(response.token);
      setSuccess("Successfully Confirmed Modater");
    } else {
      setDisabled(false);
      setError((error) => [...error, response]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateExtraInfo = async (
  data,
  id,
  setDisabled,
  setModaters,
  setModater,
  setError,
  setExtraInfo,
  setSuccess,
  token
) => {
  try {
    console.log("upadating extraInfo");
    setDisabled(true);

    const newData = {
      ...data,
      reviewed: true,
    };

    //weird cors issue when using 'Content-Type': 'application/json'
    const register = await fetch(`${url}api/user/updateExtraInfo?id=${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify(newData),
    });

    const response = await register.json();
    console.log(response);

    if (response.status === "success") {
      console.log("updated extra info");
      setDisabled(false);
      setModaters(response.data);
      setModater(response.data.filter((modater) => modater._id === id)[0]);
      setExtraInfo((prev) => !prev);
      setSuccess("Success. You updated Your MoDaters Info!");
    } else {
      setDisabled(false);
      setError((error) => [...error, response]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const rejectModater = async (
  modaterProfileId,
  reset,
  setSelected,
  setDisabled,
  setModal,
  setToken,
  setError,
  setSuccess,
  token
) => {
  console.log("rejecting modater");
  setDisabled(true);

  const register = await fetch(
    `${url}api/user/rejectModater?id=${modaterProfileId}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    }
  );

  const response = await register.json();
  console.log(response);

  if (response.status === "success") {
    reset({});
    setSelected([]);
    setModal(false);
    setDisabled(false);
    setToken(response.token);
    setSuccess("Successfully Rejected Modater");
  } else {
    setDisabled(false);
    setError((error) => [...error, response]);
  }
};

export const connectorRejectMatchAfterExpiration = async (
  matchId,
  setMatches,
  setModal,
  setDisabled,
  setSuccess,
  setError,
  token
) => {
  console.log("rejecting match");

  setDisabled(true);

  const register = await fetch(`${url}api/user/rejectMatch?id=${matchId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "auth-token": `bearer ${token}`,
    },
    credentials: "include",
  });

  const response = await register.json();
  console.log(response);

  if (response.status === "success") {
    console.log("rejectingMatch");
    setMatches(response.results);
    setModal(false);
    setDisabled(false);
    setSuccess("Successfully Canceled Match!");
  } else {
    setError((error) => [...error, response]);
    setDisabled(false);
  }
};

export const connectorRateMatch = async (
  matchId,
  rating,
  setUnreviewedMatches,
  cardIndex,
  setCardIndex,
  numMatches,
  setDisabled,
  setError,
  setSuccess,
  token
) => {
  console.log("reviewing match");
  setDisabled(true);

  const register = await fetch(
    `${url}api/user/reviewMatch?matchId=${matchId}&rating=${rating}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    }
  );

  const response = await register.json();
  console.log(response);

  if (response.status === "success") {
    // console.log('heere');
    if (cardIndex >= numMatches - 1) {
      console.log("in if");
      setCardIndex((prev) => prev - 1);
    }
    setDisabled(false);
    console.log("seeting new results");
    setUnreviewedMatches(response.results);
    setSuccess("Successfully Rated Match for MoDater");
  } else {
    setError((error) => [...error, response]);
    setDisabled(false);
  }
};

export const reviewMatchByModater = async (
  matchId,
  liked,
  setUser,
  setDisabled,
  setError,
  navigate,
  setIsAMatch,
  setModal,
  setReviewed,
  setLikedMatch,
  token
) => {
  console.log("reviewing match");
  setDisabled(true);

  const register = await fetch(
    `${url}api/user/modater/rateMatch?matchId=${matchId}&liked=${liked}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    }
  );

  const response = await register.json();
  console.log(response);

  if (response.status === "success") {
    console.log("rated match");
    setDisabled(false);
    if (response.matched) {
      setIsAMatch(true);
      setModal(false);
      setTimeout(() => {
        setUser(response.user);
        navigate("/dashboard/modaterDashboard");
      }, 1500);
    } else {
      setModal(false);
      setReviewed(true);
      setLikedMatch(liked);
      setTimeout(() => {
        console.log("navigating");
        setUser(response.user);
        navigate("/dashboard/modaterDashboard/matches");
      }, 1500);
    }
  } else {
    console.log(response);
    setDisabled(false);
    setError((error) => [...error, response]);
    navigate("/dashboard/modaterDashboard");
  }
};

export const sendVerificationRequestOfConnector = async (
  connectorId,
  setUser,
  setSuccess,
  setError,
  token
) => {
  console.log("sending verification request for connector");
   const register = await fetch(`${url}api/user/sendVerificationRequest`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "auth-token": `bearer ${token}`,
    },
    credentials: "include",
  });

  const response = await register.json();

  if (response.status === "success") {
      console.log("successfully set connector validation to pending");
      setUser(response.user);
      setSuccess("Successfully Sent Verification Request");
  } else {
    // console.log(response);
    setError((error) => [...error, response]);
  } 
}

export const verifyConnector = async (
  connectorId,
  connectors,
  setConnectors,
  setSuccess,
  setError,
  token
) => {
  console.log("verifying connector");
   const register = await fetch(`${url}api/admin/verifyConnector?id=${connectorId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "auth-token": `bearer ${token}`,
    },
    credentials: "include",
  });

  const response = await register.json();

  if (response.status === "success") {
      console.log("Successfully Verified Connector!");
      setConnectors(connectors.filter( connector => connector.connector._id !== connectorId));
      setSuccess("Successfully Verified Connector!");
  } else {
    // console.log(response);
    setError((error) => [...error, response]);
  } 
}

export const changeActiveStatus = async (
  setUser,
  setSuccess,
  setError,
  token
) => {
  console.log("changing active status");

  const register = await fetch(`${url}api/user/changeActiveStatus`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "auth-token": `bearer ${token}`,
    },
    credentials: "include",
  });

  const response = await register.json();

  if (response.status === "success") {
    console.log("seeting new results");
    setUser(response.user);
    setSuccess("Successfully Changed Active Status");
  } else {
    // console.log(response);
    setError((error) => [...error, response]);
  }
};

export const changeActiveStatusOfModater = async (
  id,
  setModaters,
  setModater,
  setModal,
  setError,
  setSuccess,
  setDisabled,
  token
) => {
  console.log("changing active status of modater");
  setDisabled(true);

  const register = await fetch(
    `${url}api/user/changeActiveStatusOfModater?id=${id}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    }
  );

  const response = await register.json();
  // console.log(response)

  if (response.status === "success") {
    console.log("seting new results");
    setModaters(response.modaters);
    setModater(response.modaters.filter((modater) => modater._id === id)[0]);
    setModal((prev) => !prev);
    setSuccess("Success. You Changed Your MoDaters Active Status");
    setDisabled(false);
  } else {
    setError((error) => [...error, response]);
    setDisabled(false);
  }
};

export const switchConnectors = async (
  id,
  setUser,
  setModal,
  setDisabled,
  setChangeConnector,
  setSuccess,
  token
) => {
  console.log("switching connector for modater");
  setDisabled(true);

  const register = await fetch(`${url}api/user/switchConnectors?id=${id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "auth-token": `bearer ${token}`,
    },
    credentials: "include",
  });

  const response = await register.json();

  if (response.status === "success") {
    console.log("setting new token after switching connectors");
    setUser(response.user);
    setModal((prev) => !prev);
    setDisabled(false);
    setChangeConnector(false);
    setSuccess("Successfully Sent Request To Change Connectors");
  } else {
    setDisabled(false);
    console.log(response);
  }
};

export const cancelConnectorSwitch = async (
  setUser,
  setDisabled,
  setError,
  setSuccess,
  token
) => {
  console.log("cancelling Connector switch");
  setDisabled(true);

  const register = await fetch(`${url}api/user/cancelConnectorSwitch`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "auth-token": `bearer ${token}`,
    },
    credentials: "include",
  });

  const response = await register.json();

  if (response.status === "success") {
    console.log("cancelling switching connectors");
    setUser(response.user);
    setDisabled(false);
    setSuccess("Successfully Canceled Connector Switch");
  } else {
    setDisabled(false);
    setError((error) => [...error, response]);
  }
};

export const breakUpWithMatch = async (
  data,
  setUser,
  setDisabled,
  setError,
  setSuccess,
  token
) => {
  console.log("breaking up with modater");
  setDisabled(true);

  const register = await fetch(`${url}api/user/breakUp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "auth-token": `bearer ${token}`,
    },
    credentials: "include",
    body: JSON.stringify({
      period: data.period,
      reason: data.reason,
    }),
  });

  const response = await register.json();
  console.log(response);

  if (response.status === "success") {
    console.log("brokeUp with modater");
    setUser(response.user);
    setSuccess("Successfully Ended Things With MoDater");
  } else {
    setDisabled(false);
    setError((error) => [...error, response]);
  }
};

export const checkIfLoggedIn = async (token) => {
  try {
    const { exp } = jwtDecode(token);
    if (Date.now() >= exp * 1000) {
      const response = await fetch(`${url}api/user/refresh_token`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      });

      const responeJson = await response.json();

      if (responeJson.status === "success") {
        return true;
      } else {
        return false;
      }
    } else {
      console.log("you are logged In");
      return true;
    }
  } catch (error) {
    const response = await fetch(`${url}api/user/refresh_token`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": `bearer ${token}`,
      },
      credentials: "include",
    });

    const responeJson = await response.json();

    if (responeJson.status === "success") {
      return true;
    } else {
      return false;
    }
  }
};

export const getRefreshTokenBeforeApiCall = async (
  token,
  setToken,
  setLoggedIn,
  navigate,
  cb,
  args
) => {
  try {
    const { exp } = jwtDecode(token);
    // if we decode the jwt and it expired then we want to get a new access token
    if (Date.now() >= exp * 1000) {
      const response = await fetch(`${url}api/user/refresh_token`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": `bearer ${token}`,
        },
        credentials: "include",
      });

      const responeJson = await response.json();

      if (responeJson.status === "success") {
        console.log("setting new token because old one was expired");
        setToken(responeJson.token);
        return await cb(...args, responeJson.token);
      }
      // either missing, stale, or tampered with refresh token was used so we force a logout
      else {
        console.log(responeJson);
        setLoggedIn(false);
        setToken("");
        navigate("/");
      }
    }
    // tihs is the case when we have a good token that hasn't expired yet.
    else {
      console.log("token is good");
      return await cb(...args, token);
    }
  } catch (error) {
    // this is the case when we have an invalid token and weren't able to decode it
    console.log("Invalid Token");
  }
};
