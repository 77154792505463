import React, { useEffect, useState } from 'react'
import '../Components/nav.css'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function Home() {

    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        const safari = window['safari'];
        setIsSafari(safari)
    }, [])

    return (
        <div className="w-full h-full text-white">
            <div className="w-full md:h-1/2 flex flex-col md:flex-row">
                <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} transition={{ delay: 0 }} className="w-full py-8 h-full flex justify-center items-center bg-gray-800 card px-20 text-center tracking-wider leading-relaxed md:leading-loose text-4xl">
                    The Mutual Friend You Wish You Had
                </motion.div>
                {isSafari ?
                    <motion.img src="https://modate-photos.s3.amazonaws.com/New+MoDate+Video.mp4" className="w-full md:w-1/2"/> :
                    <motion.video initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0}} transition={{ delay: .2 }} loop autoPlay controls muted className="w-full md:w-1/2 h-full object-cover">
                        <source src="https://modate-photos.s3.amazonaws.com/New+MoDate+Video.mp4" type="video/mp4"/>
                    </motion.video>
                }
            </div>

            <motion.div initial={{opacity: 0, y: 200}} animate={{opacity: 1, y: 0}} transition={{ delay: .4 }} className="w-full md:h-1/2 flex justify-center items-center bg-[#262e46] tracking-widest text-sm leading-loose" id="works">
                <div className="p-12 md:p-0 md:w-3/5 lg:w-2/5 card flex flex-col space-y-6 justify-center items-center">
                    <div className="text-center text-3xl">How It Works</div>
                    <div>
                        Connectors are responsible for recruiting their friends (MoDaters) to fill out the MoDate questionnaire and answering a few personality questions about them. The MoDate algorithm creates matches at which point Connectors are able to see the profiles of their MoDaters’ matches without seeing names and pictures of the matches. Connectors rank the matches on a scale of 1 to 5 depending on how viable they believe the match to be for their friend. If the combined score of both Connectors meets the MoDate threshold then the MoDaters are sent the match which will include names and pictures.
                    </div>
                    <Link to="/howItWorks" className="py-0.5 px-4 rounded-sm border border-gray-300 text-gray-300 hover:text-gray-400 hover:border-gray-400 text-sm uppercase">See More</Link>
                </div>
            </motion.div>

            <div className="w-full md:h-1/2 flex flex-col">
                <div className="flex flex-col md:flex-row w-full h-full">
                    <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} transition={{ delay: .6 }} className="h-60 md:w-1/2 md:h-full flex justify-center items-center bg-black card text-4xl px-20 text-center tracking-wider leading-loose underline">
                        <Link to="/SignUp">Connector Sign Up</Link>
                    </motion.div>
                    <motion.div initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0}} transition={{ delay: .6 }} className="h-60 md:w-1/2 md:h-full flex justify-center items-center bg-[#1079c5] card text-4xl px-20 text-center tracking-wider leading-loose underline">
                        <Link to="/SignUp">MoDater Sign Up</Link>
                    </motion.div>
                </div>
                <footer className="h-20 w-full bg-gray-300 px-20 card text-black">
                    <div className="h-full w-full flex justify-between items-center">
                        <p>&copy; Modate 2021</p>
                        <div className="flex space-x-4 justify-center items-center">
                            <a className="h-8 w-8" href="https://www.instagram.com/modate613/"><img src="./instagram.png" alt="" /></a>
                            <a href = "mailto: modate613@gmail.com"><EmailOutlinedIcon fontSize="large" /></a>
                        </div>
                    </div>
                </footer>
            </div>

        </div>
    )
}

export default Home
