import React, { useState, useEffect, useContext, useRef } from 'react';
import '../../Components/nav.css';
import { TokenContext } from '../../Components/utilities/accessTokenContext';
import { getAllUserSignUps, getAllUserTotals, getRefreshTokenBeforeApiCall } from '../../Components/utilities/EndPoints';
import { Outlet } from 'react-router';
import { NavLink } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MatchModal from '../../Components/Modal/CreateMatchModal/MatchModal';
import Loading from '../../Components/Loading';

function AdminDashboard() {

    const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);
    const [totals, setTotals] = useState();
    const [totalsLoading, setTotalsLoading] = useState(false);
    const [signUps, setSignUps] = useState();
    const [signUpsLoading, setSignUpsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const _isMounted = useRef(true);

    // const scrollToBottom = () => {
    //     dashRef?.current?.scrollTo({ top: dashRef.current.scrollHeight });
    // }

    // this is for disabling scroll when modal pops up
    const modalHandler = () => {
        setModal(prev => !prev);
        document.body.style.overflow = "hidden";
    }

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getAllUserTotals, [setTotals, setTotalsLoading, _isMounted]);
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getAllUserSignUps, [setSignUps, setSignUpsLoading, _isMounted]);
    }, [token])

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    return (
        <>
            { !/\/dashboard\/adminDashboard\/modaterProfile/.test(window.location.pathname) ?
                <div className="h-full w-full relative">
                    {modal &&
                        <MatchModal setModal={setModal}/>
                    }
                    <div className="w-full flex flex-col justify-center items-center text-gray-300 p-4 sm:px-24 sm:py-12 space-y-8">
                        <div className="flex flex-col w-full justify-center md:space-x-8 md:flex-row space-y-4 md:space-y-0 max-w-[896px]">
                            {totalsLoading ? 
                                <Loading type="generalStats" numPlaceholders={1} /> :
                                totals && 
                                    <div className="flex space-x-8 flex-1">
                                        <div className="flex flex-1 h-[125px] rounded-lg flex-col space-y-2 nav-bg p-4 justify-center items-center border border-gray-800">
                                            <span className="text-gray-300 font-bold text-center">MoDater Totals</span>
                                            <span className="text-2xl text-blue-400 text-center">{totals?.countOfModaters}</span>
                                        </div>
                                        <div className="flex flex-1 h-[125px] rounded-lg flex-col space-y-2 nav-bg p-4 justify-center items-center border border-gray-800">
                                            <span className="text-gray-300 font-bold text-center">Connector Totals</span>
                                            <span className="text-2xl text-blue-300 text-center">{totals?.countOfConnectors}</span>
                                        </div>
                                    </div>
                            }
                            {signUpsLoading ?
                                <Loading type="generalStats" numPlaceholders={1} /> :
                                signUps &&
                                    <div className="flex space-x-8 flex-1">
                                        <div className="flex flex-1 h-[125px] rounded-lg flex-col space-y-2 nav-bg p-4 justify-center items-center border border-gray-800">
                                            <span className="text-gray-300 font-bold text-center">MoDater Sign Ups Today</span>
                                            <span className="text-2xl text-blue-400 text-center">{signUps?.countOfDailyModaterSignUps}</span>
                                        </div>
                                        <div className="flex flex-1 h-[125px] rounded-lg flex-col space-y-2 nav-bg p-4 justify-center items-center border border-gray-800">
                                            <span className="text-gray-300 font-bold text-center">Connector Sign Ups Today</span>
                                            <span className="text-2xl text-blue-300 text-center">{signUps?.countOfDailyConnectorSignUps}</span>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="w-full max-w-[896px] flex flex-wrap">
                            <NavLink to="activity" className={ ({isActive}) => `${isActive ? 'text-gray-200' : 'text-gray-400'} nav-bg border border-gray-800 hover:border-gray-700 flex items-center px-4 py-1 rounded-md text-sm mr-1 mb-2`}>User Activity</NavLink>
                            <NavLink to="connectors" className={ ({isActive}) => `${isActive ? 'text-gray-200' : 'text-gray-400'} nav-bg border border-gray-800 hover:border-gray-700 flex items-center px-4 py-1 rounded-md text-sm mr-1 mb-2`}>Connectors</NavLink>
                            <NavLink to="verifyConnectors" className={ ({isActive}) => `${isActive ? 'text-gray-200' : 'text-gray-400'} nav-bg border border-gray-800 hover:border-gray-700 flex items-center px-4 py-1 rounded-md text-sm mr-1 mb-2`}>Connectors to be Verified</NavLink>
                            <NavLink to="modaters" className={ ({isActive}) => `${isActive ? 'text-gray-200' : 'text-gray-400'} nav-bg border border-gray-800 hover:border-gray-700 flex items-center px-4 py-1 rounded-md text-sm mr-1 mb-2`}>MoDaters</NavLink>
                            <NavLink to="matches" className={ ({isActive}) => `${isActive ? 'text-gray-200' : 'text-gray-400'} nav-bg border border-gray-800 hover:border-gray-700 flex items-center px-4 py-1 rounded-md text-sm mr-1 mb-2`}>Matches</NavLink>
                        </div>
                        <div className="h-16 w-16 rounded-full sidebar-bg border border-gray-700 fixed bottom-8 right-8 flex text-red-400 justify-center items-center hover:cursor-pointer hover:border-gray-600 hover:transition hover:scale-110 hover:duration-500 hover:text-red-500 z-10" onClick={modalHandler}>
                            <FavoriteIcon fontSize="medium"/>
                        </div>
                        <Outlet />
                    </div>
                </div> :
                <Outlet />
            }
        </>
    )
}

export default AdminDashboard