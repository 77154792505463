import React, { useContext, useState, useEffect } from 'react'
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { getRefreshTokenBeforeApiCall, getStatusOfMatchesConnector, connectorRejectMatchAfterExpiration } from '../Components/utilities/EndPoints';
import Modal from '../Components/Modal.jsx';
// import { Link } from 'react-router-dom';
import StatusLine from '../Components/StatusLine.jsx';

function MatchesStatus() {

    const { token, setToken, setLoggedIn, navigate, setSuccess, setError } = useContext(TokenContext);

    const [matches, setMatches] = useState();
    const [modal, setModal] = useState(false);
    const [matchToBeCancelled, setMatchToBeCancelled] = useState();
    const [disabled, setDisabled] = useState(false);

    const cancelMatch = match => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, connectorRejectMatchAfterExpiration, [match._id, setMatches, setModal, setDisabled, setSuccess, setError])
    }

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getStatusOfMatchesConnector, [setMatches]);
    }, [token])

    // console.log(matches)

    return (
        <div className="flex w-full flex-col body-bg relative">
            {modal && 
                <Modal 
                    setModal={setModal} 
                    rejectHandler={() => cancelMatch(matchToBeCancelled)} 
                    disabled={disabled}
                    header="Are you sure you want to cancel this match?"
                    body="By canceling this match you will not get suggested this match again"
                /> 
            }
            { matches && 
                <div className="p-4 md:p-12 w-full h-full">
                    {/* <Link to="/dashboard/connectorDashboard" className="py-1 px-4 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400">Back to Dashboard</Link> */}
                    <div className="text-gray-400 flex flex-col space-y-4 pb-12">
                        <div className="w-full flex px-4 py-2 text-gray-300 underline text-xs lg:text-sm tracking-wider">
                            <div className="flex-1">Date Activated</div>
                            <div className="flex-1">MoDater Alias</div>
                            <div className="flex-1">Status</div>
                        </div>
                        {
                            matches.map((obj, index) => {
                                return <StatusLine 
                                            key={index} 
                                            modater={obj} 
                                            setModal={setModal} 
                                            setMatchToBeCancelled={setMatchToBeCancelled}
                                        />
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default MatchesStatus
