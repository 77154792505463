import React, { forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { cancelSuggestionForModater, getRefreshTokenBeforeApiCall } from '../../utilities/EndPoints';
import { TokenContext } from '../../utilities/accessTokenContext';

const Step2 = forwardRef(({ setDisabled, setStep, suggestions, selected, selectedMatch, setSelectedMatch, cancelMatch, setCancelMatch, loading, setSuggestions }, ref) => {

    console.log(selectedMatch)
    const { token, setToken, setLoggedIn, navigate, setError, setSuccess } = useContext(TokenContext);

    useImperativeHandle(ref, () => ({

        onSubmitHandler() {
            if (!cancelMatch) {
                setStep(2);
            }
            else {
                const data = {modaterIds: [selected.value, selectedMatch.modater._id]}
                getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, cancelSuggestionForModater, [data, setError, setSuccess, setDisabled, setSuggestions, setSelectedMatch]);
            }
        }
    
    }));

    const handleClick = (suggestion) => {
        if (selectedMatch?.modater?._id === suggestion?.modater._id) {
            setSelectedMatch(null);
            setCancelMatch(null);
            return;
        }
        setSelectedMatch(suggestion);
    }

    const handleCancelMatch = (e, suggestion) => {
        e.stopPropagation();
        setCancelMatch(suggestion)
    }

    useEffect(() => {
        if (!selectedMatch) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        setCancelMatch(null);
    }, [selectedMatch])
    console.log(suggestions)

    return (
        <div className="flex flex-col items-center space-y-4">
            <h1 className="text-sm tracking-wider  px-4 sm:px-12">Suggestions for <b>{selected?.label}</b> based on Matches your other MoDaters have liked</h1>
            { loading ? 
                <div className="flex items-center space-x-4">
                    <CircularProgress />
                    <span>Loading Matches...</span>
                </div> 
            : suggestions?.suggestions.length > 0 ? 
                <div className="flex flex-wrap justify-start w-full sm:px-10 overflow-y-scroll max-h-[12rem]">
                    { suggestions.suggestions.map(suggestion => {
                            return (
                                <div className={`flex flex-col relative px-4 ml-2 mt-2 tracking-wider border border-gray-800 hover:cursor-pointer hover:bg-gray-700 rounded-md ${suggestion.modater.activeStatus ? '' :'pointer-events-none'} ${selectedMatch?.modater?._id === suggestion?.modater?._id ? 'bg-blue-500 hover:bg-blue-500' : 'body-bg'}`} key={suggestion?.modater._id} onClick={() => handleClick(suggestion)}>
                                    <div className="flex space-x-2 items-center ml-1">
                                        <div className="text-sm sm:text-[16px]">{suggestion.modater.vocation}</div>
                                        <div className="text-md sm:text-lg tracking-widest font-bold text-blue-300">{new Date(Date.now() - new Date(suggestion.modater.dateOfBirth)).getFullYear() - 1970}</div>
                                        <div className={`uppercase text-[12px] px-3 rounded-full nav-bg ${suggestion.modater.activeStatus ? 'text-green-400' : 'text-red-400'}`}>{suggestion.modater.activeStatus ? 'available' : 'busy'}</div>
                                    </div>
                                    <div className="text-sm text-gray-400 flex items-center flex-wrap">
                                        <span className="text-xs ml-1">Liked by</span>
                                        {
                                            suggestion.likedBy.map((modater, index) => {
                                                return <span className="text-md font-medium text-gray-300 tracking-wider ml-1" key={modater._id}>{modater.firstName} {modater.lastName}{suggestion.likedBy.length-1 === index ? '' : ','}</span> 
                                            })
                                        }
                                    </div>
                                    { suggestion.modater._id === selectedMatch?.modater._id && <button className="hover:text-red-300 border border-gray-700 nav-bg shadow-xl shadow-black rounded-full absolute -top-2.5 -right-2.5 h-5 w-5 flex justify-center items-center text-red-100 text-sm" onClick={(e) => handleCancelMatch(e, suggestion)}><CloseIcon fontSize="smal"/></button> }
                                </div>
                            )
                        })
                    }
                </div> :
                <div className="tracking-wider">
                    No Suggestions Yet.
                </div>
            }
        </div>
    )
})

export default Step2