import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react'
import { HEIGHTS } from '../../../constants';
import { TokenContext } from '../../utilities/accessTokenContext';
import { connectorCreateMatchBetweenModaters, getRefreshTokenBeforeApiCall } from '../../utilities/EndPoints';
import { getRelationToJudaism } from '../../utilities/funcs';

const Step3 = forwardRef(({setDisabled, selectedMatch, selected, setStep, setSuggestions }, ref) => {

    const { token, setToken, setLoggedIn, navigate, setError, setSuccess } = useContext(TokenContext);
    const [isFlipped, setIsFlipped] = useState(true);
     
    useImperativeHandle(ref, () => ({

        onSubmitHandler() {
            const data = {modaterIds: [selected.value, selectedMatch.modater._id]}
            getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, connectorCreateMatchBetweenModaters, [data, setError, setSuccess, setDisabled, setStep, setSuggestions])
        }
    
    }));

    console.log(selectedMatch.modater)
    return (
        <div className="flex w-full flex-col">
            { isFlipped ? 
                <div className="flex justify-center items-center h-[20rem] w-full sm:px-8">
                    <div className={`h-full w-full rounded-md relative tracking-wider overflow-y-scroll`}>
                        <button className="absolute top-5 right-3 text-white px-2 bg-blue-300 bg-opacity-40 hover:bg-opacity-50 rounded-sm py-1 text-xs uppercase" onClick={() => setIsFlipped(prev => !prev)}>
                            Other Data
                        </button>
                        <div className="text-white h-full w-full">
                            {/* characteristics */}
                            <div className="px-4 mt-4 w-full sm:text-lg text-blue-300 font-light tracking-wider">Characteristics</div>
                            <div className="flex flex-wrap items-start">
                                {selectedMatch.modater.characteristics.map((characteristic, index) => {
                                    return (
                                        <div className="nav-bg px-3 py-0.5 mt-4 rounded-sm text-gray-300 text-sm ml-4 border border-gray-800" key={index}>{characteristic}</div>
                                    )
                                })}
                            </div>
                            <div className="px-4 py-1 w-full flex flex-col space-y-1">
                                <div className="flex items-center space-x-20 py-2">
                                    <div className="text-md sm:text-lg font-light tracking-wider text-blue-300">A Little About Me</div>
                                    <div className="text-sm font-medium">Height: {HEIGHTS[selectedMatch.modater.height]}</div>
                                </div>
                                { selectedMatch.modater.bio.length > 0 ?
                                    <div className="text-base text-gray-300 font-light overflow-y-scroll tracking-wider special">
                                        {selectedMatch.modater.bio}
                                    </div> :
                                    <div className="text-xl h-full flex items-center justify-center tracking-wide font-light">No Bio Provided</div>
                                }
                            </div>
                            <div className="p-4 w-full">
                                <div className="flex flex-col text-white space-y-3">
                                    <div className="flex justify-between items-center">
                                        {/* name and age of person */}
                                        <div className="text-sm sm:text-md uppercase flex items-center">{`${selectedMatch.modater.alias}, `}
                                            <span className="text-md sm:text-lg ml-2">{new Date(Date.now() - new Date(selectedMatch.modater.dateOfBirth)).getFullYear() - 1970}</span>
                                        </div>
                                        {/* persons location */}
                                        <div className="text-[10px] uppercase text-end">{selectedMatch.modater.city}, {selectedMatch.modater.state}</div>
                                    </div>
                                    {/* vocation and college */}
                                    <div className="text-xs tracking-wide uppercase font-light"><span>{selectedMatch.modater.vocation}</span> - <span>{selectedMatch.modater.college}</span></div>
                                    {/* activities */}
                                    <div className="p-0.5 flex flex-col">
                                        <div className="text-xs sm:text-lg text-blue-300 flex items-center">Favorite Activities</div>
                                        <div className="flex flex-wrap items-start -ml-4">
                                            {selectedMatch.modater.activities.map((activity, index) => {
                                                return (
                                                    <div className="px-3 py-0.5 mt-2 rounded-sm text-gray-300 text-xs sm:text-sm ml-2" key={index}>- {activity}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                :
                <div className="flex justify-center items-center h-[20rem] w-full sm:px-8">
                    <div className={`h-full w-full rounded-md relative tracking-wider overflow-y-scroll`}>
                        <button className="absolute top-5 right-3 text-white px-2 bg-blue-300 bg-opacity-40 hover:bg-opacity-50 rounded-sm py-1 text-xs uppercase" onClick={() => setIsFlipped(prev => !prev)}>
                            Other Data
                        </button>
                        <div className="text-white h-full w-full py-6">
                            <div className="space-y-0.5">
                                <div className="px-4 text-sm font-light">
                                    Aliyah: {selectedMatch.modater.aliyah}
                                </div>
                                <div className="px-4 text-sm font-light">
                                    Shabbos: {selectedMatch.modater.shabbos}
                                </div>
                                <div className="px-4 text-sm font-light">
                                    Learns: {selectedMatch.modater.learns}
                                </div>
                                <div className="px-4 text-sm font-light">
                                    Kosher: {selectedMatch.modater.kosher}
                                </div>
                                <div className="px-4 text-sm font-light">
                                    Shomer Negiah: {selectedMatch.modater.shomerNegiah}
                                </div>
                            </div>
                            <div className="p-4 max-h-[14rem] w-full flex flex-col space-y-1">
                                <div className="text-xl font-light tracking-wider">
                                    Relation To Judaism
                                </div>
                                <div className="text-base text-gray-300 font-light overflow-y-scroll tracking-wide">
                                    {getRelationToJudaism(selectedMatch.modater.relationToJudaism)}
                                </div> 
                            </div>
                            <div className="px-4 w-full">
                                <div className="flex flex-col text-white space-y-3">
                                    <div className="flex space-x-4 items-center">
                                        <span className="text-sm font-medium">High School:</span>
                                        <div className="text-sm font-light">{selectedMatch.modater.highSchool}</div>
                                    </div>
                                    <div className="flex space-x-4 items-center">
                                        <span className="text-sm font-medium">{selectedMatch.modater.sex === 'Male' ? 'Yeshiva' : 'Seminary'}:</span>
                                        <div className="text-sm font-light">{selectedMatch.modater.israelSchool}</div>
                                    </div>
                                    {/* politics */}
                                    <div className="flex space-x-4 items-center">
                                        <span className="text-sm font-medium">Politics:</span>
                                        <div className="text-sm font-light">{selectedMatch.modater.politics}</div>
                                    </div>
                                    <div className="flex space-x-4 items-center">
                                        <span className="text-sm font-medium">Vacation:</span>
                                        <div className="text-sm font-light">{selectedMatch.modater.vacationPreference}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
})

export default Step3