import React from 'react';
import './nav.css'

function ExtraInfoView({ modater }) {
    
    const getAnswerForQ4 = (ans) => {
        switch(ans) {
            case "Happy":
                return "Happy (Pharrell Williams) - Always Cheerful and Has a Positive Outlook";
            case "Eye of the Tiger":
                return "Eye of the Tiger (Survivor) - Extremely Determined to Keep Growing and Achieving";
            case "Party in the USA":
                return "Party in the USA (Miley Cyrus) - Always the Life of the Party Whether Actually in the US or Abroad";
            case "The Scientist":
                return "The Scientist (Coldplay) - Loves Solving Problems and Understanding the World";
            default:
                return ""
        }
    }

    return (
        <div className="space-y-4 w-full h-full overflow-y-visible xl:overflow-y-scroll">
            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-16 xl:flex-col xl:space-x-0 xl:space-y-5">
                <div className="flex flex-col space-y-4 w-full md:w-1/2 xl:w-full">
                    {/* q1 */}
                    <div className="text-sm font-light">Which best describes your friend?</div>
                    <div className="px-2 py-0.5 text-sm text-blue-200 inline-block rounded-sm font-sans">{modater.extraInfo.q1}</div>
                </div>
                <div className="lex flex-col space-y-4 w-full md:w-1/2 xl:w-full">
                    {/* q2 */}
                    <div className="text-sm font-light">You are on a trip with your MoDater: what role do they fill?</div>
                    <div className="px-2 py-0.5 text-sm text-blue-200 inline-block rounded-sm font-sans">{modater.extraInfo.q2}</div>
                </div>
            </div>
            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-16 xl:flex-col  xl:space-x-0 xl:space-y-4">
                <div className="flex flex-col space-y-4 w-full md:w-1/2 xl:w-full">
                    {/* q3 */}
                    <div className="text-sm font-light">You are at a Shabbat meal with your MoDater: what role do they fill?</div>
                    <div className="px-2 py-0.5 text-sm text-blue-200 inline-block rounded-sm font-sans">{modater.extraInfo.q3}</div>
                </div>
                <div className="flex flex-col space-y-4 w-full md:w-1/2 xl:w-full">
                    {/* q4 */}
                    <div className="text-sm font-light">Which song would you choose to be your MoDater's theme song?</div>
                    <div className="px-2 py-0.5 text-sm text-blue-200 inline-block rounded-sm font-sans">{getAnswerForQ4(modater.extraInfo.q4)}</div>
                </div>
            </div>
            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-16 xl:flex-col  xl:space-x-0 xl:space-y-4">
                <div className="flex flex-col space-y-4 w-full md:w-1/2 xl:w-full">
                    {/* q5 */}
                    <div className="text-sm font-light">It is your MoDater’s birthday and you want to celebrate: what do you organize?</div>
                    <div className="px-2 py-0.5 text-sm text-blue-200 inline-block rounded-sm font-sans">{modater.extraInfo.q5}</div>
                </div>
                <div className="flex flex-col space-y-4 w-full md:w-1/2 xl:w-full">
                    {/* q6 */}
                    <div className="text-sm font-light">What would you say is your MoDater’s primary focus?</div>
                    <div className="px-2 py-0.5 text-sm text-blue-200 inline-block rounded-sm font-sans">{modater.extraInfo.q6}</div>
                </div>
            </div>
            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-16 xl:flex-col  xl:space-x-0 xl:space-y-4">
                <div className="flex flex-col space-y-4 w-full md:w-1/2 xl:w-full">
                    {/* q7 */}
                    <div className="text-sm font-light">Would you say your MoDater adheres to the status quo or blazes their own trail?</div>
                    <div className="px-2 py-0.5 text-sm text-blue-200 inline-block rounded-sm font-sans">{modater.extraInfo.q7}</div>
                </div>
                <div className="flex flex-col space-y-4 w-full md:w-1/2 xl:w-full">
                    {/* q8 */}
                    <div className="text-sm font-light">What does your MoDater appreciate more?</div>
                    <div className="px-2 py-0.5 text-sm text-blue-200 inline-block rounded-sm font-sans">{modater.extraInfo.q8}</div>
                </div>
            </div>
        </div>
    )
}

export default ExtraInfoView