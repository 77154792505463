import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react'
import { getRefreshTokenBeforeApiCall, createMatchBetweenModaters, getMatchesSuggestionForModater } from '../../utilities/EndPoints';
import { TokenContext } from '../../utilities/accessTokenContext';
import { STYLES } from '../../../constants';
import Select from 'react-select';
import { components } from 'react-select';
const { Option } = components;

const Step1 = forwardRef(({ setDisabled, setStep, selected, setSelected, suggestions, setSuggestions, setLoading, modaters, loadingModaters }, ref) => {
    const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);

    useImperativeHandle(ref, () => ({

        onSubmitHandler() {
          setStep(1);
        }
    
    }));

    const IconOption = (props) => {
        return <Option {...props}>
            <div className="flex items-center space-x-4">
                { props.data.img ? 
                    <img src={props.data.img} className="h-8 w-8 object-cover rounded-full ring-1 ring-gray-800"/> :
                    <img src="https://modate-photos.s3.amazonaws.com/blank-profile-picture.png" alt="" className="h-8 w-8 object-cover rounded-full ring-1 ring-gray-800"/>
                }
                <div className={`text-md tracking-wider font-medium ${ props.data.isActive ? '' : 'text-gray-400'}`}>{props.data.label}</div>
            </div>
        </Option>
    }
    
    useEffect(() => {
        if (!selected) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [selected])

    useEffect(() => {
        if (selected) {
            getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getMatchesSuggestionForModater, [setSuggestions, setLoading, selected?.value])
        }
    }, [selected])

    console.log(suggestions)
    
    return (
        <div className="flex flex-col items-center space-y-4 h-full">
            <h2 className="text-md sm:text-lg sm:font-medium tracking-wider font-light">Welcome to Suggestions!</h2>
            <p className="text-sm tracking-wider  px-4 sm:px-12 leading-6">A new way to help set up your MoDaters by having access to profiles that have already been liked by them. These matches go straight to the MoDater stage and bypass the other Connector.</p>
            <div className="w-full flex justify-center pt-2" onClick={(e) => {e.stopPropagation()} }>
                <Select 
                    styles={STYLES}
                    components={{Option:IconOption}}
                    value={selected}
                    isClearable
                    options={modaters ? modaters.map(modater => ({label: `${modater.firstName} ${modater.lastName}`, value: modater._id, img: modater.photoUrls[0]?.url, isActive: modater.activeStatus})) : null} 
                    onChange={setSelected}
                    className="text-black w-60"
                    placeholder={loadingModaters ? "Loading Modaters..." : "Select MoDater"}
                />
            </div>
        </div>
    )
})

export default Step1