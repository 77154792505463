import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Error from '../Components/utilities/Error';
import { useNavigate } from 'react-router-dom';
import '../Components/nav.css';
import { forgotPassword } from '../Components/utilities/EndPoints';
// import { TokenContext } from '../Components/utilities/accessTokenContext';

const schema = yup.object().shape({
    email: yup.string().email().required('Please enter a valid Email'),
})

const ForgotPassword = () => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const [success, setSuccess] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [formError, setFormError] = useState();


    const submitForm = async data => {
        forgotPassword(data, setDisabled, setFormError, setSuccess);
    }

    return (
        <form onSubmit={ handleSubmit(submitForm) } className="flex justify-center items-center h-full">
            <div className="flex flex-col w-5/6 sm:w-1/2 lg:w-2/5 xl:w-2/6 h-1/2 justify-center items-center space-y-4 sidebar-bg text-gray-200 px-10 tracking-wider border border-gray-800 ring-2 ring-black ring-opacity-30 rounded-sm">
                {formError && <div className="text-red-400 lg:px-12 uppercase text-sm">{formError}</div>}
                { success ? 
                    <div className="flex flex-col justify-center items-start space-y-6 lg:px-12">
                        <h1 className="text-xl tracking-widest">Reset Password</h1>
                        <div className="text-sm tracking-wider font-extralight">If we found an account associated with that username, we've sent password reset instructions to the email address on the account.</div>
                        <div className="text-sm tracking-wider font-extralight">Still having trouble logging in? <a className="underline" href = "mailto: modate613@gmail.com">Contact Support</a></div>
                    </div> :

                    <div className="flex flex-col space-y-6 justify-center items-start lg:px-12">
                        <div className="text-2xl font-light">Forgot Password</div>
                        <div className="flex flex-col w-full py-2">
                            <label className="flex justify-start mb-4 text-sm text-gray-300 font-extralight">To reset your password, please provide your MoDate username.</label>
                            <input { ...register('email') } type="text" placeholder="Email" className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg text-sm font-light rounded-full"/>
                            {errors.email && <Error errorMessage={errors.email.message} /> }
                        </div>

                        <div className="flex justify-center w-full">
                            <button type="submit" disabled={disabled} className="block bg-blue-600 uppercase px-8 py-3 font-light hover:bg-blue-900 text-xs rounded-full text-white w-full">Send Reset Instructions</button>
                        </div>
                    </div>
                }
            </div>
        </form>
    )
}

export default ForgotPassword;