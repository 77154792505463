import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import CardProfile from "../Components/CardProfile";
import Modal from "../Components/Modal";
import InfoModal from "../Components/InfoModal";
import { TokenContext } from "../Components/utilities/accessTokenContext";
import { Link } from "react-router-dom";
import {
  getRefreshTokenBeforeApiCall,
  changeActiveStatus,
  getAllConnectors,
  switchConnectors,
  cancelConnectorSwitch,
  deleteMyAccount,
  getConnectorById,
} from "../Components/utilities/EndPoints";
// import Select from "react-select";
import AsyncSelect from "react-select/async";
import VerifiedIcon from "@mui/icons-material/Verified";
import { motion } from "framer-motion";
import { STYLES } from "../constants.js";
import "../Components/nav.css";
import DeleteAccountModal from "../Components/DeleteAccountModal";

function Profile() {
  const {
    token,
    setToken,
    setLoggedIn,
    user,
    setUser,
    navigate,
    setError,
    setSuccess,
  } = useContext(TokenContext);
  const [input, setInput] = useState("");
  const [myConnector, setMyConnector] = useState();
  const [changeConnector, setChangeConnector] = useState(false);
  const [selected, setSelected] = useState();
  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [formError, setFormError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [body, setBody] = useState();
  const [header, setHeader] = useState();
  const componentIsMounted = useRef(true);
  const _isMounted = useRef();

  const handleContinue = (e) => {
    e.stopPropagation();
    if (selected) {
      setModal((prev) => !prev);
      setFormError(null);
    } else {
      setFormError("you must choose a connector before Continuing");
    }
  };

  const handleSelect = (selected) => {
    setSelected(selected);
    setFormError(null);
  };

  const handleChangeConnector = () => {
    if (user?.modaterProfileId?.isDating?.status) {
      setInfoModal((prev) => !prev);
      setHeader(
        "You can't change Connectors when you're in middle of Dating someone"
      );
      setBody(
        "In order to Change Connectors you can go to the modater dashboard and close out your dating status"
      );
    } else {
      setChangeConnector((prev) => !prev);
    }
  };

  const handleActivation = () => {
    if (user?.modaterProfileId?.isDating?.status) {
      setInfoModal((prev) => !prev);
      setHeader(
        "You can't activate yourself when you're in middle of Dating someone"
      );
      setBody(
        "In order to re-activate your Profile you can go to the modater dashboard and close out your dating status"
      );
    } else if (!user?.modaterProfileId?.extraInfo?.reviewed) {
      setInfoModal((prev) => !prev);
      setHeader("You haven't been reviewed by a connector yet");
      setBody(
        "In order to change your acitvation status you need to have been reviewed by a connector"
      );
    } else {
      getRefreshTokenBeforeApiCall(
        token,
        setToken,
        setLoggedIn,
        navigate,
        changeActiveStatus,
        [setUser, setSuccess, setError]
      );
    }
  };

  const cancelRequest = () => {
    if (
      !user?.modaterProfileId.extraInfo.reviewed &&
      !user?.connectorTransfer.isSwitchingConnectors
    ) {
      setInfoModal((prev) => !prev);
      setHeader("Can't Change Connectors Yet");
      setBody(
        "You Can't Change Connectors Before You Have Been Reviewed By A Connector"
      );
    } else {
      getRefreshTokenBeforeApiCall(
        token,
        setToken,
        setLoggedIn,
        navigate,
        cancelConnectorSwitch,
        [setUser, setDisabled, setError, setSuccess]
      );
    }
  };

  const deleteAccountHandler = () => {
    getRefreshTokenBeforeApiCall(
      token,
      setToken,
      setLoggedIn,
      navigate,
      deleteMyAccount,
      [setDisabled, setDeleteModal, setError, navigate, setLoggedIn]
    );
  };

  const debounce = (func, delay) => {
    let id;
    // console.log('id is', id);
    return (...args) => {
      return new Promise((resolve) => {
        if (id) {
          clearTimeout(id);
        }
        id = setTimeout(async () => {
          const res = await func(...args);
          resolve(res);
        }, delay);
      });
    };
  };

  const loadOptions = (input) => {
    return getRefreshTokenBeforeApiCall(
      token,
      setToken,
      setLoggedIn,
      navigate,
      getAllConnectors,
      [input, _isMounted]
    );
  };

  const debounced = useCallback(debounce(loadOptions, 400), [token]);

  useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  });

  useEffect(() => {
    console.log("user is", user);
    if (user?.modaterProfileId) {
      getRefreshTokenBeforeApiCall(
        token,
        setToken,
        setLoggedIn,
        navigate,
        getConnectorById,
        [setMyConnector, user.modaterProfileId.connectorId]
      );
    }
  }, [user]);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      className="w-full lg:h-full flex items-center justify-center relative"
    >
      {deleteModal && (
        <DeleteAccountModal
          setModal={setDeleteModal}
          rejectHandler={deleteAccountHandler}
          disabled={disabled}
        />
      )}
      {modal && (
        <Modal
          disabled={disabled}
          rejectHandler={() => {
            getRefreshTokenBeforeApiCall(
              token,
              setToken,
              setLoggedIn,
              navigate,
              switchConnectors,
              [
                selected.value,
                setUser,
                setModal,
                setDisabled,
                setChangeConnector,
                setSuccess,
              ]
            );
          }}
          setModal={setModal}
          header={`Are you sure that you want to make ${selected.label} your New Connector`}
          body={`By clicking Confirm your profile will be sent to your new connector to be reviewed. If however, ${selected.label} does not accept your invitation to be your connector you will revert back to the connector you have now. DISCLAIMER: if your new Connector accepts your request all your previous matches will be deleted`}
        />
      )}
      {infoModal && (
        <InfoModal header={header} body={body} setModal={setInfoModal} />
      )}
      {user && (
        <div className="w-full h-full flex flex-col items-center justify-center relative lg:flex-row">
          <div className="flex space-x-4 absolute top-4 left-4">
            {user.modaterProfileId && (
              <Link
                to="/dashboard/profile/modaterEdit"
                className="px-3 py-0.5 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400"
              >
                Edit Profile
              </Link>
            )}
            {!user.connectorProfileId && (
              <Link
                to="/connectorSignUp"
                className="px-3 py-0.5 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400"
              >
                Connector Sign Up{" "}
              </Link>
            )}
            {!user.modaterProfileId && (
              <Link
                to="/modaterSignUp"
                className="px-3 py-0.5 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400"
              >
                MoDater Sign Up
              </Link>
            )}
          </div>
          {user?.modaterProfileId ? (
            <div className="flex flex-col lg:flex-row justify-center items-center py-16">
              <div className="flex  flex-col space-y-8 p-8 lg:mt-0 xl:mr-20">
                <div className="text-lg text-gray-300 flex flex-col space-y-4">
                  <div className="flex flex-col tracking-wider space-y-2">
                    <div className="flex space-x-4 items-center">
                      <span className="font-light text-base">Connector</span>
                      <div className="text-sm uppercase font-light">
                        {myConnector
                          ? `${myConnector.firstName} ${myConnector.lastName}`
                          : `None`}
                      </div>
                      {user.modaterProfileId.extraInfo.reviewed && (
                        <VerifiedIcon fontSize="small" className="" />
                      )}
                    </div>
                    {/* <div className={`text-sm nav-bg px-2 py-0.5`}>{user.modaterProfileId.extraInfo.reviewed ? 'Reviewed': 'Pending Review'}</div> */}
                    {user.modaterProfileId.extraInfo.reviewed &&
                    user.modaterProfileId.connectorId ? (
                      <div className="text-sm font-light underline">
                        Reviewed and Accepted by Connector
                      </div>
                    ) : user.modaterProfileId.connectorId ? (
                      <div className="text-sm font-light underline">
                        Pending Connector Review and Acceptance
                      </div>
                    ) : (
                      <div className="text-sm font-light underline">
                        Please Choose A Connector
                      </div>
                    )}
                  </div>
                  {changeConnector ? (
                    <div className="flex flex-col space-y-4">
                      <AsyncSelect
                        loadOptions={debounced}
                        placeholder="Search for a Connector"
                        isClearable
                        onInputChange={setInput}
                        value={selected}
                        onChange={handleSelect}
                        className="w-[300px] text-sm"
                        styles={STYLES}
                      />
                      <div className="flex flex-col space-y-3">
                        <div className="flex space-x-8">
                          <button
                            className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800 hover:text-blue-300"
                            onClick={() => {
                              setChangeConnector((prev) => !prev);
                              setSelected(null);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800 hover:text-blue-300"
                            onClick={(e) => handleContinue(e)}
                          >
                            Continue
                          </button>
                        </div>
                        {formError && (
                          <div className="text-xs text-red-400">
                            {formError}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : !user.modaterProfileId.extraInfo.reviewed &&
                    user.modaterProfileId.connectorId ? (
                    <div>
                      <button
                        className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800 hover:text-blue-300"
                        onClick={cancelRequest}
                        disabled={disabled}
                      >
                        Cancel Request
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800 hover:text-blue-300"
                        onClick={handleChangeConnector}
                      >
                        {user?.modaterProfileId?.connectorId
                          ? "Change Connectors"
                          : "Choose Connector"}
                      </button>
                    </div>
                  )}
                </div>

                <div className="text-lg text-gray-300 flex flex-col space-y-4">
                  <div className="flex space-x-4 tracking-wider items-center">
                    <span className="font-light text-base">Status</span>
                    <div
                      className={`${
                        user.modaterProfileId.activeStatus
                          ? "text-green-400"
                          : "text-red-400"
                      } text-sm nav-bg px-2 py-0.5 rounded-sm border border-gray-800`}
                    >
                      {user.modaterProfileId.activeStatus
                        ? "ACTIVE"
                        : "INACTIVE"}
                    </div>
                  </div>
                  <div className="text-sm font-light underline">{`When we run matches you will ${
                    user.modaterProfileId.activeStatus
                      ? "be included"
                      : "not be included"
                  }`}</div>
                  <div>
                    <button
                      className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800 hover:text-blue-300"
                      onClick={handleActivation}
                    >
                      Click to{" "}
                      {user.modaterProfileId.activeStatus
                        ? "Deactivate"
                        : "Activate"}
                    </button>
                  </div>
                </div>
                <div className="flex flex-col justify-start space-y-4">
                  <div className="text-mds tracking-wider text-gray-300 underline underline-offset-2">
                    Delete Account Permanently
                  </div>
                  <div>
                    <button
                      className="border nav-bg border-gray-800 px-4 py-1 text-red-400 text-sm rounded-sm"
                      onClick={() => setDeleteModal((prev) => !prev)}
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>

              <CardProfile user={user} urls={user.modaterProfileId.photoUrls} />
            </div>
          ) : (
            <div className="text-gray-300 tracking-wider text-2xl mt-40 lg:mt-0 p-8">
              Sign Up As A MoDater To Be Able To View Your Profile
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
}

export default Profile;
