import React from 'react'
import '../Components/nav.css';
import VerifiedIcon from '@mui/icons-material/Verified';
import GradingIcon from '@mui/icons-material/Grading';
import { motion } from 'framer-motion';
import HomePageCard from '../Components/HomePageCard';
import HomePageLine from '../Components/HomePageLine';
import { Link } from 'react-router-dom';

function HowItWorks() {
    return (
        <>
            <div className="flex justify-center h-[900px] md:h-[800px] bg-[url('https://modate-photos.s3.amazonaws.com/heading-bg-wave-desktop.svg')] bg-repeat-x bg-bottom w-full bg-[length:2200px_300px] montserrat">
                <div className="flex flex-col-reverse md:flex-row items-center justify-center md:space-x-16 md:-mt-16 px-6 ">
                    <motion.div initial={{opacity: 0, x: -400}} animate={{opacity: 1, x: 0}} transition={{ duration: .3 }} className="max-w-[400px] h-[450px] rounded-lg shadow-lg shadow-black/80 body-bg border border-gray-800 p-8 mt-6 md:mt-0">
                        <div className="w-full flex items-center space-x-8 h-1/4">
                            <div className="flex-none p-1 w-12 h-12 bg-blue-300 relative text-black flex justify-center items-center text-3xl rounded-full after:content-['|'] after:block after:absolute after:top-[50px] after:text-blue-300">1</div>
                            <div className="text-gray-300 text-[14px] tracking-wide font-light">Sign up to be a Connector and fill out the MoDate Connector form.</div>
                        </div>
                        <div className="w-full flex items-center space-x-8 h-1/4">
                            <div className="flex-none p-1 w-12 h-12 bg-blue-300 relative text-black flex justify-center items-center text-3xl rounded-full after:content-['|'] after:block after:absolute after:top-[50px] after:text-blue-300">2</div>
                            <div className="text-gray-300 text-[14px] tracking-wide font-light">Send your friends a link to MoDate.org to sign up as Connectors, MoDaters, or both.</div>
                        </div>
                        <div className="w-full flex items-center space-x-8 h-1/4">
                            <div className="flex-none p-1 w-12 h-12 bg-blue-300 relative text-black flex justify-center items-center text-3xl rounded-full after:content-['|'] after:block after:absolute after:top-[50px] after:text-blue-300">3</div>
                            <div className="text-gray-300 text-[14px] tracking-wide font-light">Tell your MoDaters to assign you as their Connector on the MoDate form.</div>
                        </div>
                        <div className="w-full flex items-center space-x-8 h-1/4">
                            <div className="flex-none p-1 w-12 h-12 bg-blue-300 relative text-black flex justify-center items-center text-3xl rounded-full">4</div>
                            <div className="text-gray-300 text-[14px] tracking-wide font-light">Fill out a couple of questions about your friends and start receiving matches to review for them.</div>
                        </div>
                    </motion.div>
                    <motion.div initial={{opacity: 0, y: -400}} animate={{opacity: 1, y: 0}} transition={{ duration: .3, delay: .2 }} className="w-full flex flex-col max-w-[550px] space-y-6 mt-6 sm:mt-0 mt">
                        <div className="flex flex-col space-y-2">
                            <div className="text-blue-300">
                            <span className="text-md sm:text-3xl">MoDate</span><span className="text-sm sm:text-2xl"> - "The Mutual Friend You Wish You Had"</span></div>
                        </div>
                        <div className="text-gray-300 w-full tracking-wider font-light text-[14px] md:text-md leading-7 sm:text-lg sm:font-medium">MoDate makes dating more accessible for the Jewish people by expanding people’s networks and creating connections. MoDate is not just a dating platform, but rather a dating community. The various offerings of the MoVerse including: MoDate, The MoPod, MoEvents, & more, aim to remove any stigmas that surround dating, allow everyone to be a part of the conversation, and help people find their perfect match in a fun and easy way.</div>
                        <div className="w-full flex justify-center items-center"><Link to="/signUp" className="bg-gradient-to-r from-cyan-500 to-blue-500 rounded-md py-1 px-4 text-white font-bold text-lg">Sign Up</Link></div>
                    </motion.div>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center min-h-[600px] max-h-[1400px] bg-[#ebebec] py-12" id="about">
                <motion.div initial={{opacity: 0, y: -300}} animate={{opacity: 1, y: 0}} transition={{ duration: 2 }} className="text-[7vw] lg:text-[4vw] card w-full lg:w-1/4 flex justify-center text-center uppercase">Our Story</motion.div>
                <div className="px-12 w-full lg:w-3/4 text-black font-semibold md:leading-10 leading-7 tracking-wider text-[3.1vw] sm:text-[2.5vw] lg:text-[1vw] special">
                    <HomePageLine index={.05} >In the summer of 2020, Evan decided to enter the shidduch system and download various dating apps.</HomePageLine>
                    <HomePageLine index={.1} >After trying various dating methods, Evan felt as though the most comfortable and efficient form of dating was being set up</HomePageLine> 
                    <HomePageLine index={.15} >  by friends. However, Evan felt this system was very limited and didn't have an effective medium through</HomePageLine>
                    <HomePageLine index={.2} > which to facilitate the process. Thus, the idea of MoDate was born. MoDate - the mutual friend you wish you had, </HomePageLine>
                    <HomePageLine index={.25} >became a way to scale up the process of setting up friends and expanding people’s networks. After a couple of months of attempting </HomePageLine>
                    <HomePageLine index={.3} >different approaches and having conversations about dating with different people, Evan recruited his good friend Menachem </HomePageLine>
                    <HomePageLine index={.35} > to create a system in which people would be able to help their friends find their match. After building a proof</HomePageLine>
                    <HomePageLine index={.4} >of concept concept and experiencing rapid growth MoDate threw various events and created "The MoPod".</HomePageLine>
                    <HomePageLine index={.5} > While The MoVerse was growing, it was clear that although the MoDate process had produced many dates, there </HomePageLine>
                    <HomePageLine index={.55} >was still work to be done to improve the process. Thus, Evan and Menachem started on a new web app which would streamline the process.</HomePageLine>
                    <HomePageLine index={.6} > Menachem’s background in software development enabled him to both design an easy to use UI/UX and build the front-end and backend</HomePageLine>
                    <HomePageLine index={.65} > of the new MoDate. Evan and Menachem spent months brainstorming the various capabilities and features of the new MoDate and </HomePageLine>
                    <HomePageLine index={.7} >are very excited to see the results of their labor and continue to pump the volume.</HomePageLine>
                </div>
            </div>
            <div className="w-full relative flex justify-center" id="works">
                <div className="w-full h-full absolute bg-[#1e293b] transform rotate-180 flex justify-center bg-[url('https://modate-photos.s3.amazonaws.com/heading-bg-wave-desktop.svg')] bg-repeat-x bg-bottom bg-[length:2200px_300px]"></div>
                <div className="z-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 p-8 mt-24 md:mt-40">

                    <HomePageCard span={1} startX={0} startY={200}>
                        <div className="text-blue-300 text-lg uppercase sticky top-0 w-full body-bg px-8 pt-8 pb-1 fredka">Connector Dashboard</div>
                        <div className="text-gray-300 leading-7 text-[14px] md:text-md px-8 pb-8">The Connector Dashboard is critical to learn in order to be successful in connecting your friends. Thankfully, it's not too complicated and we're here to help!</div>
                    </HomePageCard>

                    <HomePageCard span={2} startX={0} startY={-200}>
                        <div className="text-blue-300 text-lg uppercase sticky top-0 w-full body-bg px-8 pt-8 pb-1">New Matches</div>
                        <div className="text-gray-300 leading-7 text-[14px] md:text-md px-8 pb-8">Here you will be able to see all of the new matches for your MoDaters. Click start matching and select one of your MoDaters that has a match. Here you will be able to see the information about the potential match for your MoDater(s). If you flip the profile card by clicking “Flip Card” you will see additional information about the potential match. Connectors must then decide whether or not they believe a match is a good fit.</div>
                    </HomePageCard>

                    <HomePageCard span={1} startX={0} startY={0} index={.1}>
                        <div className="text-blue-300 text-sm sm:text-lg uppercase sticky top-0 w-full body-bg px-8 pt-8 pb-1 space-x-4">
                            <span>MoDater Reviews Needed</span>
                            <GradingIcon />
                        </div>
                        <div className="text-gray-300 leading-7 text-[14px] md:text-md px-8 pb-8">When a MoDater signs up and assigns you as their Connector, you will need to review them in order for them to become active. You will be prompted to answer a few personality questions about your MoDater that will later be visible to both the Connectors of their prospective matches and their prospective matches. On the off chance that someone you don’t know signs up as your MoDater you will be able to reject the opportunity to become their Connector.</div>
                    </HomePageCard>

                    <HomePageCard span={1} startX={0} startY={0} index={.2}>
                        <div className="text-blue-300 text-sm sm:text-lg uppercase sticky top-0 w-full body-bg px-8 pt-8 pb-1 space-x-4">
                            <span>Verified MoDaters</span> 
                            <VerifiedIcon />
                        </div>
                        <div className="text-gray-300 leading-7 text-[14px] md:text-md px-8 pb-8">This is your list of MoDaters that you have verified. If for whatever reason they no longer want to be active MoDaters, feel free to click on their names to access their profiles and click “Change Status” on their profiles and make them inactive so that they will no longer get matches.</div>
                    </HomePageCard>

                    <HomePageCard span={1} startX={0} startY={0} index={.3}>
                        <div className="text-blue-300 text-lg uppercase sticky top-0 w-full body-bg px-8 pt-8 pb-1">Matches Status</div>
                        <div className="text-gray-300 leading-7 text-[14px] md:text-md px-8 pb-8">Here you will be able to see the status of all open matches.</div>
                    </HomePageCard>

                    <HomePageCard span={1} index={.3}>
                        <div className="text-blue-300 text-lg uppercase sticky top-0 w-full body-bg px-8 pt-8 pb-1 fredka">MoDater Dashboard</div>
                        <div className="text-gray-300 leading-7 text-[14px] md:text-md px-8 pb-8">When you have a match you will be able to see the entire profile of your match. Based on their profile you will decide whether or not you’d like to go on a date. If a MoDater says yes to going on a date before the other MoDater has a chance to review the match then a MoDater will be able to check the status of that match in the MoDater Dashboard. The MoDater that you have potentially matched with will not be aware of your response if they have not yet responded, so you do not have to worry about saying yes or no right away. If you said yes to a MoDater and never heard back, there’s a chance that they either did not see the match or have become busy, but did not mark themselves inactive. Don’t be too hard on yourself.</div>
                    </HomePageCard>

                    <HomePageCard span={1} index={.3}>
                        <div className="text-blue-300 text-lg uppercase sticky top-0 w-full body-bg px-8 pt-8 pb-1">Profile</div>
                        <div className="text-gray-300 leading-7 text-[14px] md:text-md px-8 pb-8">You are always able to edit your profile if you would like to switch any answers from the original form that you filled out. You will also be able to change Connectors for whatever reason, but then you must wait for your new Connector to review you in order to become active again. You can mark yourself as inactive in order to stop receiving matches for whatever reason.</div>
                    </HomePageCard>

                    <HomePageCard span={1} index={.3}>
                        <div className="text-blue-300 text-lg uppercase sticky top-0 w-full body-bg px-8 pt-8 pb-1">Matches</div>
                        <div className="text-gray-300 leading-7 text-[14px] md:text-md px-8 pb-8">If both parties agree to a match then the accounts of those MoDaters will become inactive. If, unfortunately, things do not work out then those MoDaters will need to return to their accounts and close out the match.</div>
                    </HomePageCard>

                </div>
            </div>
            <div className="w-full h-[300px] relative flex justify-center">
                <div className="w-full h-full absolute bg-[#1e293b] flex justify-center bg-[url('https://modate-photos.s3.amazonaws.com/heading-bg-wave-desktop.svg')] bg-repeat-x bg-bottom bg-[length:2200px_300px]"></div>
            </div>
            <div className="w-full max-h-[1200px] relative flex flex-col md:flex-row justify-center items-center bg-[#ebebec] md:space-x-20 md:p-24 p-4 space-y-8 md:space-y-0">
                <div className="flex-1 max-w-[550px] min-h-[525px] max-h-[550px] rounded-lg shadow-lg shadow-black/80 nav-bg border border-gray-800 p-8 flex flex-col items-center z-10 space-y-4">
                    <img className="w-20 h-20 object-cover rounded-full ring-1 ring-white" src="https://modate-photos.s3.amazonaws.com/DR5A4865_40.jpeg" alt="" />
                    <div className="text-md text-blue-200 uppercase">Michael Losev - CTO</div>
                    <div className="text-gray-300 text-xs leading-6 md:text-sm md:leading-7 md:font-medium overflow-y-scroll">Born and raised in the 5 towns, Michael Losev attended the Yeshiva of South Shore and MAY before graduating from New York University in May 2020 with a degree in computer science. Michael has a passion for building products that are intuitive and easy to use that also look great. While not currently employed he has recently begun actively looking for potential opportunities to grow and develop in his career. Some of Michael's other interests include traveling (cross-countries/national parks), options trading, and snowboarding.</div>
                </div>
                <div className="flex-1 max-w-[550px] min-h-[525px] max-h-[550px] rounded-lg shadow-lg shadow-black/80 nav-bg border border-gray-800 p-8 flex flex-col items-center z-10 space-y-4">
                    <img className="w-20 h-20 object-cover rounded-full ring-1 ring-white" src="https://modate-photos.s3.amazonaws.com/214725273_167503965458376_1974978964709784230_n.jpeg" alt="" />
                    <div className="text-md text-blue-200 uppercase">Evan Harris - CEO</div>
                    <div className="text-gray-300 text-xs leading-6 md:text-sm md:leading-7 md:font-medium overflow-y-scroll">Evan Harris grew up Modern Orthodox in the 5 towns community of New York. He attended HAFTR, Yeshivat Orayta, and went on to graduate summa cum laude with a degree in finance from the Macaulay Honors program at Baruch College. Evan currently works in a marketing strategy role while living on the Upper West Side of Manhattan. Growing the MoVerse which includes MoDate, The MoPod, MoEvents, & more is something that Evan is very passionate about. There are two types of people in this world: those who make things happen and those who let things happen to them. Evan always strives to make things happen and encourages others to do the same.</div>
                </div>
            </div>

            <div id="contacts">
            <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="footer-col">
                        <h4>Modate</h4>
                        <ul>
                        <li><a href="/" onClick={e => {
                                let about = document.getElementById("about");
                                e.preventDefault();
                                about && about.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}>About Us</a></li>
                        <li><a href="/" onClick={e => {
                            let works = document.getElementById("works");
                            e.preventDefault();
                            works && works.scrollIntoView({ behavior: "smooth", block: "start" });
                         }}>Our Services</a></li>
                        {<li><Link to= "/TermsAndCond">Terms Of Use</Link></li> }
                        {<li><Link to= "/PrivacyPolicy">Privacy Policy</Link></li> }
                            </ul> 

                    </div>
                    <div class="footer-col">
                        <h4>Contact Us</h4>
                        <ul>
                            <li><a target='_blank' href="mailto:modate613@gmail.com">Email modate613@gmail.com</a></li>
                        </ul>
                    </div>
                    <div class="footer-col">
                        <h4>Modate Featured</h4>
                        <ul>
                            <li><a target="_blank" href="https://jewishlink.news/features/56204-modate-is-the-jewish-dating-platform-of-the-future">The Jewish Link</a></li>
                            <li><a target="_blank" href="https://yuobserver.org/2022/05/modate-the-mutual-friend-you-wish-you-had/">YU Observer</a></li>
                            <li><a target="_blank" href="https://nachumsegal.com/modate-dating-platform-featured-on-jm-in-the-am/">Nachum Segal Network</a></li>
                            <li><a target="_blank" href="https://www.globaldatinginsights.com/news/modate-the-next-big-jewish-dating-site/">Global Dating Insights</a></li>
                        </ul>
                    </div>
                    <div class="footer-col">
                        <h4>follow us on instagram</h4>
                        <div>
                            {/* <a href="#"><i class="fab fa-facebook-f"></i></a> */}
                            <a href="https://www.instagram.com/modate613/?hl=en" target="_blank" rel="noopener noreferrer" class="text-gray-400 hover:text-gray-500"><span class="sr-only">Instagram</span><svg fill="currentColor" viewBox="0 0 24 24" class="h-6 w-6" aria-hidden="true"><path d="M12,9.52A2.48,2.48,0,1,0,14.48,12,2.48,2.48,0,0,0,12,9.52Zm9.93-2.45a6.53,6.53,0,0,0-.42-2.26,4,4,0,0,0-2.32-2.32,6.53,6.53,0,0,0-2.26-.42C15.64,2,15.26,2,12,2s-3.64,0-4.93.07a6.53,6.53,0,0,0-2.26.42A4,4,0,0,0,2.49,4.81a6.53,6.53,0,0,0-.42,2.26C2,8.36,2,8.74,2,12s0,3.64.07,4.93a6.86,6.86,0,0,0,.42,2.27,3.94,3.94,0,0,0,.91,1.4,3.89,3.89,0,0,0,1.41.91,6.53,6.53,0,0,0,2.26.42C8.36,22,8.74,22,12,22s3.64,0,4.93-.07a6.53,6.53,0,0,0,2.26-.42,3.89,3.89,0,0,0,1.41-.91,3.94,3.94,0,0,0,.91-1.4,6.6,6.6,0,0,0,.42-2.27C22,15.64,22,15.26,22,12S22,8.36,21.93,7.07Zm-2.54,8A5.73,5.73,0,0,1,19,16.87,3.86,3.86,0,0,1,16.87,19a5.73,5.73,0,0,1-1.81.35c-.79,0-1,0-3.06,0s-2.27,0-3.06,0A5.73,5.73,0,0,1,7.13,19a3.51,3.51,0,0,1-1.31-.86A3.51,3.51,0,0,1,5,16.87a5.49,5.49,0,0,1-.34-1.81c0-.79,0-1,0-3.06s0-2.27,0-3.06A5.49,5.49,0,0,1,5,7.13a3.51,3.51,0,0,1,.86-1.31A3.59,3.59,0,0,1,7.13,5a5.73,5.73,0,0,1,1.81-.35h0c.79,0,1,0,3.06,0s2.27,0,3.06,0A5.73,5.73,0,0,1,16.87,5a3.51,3.51,0,0,1,1.31.86A3.51,3.51,0,0,1,19,7.13a5.73,5.73,0,0,1,.35,1.81c0,.79,0,1,0,3.06S19.42,14.27,19.39,15.06Zm-1.6-7.44a2.38,2.38,0,0,0-1.41-1.41A4,4,0,0,0,15,6c-.78,0-1,0-3,0s-2.22,0-3,0a4,4,0,0,0-1.38.26A2.38,2.38,0,0,0,6.21,7.62,4.27,4.27,0,0,0,6,9c0,.78,0,1,0,3s0,2.22,0,3a4.27,4.27,0,0,0,.26,1.38,2.38,2.38,0,0,0,1.41,1.41A4.27,4.27,0,0,0,9,18.05H9c.78,0,1,0,3,0s2.22,0,3,0a4,4,0,0,0,1.38-.26,2.38,2.38,0,0,0,1.41-1.41A4,4,0,0,0,18.05,15c0-.78,0-1,0-3s0-2.22,0-3A3.78,3.78,0,0,0,17.79,7.62ZM12,15.82A3.81,3.81,0,0,1,8.19,12h0A3.82,3.82,0,1,1,12,15.82Zm4-6.89a.9.9,0,0,1,0-1.79h0a.9.9,0,0,1,0,1.79Z"></path></svg></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </div>
                

        </>
    )
}

export default HowItWorks