import React, { useState } from 'react';

const MyersBriggsJudging_Perceiving = () => {
    const [isImageVisible, setIsImageVisible] = useState(false);
    const [isButtonDarkened, setIsButtonDarkened] = useState(false);
  
    const handleMouseEnter = () => {
      setIsButtonDarkened(true);
    };
  
      const handleMouseLeave = () => {
        setIsButtonDarkened(false);
      };
      
      const handleImageClickOn = (event) => {
        event.preventDefault();
        setIsImageVisible((prevState) => !prevState);
      };
    
  
    const handleImageClickOff = (event) => {
      event.preventDefault();
      setIsImageVisible(false);
    };
  
    const buttonClasses = isButtonDarkened
    ? 'w-6 h-6 flex items-center justify-center bg-gray-500 hover:bg-gray-400 rounded-full cursor-pointer absolute top-0 right-0'
    : 'w-6 h-6 flex items-center justify-center bg-gray-700 hover:bg-gray-300 rounded-full cursor-pointer absolute top-0 right-0';
  
    return (
      <div className="w-full relative">
      <div className="flex items-center">
        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
        Judging (J) or Perceiving (P)
        </label>
          <button
          className={buttonClasses}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleImageClickOn}
        >
          <span className="text-base font-bold text-gray-700">i</span>
        </button>
      </div>
      {isImageVisible && (
        <div className="relative">
          <img
            src="https://modate-photos.s3.amazonaws.com/IntroOrExtra.png"
            alt=""
            className="w-full border rounded-md"
          />
          <button
            className="absolute top-2 right-2 text-black hover:text-black"
            onClick={handleImageClickOff}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
  };

  export default MyersBriggsJudging_Perceiving;