import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { TokenContext } from "../../Components/utilities/accessTokenContext";
import {
  getAllConnectorsAdmin,
  getConnectorAdmin,
  getNumberOfConnectorPages,
  getConnectorOptionsAdmin,
  getRefreshTokenBeforeApiCall,
} from "../../Components/utilities/EndPoints";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import ConnectorInfoDropdown from "../../Components/ConnectorInfoDropdown";
import { useLocalStorage } from "../../Components/utilities/hooks";
import AsyncSelect from "react-select/async";
import { STYLES } from "../../constants.js";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
      background: "transparent",
      "&.Mui-selected": {
        color: "#92c5fd",
      },
    },
    "& .MuiPaginationItem-root:hover": {
      background: "#272829",
    },
    "& .MuiPaginationItem-root:active": {
      background: "#272829",
    },
  },
}));

function ConnectorInfo() {
  const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);
  const [connectors, setConnectors] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useLocalStorage(
    "currentConnectorPage",
    +localStorage.getItem("currentConnectorPage")
  );
  const [queryResult, setQueryResult] = useState(null);
  const [selected, setSelected] = useLocalStorage(
    "currentSelectedConnector",
    JSON.parse(localStorage.getItem("currentSelectedConnector"))
  );
  const [input, setInput] = useState("");
  const _isMounted = useRef(true);
  const classes = useStyles();

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  // console.log(input)
  // console.log(selected)
  // console.log(queryResult)
  // console.log(token)

  const debounce = (func, delay) => {
    let id;
    // console.log('id is', id);
    return (...args) => {
      return new Promise((resolve) => {
        if (id) {
          clearTimeout(id);
        }
        id = setTimeout(async () => {
          const res = await func(...args);
          resolve(res);
        }, delay);
      });
    };
  };

  const loadOptions = (input) => {
    return getRefreshTokenBeforeApiCall(
      token,
      setToken,
      setLoggedIn,
      navigate,
      getConnectorOptionsAdmin,
      [input, _isMounted]
    );
  };

  const debounced = useCallback(debounce(loadOptions, 400), [token]);

  useEffect(() => {
    getRefreshTokenBeforeApiCall(
      token,
      setToken,
      setLoggedIn,
      navigate,
      getNumberOfConnectorPages,
      [setTotalPages, setCurrentPage, _isMounted]
    );
  }, []);

  useEffect(() => {
    if (!isNaN(currentPage)) {
      getRefreshTokenBeforeApiCall(
        token,
        setToken,
        setLoggedIn,
        navigate,
        getAllConnectorsAdmin,
        [setConnectors, currentPage, _isMounted]
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (selected) {
      getRefreshTokenBeforeApiCall(
        token,
        setToken,
        setLoggedIn,
        navigate,
        getConnectorAdmin,
        [setQueryResult, selected.label, _isMounted]
      );
    }
  }, [selected]);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <>
      <div className="w-full max-w-[896px] z-20">
        <AsyncSelect
          loadOptions={debounced}
          isClearable
          onInputChange={setInput}
          onChange={setSelected}
          value={selected}
          className="w-[300px]"
          styles={STYLES}
        />
      </div>
      <div className="nav-bg w-full rounded-md shadow-md shadow-black border border-gray-800 overflow-y-scroll h-[475px] max-w-[896px] relative overflow-x-scroll">
        <div className="flex h-16 min-w-[896px] items-center p-6 sidebar-bg sticky top-0 border-b border-gray-800 z-10">
          <div className="ml-4 w-[30%] uppercase text-sm tracking-widest">
            Name
          </div>
          <div className="relative w-[20%] flex items-center uppercase text-sm tracking-widest">
            <span className="font-thin absolute -left-8 border-0 border-l border-gray-700 h-full"></span>
            <div className="">modaters</div>
          </div>
          <div className="relative w-[25%] flex items-center uppercase text-sm tracking-widest">
            <span className="font-thin absolute -left-8 border-0 border-l border-gray-700 h-full"></span>
            <div className="">Phone Number</div>
          </div>
          <div className="relative w-[25%] flex items-center uppercase text-sm tracking-widest">
            <span className="font-thin absolute -left-8 border-0 border-l border-gray-700 h-full"></span>
            <div className="">email</div>
          </div>
        </div>
        <div className="flex flex-col">
          {selected
            ? queryResult?.map((connector) => (
                <ConnectorInfoDropdown
                  connector={connector}
                  key={connector.connector._id}
                />
              ))
            : connectors.map((connector) => (
                <ConnectorInfoDropdown
                  connector={connector}
                  key={connector.connector._id}
                />
              ))}
        </div>
      </div>
      <>
        {totalPages && currentPage && (
          <div className="nav-bg rounded-md">
            {!selected && (
              <Pagination
                count={totalPages}
                classes={{ ul: classes.ul }}
                page={+currentPage}
                onChange={handleChange}
                sx={{ color: "white" }}
              />
            )}
          </div>
        )}
      </>
    </>
  );
}

export default ConnectorInfo;
