import React, { useState, useEffect, useContext, useRef } from 'react'
import { TokenContext } from '../../Components/utilities/accessTokenContext';
import { getAllUserActions, getNumberOfActionPages, getRefreshTokenBeforeApiCall } from '../../Components/utilities/EndPoints';
import ActionItem from '../../Components/ActionItem';
import dateFormat from 'dateformat';
import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import { useLocalStorage } from '../../Components/utilities/hooks';

const useStyles = makeStyles(() => ({
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#fff",
            background: "transparent",
            '&.Mui-selected': {
                color: '#92c5fd',
            },
        },
        "& .MuiPaginationItem-root:hover": {
            background: "#272829",
        },
        "& .MuiPaginationItem-root:active": {
            background: "#272829",
        }
    }
}));

function UserActions() {

    const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);
    const [actions, setActions] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useLocalStorage('currentActionPage', +localStorage.getItem('currentActionPage'));
    const _isMounted = useRef(true);
    const classes = useStyles();

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getNumberOfActionPages, [setTotalPages, setCurrentPage, _isMounted]);
    }, [])

    useEffect(() => {
        if (!isNaN(currentPage)) {
            getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getAllUserActions, [setActions, currentPage, _isMounted]);
        }
    }, [currentPage])

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    return (
        <>
            <div className="nav-bg w-full rounded-md shadow-md shadow-black border border-gray-800 p-6 overflow-y-scroll h-[475px] max-w-[896px] relative">
                <div className="flex flex-col space-y-3 justify-center items-center">
                    {
                        actions.map(action => {
                            return (
                                <div key={action._id} className="w-full flex items-center space-x-4">
                                    <div className="text-xs flex justify-start text-blue-200">{dateFormat(new Date(action.logTime), 'm/dd/yy h:MMTT')}</div>
                                    <ActionItem action={action}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <>
                {totalPages && currentPage && 
                    <div className="nav-bg rounded-md">
                        <Pagination count={totalPages} classes={{ ul: classes.ul }} page={+currentPage} onChange={handleChange} sx={{color: 'white'}}/>
                    </div>
                }
            </>
        </>
    )
}

export default UserActions