import React, { useState, useContext } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

function VerifyConnectorInfoDropdown({ connector, handleVerificationClick }) {

    const [open, setOpen] = useState(false);
    
    return (
        <>
            <div key={connector._id} className="min-w-[896px] flex p-2 items-center border-b border-gray-800">
                <div className="ml-4 w-[20%] p-2">
                    <button onClick={() => setOpen(prev => !prev)} className={`${open ? 'transfrom duration-200 rotate-90' : 'transform duration-200'} px-2 text-gray-400`}>
                        <ArrowRightIcon />
                    </button>
                    <span className="text-blue-300">{connector.connector.firstName} {connector.connector.lastName}</span>
                </div>
                <span className="w-[20%] p-2 text-sm">{connector.modaters.length}</span>
                <span className="-ml-4 w-[20%] p-2 tracking-widest text-xs">{connector.connector.phoneNumber}</span>
                <span className="-ml-2 w-[20%] p-2 text-xs">{connector.email}</span>
                <span className="-ml-2 w-[20%] p-2 text-xs">
                    <button onClick={handleVerificationClick(connector.connector._id)} className="px-3 inline-block uppercase text-[#ffffff] bg-[#2e8bc0] rounded-sm border border-[#ffffff] py-0.5 text-xs font-medium tracking-widest">verify</button>
                </span>
            </div>
            <AnimatePresence >
                { open && 
                    <div className="body-bg px-6">
                        <motion.div className={`w-full flex-wrap flex justify-start overflow-x-scroll`} initial={{opacity: 0, y: -10, maxHeight: 0}} animate={{opacity: 1, y: 0, maxHeight:1000}} transition={{ type: "tween", duration: .4 }} exit={{ opacity: 0, y: -10, height: 0 }}>
                            { connector.modaters.map(modater => {
                                return (
                                    <Link to={`/dashboard/adminDashboard/modaterProfile/${modater._id}`} className={`flex min-w-[120px] flex-col justify-center items-center  text-xs space-y-2 mt-6 mb-6`} key={modater._id}>
                                        <div className="relative w-12 h-12">
                                            <img className={`shadow-lg shadow-black h-full w-full object-cover rounded-full ring-1 ring-gray-700 outline-none`} src={modater.photoUrls[0]?.url ? modater.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                                            <div className={`absolute bottom-0 right-0 ${modater.activeStatus ? 'bg-green-600' : 'bg-red-600'} h-3 w-3 rounded-full`}></div>
                                        </div>
                                        <span className="text-ellipsis overflow-hidden">{modater.firstName} {modater.lastName}</span>
                                    </Link>
                                )
                            })}
                        </motion.div>
                    </div>
                }
            </AnimatePresence>
            
        </>
    )
}

export default VerifyConnectorInfoDropdown;