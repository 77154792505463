import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Error from '../Components/utilities/Error';
import { useNavigate } from 'react-router-dom';
import '../Components/nav.css';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { checkIfLoggedIn, registerUser } from '../Components/utilities/EndPoints';

const schema = yup.object().shape({
    email: yup.string().email().required('Please enter a valid Email'),
    password: yup.string().min(6).max(15).required('Password must be between 6 and 15 Characters long'),
    confirmPassword:  yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required')
})

const SignUp = () => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const [route, setRoute] = useState('/')
    const [formError, setFormError] = useState();
    const [disabled, setDisabled] = useState(false);
    const {token, setToken, setLoggedIn, navigate} = useContext(TokenContext);


    const submitForm = async data => {
        registerUser(data, setToken, setLoggedIn, setFormError, navigate, route, setDisabled);
    }
    
    useEffect(() => {
        (async () => {
            const logged = await checkIfLoggedIn(token)
            if (logged) {
                navigate('/dashboard')
            }
        })()
    }, [])

    return (
        <form onSubmit={ handleSubmit(submitForm) } className="flex justify-center items-center h-full special text-gray-200">
            <div className="flex flex-col w-full sm:w-1/2 lg:w-2/5 xl:w-2/6 h-1/2 justify-center items-center space-y-4 bg-transparent px-10 tracking-wider">
                <div className="mb-4 text-2xl font-light">Sign Up</div>
                {formError && <div className="text-red-400">{formError}</div>}
                <div className="flex flex-col w-full py-2">
                    <label className="flex justify-start mb-4 text-sm text-grey-darkest ml-6">Email Address</label>
                    <input { ...register('email') } type="email" placeholder="Email" className="border py-2 px-6 text-grey-darkest rounded-full focus:outline-none focus:bg-gray-200 text-black"/>
                    {errors.email && <Error errorMessage={errors.email.message} /> }
                </div>
                <div className="flex flex-col w-full py-2">
                    <label className="flex justify-start mb-4 text-sm text-grey-darkest ml-6">Password</label>
                    <input { ...register('password') } type="password" placeholder="Password" className="border py-2 px-6 text-grey-darkest rounded-full focus:outline-none focus:bg-gray-200 text-black"/>
                    {errors.password && <Error errorMessage={errors.password.message} /> }
                </div>
                <div className="flex flex-col w-full py-2">
                    <label className="flex justify-start mb-4 text-sm text-grey-darkest ml-6">Confirm Password</label>
                    <input { ...register('confirmPassword') } type="password" className="border py-2 px-6 text-grey-darkest rounded-full focus:outline-none focus:bg-gray-200 text-black"/>
                    {errors.confirmPassword && <Error errorMessage={errors.confirmPassword.message} /> }
                </div>
                <div className="w-full space-y-4">
                    <div className="space-y-2" onClick={() => {
                        setRoute('/connectorSignUp')
                    }}>
                        <p className="text-xs tracking-normal font-light ml-4">Connect your friends with other MoDaters</p>
                        <div className="flex flex-col w-full">
                            <button type="submit" className="block bg-blue-600 uppercase p-3 font-light hover:bg-blue-900 text-xs rounded-full text-white w-full" disabled={disabled}>Sign Up As A Connector</button>
                        </div>
                    </div>
                    <div className="space-y-2" onClick={() => {
                        setRoute('/modaterSignUp')
                    }}>
                        <p className="text-xs tracking-normal font-light ml-4">Get matched with other MoDaters (To sign up as MoDater you must have a Connector. If you do not know of any current Connectors we recommend asking a friend to become your Connector)</p>
                        <div className="flex flex-col w-full" >
                            <button type="submit" className="block bg-blue-600 uppercase p-3 font-light hover:bg-blue-900 text-xs rounded-full text-white w-full" disabled={disabled}>Sign Up As A MoDater</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default SignUp;