import { yellow } from '@mui/material/colors';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import styled from '@emotion/styled';

const WhiteText = styled.div`
  color: white;
`;

const PrivacyPolicy = () => {
  return (
    <>
      <WhiteText>
        <React.Fragment>
          <h1 style={{ color: "#0093D1" }} className='text-center text-xl'>
            <strong>
              This Privacy Policy governs your use of the MoDate Website/App (“Site/App”) and explains how we collect, use, and disclose your information.
            </strong>
          </h1>
          {/* Scrollable box */}
          <div
            style={{
              maxHeight: '500px',
              maxWidth: '1000px',
              overflowY: 'auto',
              border: '2px solid #ccc',
              padding: '10px',
              margin: '0 auto',
              height: '100%',
            }}
          >
            <p>
              You agree to the use of your information as described in this Privacy Policy each time you visit the Site/App, so we urge you to read this document in full and Contact Us at modate613@gmail.com if you have any questions. This Privacy Policy is incorporated into, and part of, the Site/App’s Terms of Use, which also governs your use of the Site/App. Your privacy is a serious matter to us. This Privacy Policy explains, in general, what Personal Information and other information are collected on our Site/App, how the information is used, and with whom we may share such information. Please take a few minutes to read our Privacy Policy so that you understand how we treat your information.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Collection and Use of Personal Information</strong>
            </p>

            <p>
              Personal Information is information that can be used to directly or indirectly identify you. This can include, for example, name; address; photos; e-mail address; telephone, wireless and/or fax number; short message service or text message address or other wireless device address; instant messaging address; or credit card number. Personal information also includes anonymous information that is linked to information that can be used to directly or indirectly identify you. Personal Information does not include information that has been irreversibly anonymized or aggregated so that it can no longer enable us, whether in combination with other information or otherwise, to identify you. This Privacy Policy does not apply to information that you may provide to us, or that we may obtain, other than through our Site/App, such as by phone, through postal mail and through other live and print media.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>What Personal Information We Collect</strong>
            </p>

            <p>
              In order to make your use of our Site/App as worthwhile as possible, we may gather different kinds of Personal Information from or about you.
            </p>

            <p>
              <strong>Information you provide:</strong> We collect the Personal Information you provide when you use the Site/App or otherwise interact with us, through users’ activities and the completion of online forms such as when users register or subscribe for accounts or features on our Site/App, order event tickets, complete surveys, join online communities, create profile pages, upload photographs, contribute to open forums that we may make available on or through our Site/App, submit a comment or question to us using a “Contact Us” or similar feature on our Site/App, send us an e-mail, or in any other way submit Personal Information to us via our Site/App.
            </p>

            <p>
              <strong>Information about payments:</strong> When you make a purchase or donation through the Site/App, we may collect your credit card number or other payment account number (for example, your wireless account number), billing address, and other information related to such purchase (collectively, “Payment Information”) from you and may use Payment Information to fulfill your purchase. We may also provide Payment Information or other Personal Information provided by you to third parties as necessary to complete your transaction (for example, to process your credit card or payment account number). To the extent that Payment Information includes Non-Personal Information, use of Non-Personal Information is governed by the section on Non-Personal Information. We also may use third-party payment services (each, a “Payment Service”) to collect payments made through the Site/App. If you wish to make a payment through the Site/App using third-party payment services, you will be directed to a webpage that is hosted by the Payment Service (or the Payment Service’s service providers) and not by us (such page, the “Payment Service Page”). Any Personal Information that you provide through the Payment Service Page will be collected by the Payment Service and not by us and will be subject to the Payment Service’s privacy policy, rather than this Policy. We have no control over, and shall not be responsible for, the Payment Service’s use of information collected through the Payment Service Page.
            </p>

            <p>
              <strong>Information about the use of our services:</strong> When you visit the Site/App, we may collect information about the type of device you use, your device’s unique identifier, the IP address of your device, the type of Internet browser that you use, usage information, diagnostic information, and location information from or about the computers, phones, or other devices on which you install or access our products or services. Where available, our services may use GPS, your IP address, and other technologies to determine a device’s approximate location to allow us to improve our programs and services.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>How We Use Your Personal Information</strong>
            </p>

            <p>
              Generally speaking, we use Personal Information for internal business and record-keeping purposes; provide, improve, and develop our programs and services; to communicate with you; to offer targeted advertisements and programs; and to protect us and our users.
            </p>

            <p>
              <strong>Provide you requested programs and services:</strong> We use Personal Information to help us provide you the programs and services you request and to respond to your inquiries about our offerings.
            </p>

            <p>
              <strong>Provide, improve, and develop our programs and services:</strong> We use Personal Information to help us provide, improve, and develop our programs, services, and advertising. This includes using Personal Information for purposes such as data analysis, research, and audits. Such processing is based on our legitimate interest in offering you programs and services and for business continuity.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Communicate with you:</strong>
            </p>

            <p>
              We may use Personal Information to offer you programs or services that we believe may be of interest to you, to notify you when we make changes to our services, to fulfill a request by you, to process transactions, or to contact you about your relationship with us. We may send you notices (e.g., in the form of emails, mailings) and otherwise correspond with you about programs and events, sponsored by us and others, that we think might interest you.
            </p>

            <p>
              <strong>Promote safety and security:</strong> We use Personal Information to help verify accounts and user activity, as well as to promote safety and security, such as by monitoring fraud and investigating suspicious or potentially illegal activity or violations of our terms or policies. Such processing is based on our legitimate interest in helping ensure the safety of our programs and services.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Disclosure of Personal Information</strong>
            </p>

            <p>
              We make certain personal information available to strategic partners that work with us to provide our programs and services.
            </p>

            <h1 style={{ color: "#FBCF0B" }}>
              <strong>Public Forums:</strong>
            </h1>

            <p>
              When you contribute to a public area or feature of our Site/App, such as a chat room, community, bulletin board, list serve, personal Web page, calendar, news group, blog, wiki, or other open forum that we may make available on or through our Site/App, the information that you submit may be made available to the general public. For this reason, we recommend that you exercise caution when submitting any sensitive information, including your full name, home address, phone number, other information that would enable other users to locate you, or financial information on these areas of our Site/App. Within these public forums, we may provide you privacy options that allow you to restrict the ability of others to use and view your Personal Information.
            </p>

            <h2 style={{ color: "#FBCF0B" }}>
              <strong>Third-Party Offers:</strong>
            </h2>

            <p>
              We may provide visitors an opportunity to receive offers from other third-party entities that have services, programs, and offers that we think might be of interest to you. We may from time to time provide your Personal Information to these entities so that they may send you these offers. These companies are obligated to protect your information. You may withdraw your consent to receive such offers by following the instructions in the Your Rights: Accessing, Correcting, and Deleting Personal Information section below.
            </p>

            <h3 style={{ color: "#FBCF0B" }}>
              <strong>Service Providers:</strong>
            </h3>

            <p>
              We may use third-party service providers to help operate our Site/App and provide services, including but not limited to data analysis, payment processing, order fulfillment, and other services of an administrative nature. We may share Personal Information about you with these third parties for the purpose of enabling them to provide services to you or to us (for example, if you choose to make a purchase through the Site/App, we may share your Payment Information or other Personal Information with our third-party service providers to fulfill your purchase, including, to process your credit card or payment account number or to ship products to you).
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Legally Compelled Disclosures; Emergencies:</strong>
            </p>

            <p>
              We may disclose Personal Information to others as we believe to be appropriate (a) where permitted or required by applicable law; (b) to comply with legal process; (c) to respond to requests from governmental or public authorities; (d) to enforce our Terms of Service; (e) to protect our operations; (f) to protect the rights, privacy, safety, or property of us, you, or others; and (g) to permit us to pursue available remedies or limit the damages that we may sustain.
            </p>

            <p>
              Assignment: We reserve the right to transfer any and all information that we collect from Site/App users to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our operation or assets (including without limitation in connection with any bankruptcy or similar proceedings). Your express consent will be obtained if, for any of the above actions or events, it is required under applicable law.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Non-Personal Information</strong>
            </p>

            <p>
              When you visit and interact with the Site/App, we and our third-party service providers may collect Non-Personal Information (for example, a catalog of Site/App pages you visit). Non-Personal Information is generally collected through the Site/App from different sources: (i) cookies; (ii) clear GIFs and similar technologies; (iii) environmental variables; and (iv) information that you voluntarily provide.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Cookies:</strong> 
              </p>
              <p>
              We and our service providers may use “cookies” to keep, and sometimes track, information about you. Cookies are small data files that are sent to your browser or related software from a Web server and stored on your computer’s hard drive. Cookies are data that a web server transfers to an individual’s computer for recordkeeping and other purposes. Cookies are an industry standard used by most websites and can facilitate users’ ongoing access to and use of a particular website; cookies do not cause damage to your computer systems or files. If you do not want information collected through the use of cookies, there is a simple procedure in most browsers that allows you to automatically decline cookies or to be given the choice of declining or accepting the transfer of a particular cookie or cookies from a particular website to your computer. Please read our Cookie Policy to learn more about how we use cookies and the choices you have.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Clear GIFs:</strong></p>
              <p>We and our service providers may use “clear GIFs” (aka “Web beacons” or “pixel tags”) or similar technologies, in the Site/App and/or in our communications with you to enable us to know whether you have visited a Web page or received a message. A clear GIF is typically a one-pixel, transparent image (although it can be a visible image as well), located on a Web page or in an e-mail or other type of message, which is used to, among other things, track the actions of Site/App users and email recipients, to determine the success of marketing campaigns, and to compile aggregate statistics about Site/App usage and response rates.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Environmental Variables:</strong> 
            </p> 
            <p>
                We and our service providers may also collect certain environmental variables, such as your MAC address, computer type (Windows or Macintosh), screen resolution, OS version, Internet browser, and Internet browser version. These environmental variables are collected by most websites and can be used to optimize your experience on the Site/App.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Information That You Voluntarily Provide:</strong></p>
              <p> We also collect Non-Personal Information (e.g., demographic information, your interests, your geographic location) when you voluntarily provide such information to us. This information is Non-Personal Information because, unless combined with Personal Information, it does not personally identify you or any other user. Additionally, we may aggregate Personal Information in a manner such that the end-product does not personally identify you or any other user of the Site/App. For example, by using Personal Information to calculate the percentage of our users who have a particular telephone area code. Such aggregate information is considered Non-Personal Information for purposes of this Policy.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>IP Address:</strong></p>
            <p>
                Our servers may automatically collect data about your server’s Internet address when you visit us. This information, known as an Internet Protocol address, or IP Address, is a number that’s automatically assigned to your computer by your Internet service provider whenever you’re on the Internet. When you request pages from our Site/App, our servers may log your IP Address and sometimes your domain name. Our server may also record the referring page that linked you to us (e.g., another Web site or a search engine); the pages you visit on this Site/App; the Web site you visit after this Site/App; other information about the type of Web browser, computer, platform, related software and settings you are using; any search terms you have entered on this Site/App or a referral site; and other Web usage activity and data logged by our Web servers. We and our service providers use this information for internal system administration, to help diagnose problems with our server, and to administer our Site/App. Such information may also be used to gather broad demographic information, such as the country of origin and Internet Service Provider.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Disclosure of Non-Personal Information</strong>
            </p>

            <p>
              Because Non-Personal Information does not personally identify you, we may use such information for any purpose. In addition, we reserve the right to share Non-Personal Information with third parties for any purpose.
            </p>

            <p>
              In some instances, we may combine Non-Personal Information or IP Addresses with Personal Information (such as combining your name with your geographic location). If we do combine any Non-Personal Information or IP Addresses with Personal Information, we will treat the combined information as Personal Information as long as it is combined.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Your Rights: Accessing, Correcting, and Deleting Personal Information</strong>
            </p>

            <p>
              We take reasonable steps to ensure that your personal information is accurate, complete, and up to date. You have the right to access, correct, or delete the personal information that we collect. You are also entitled to object, at any time, to the further processing of your personal information. You have the right to receive your personal information in a structured and standard format. You may lodge a complaint with the competent data protection authority regarding the processing of your personal information.
            </p>

            <p>
              You may have the right to make choices regarding certain uses of your Personal Information. For example, at the time you are requested to provide Personal Information on this Site/App, you may have the opportunity to consent to receiving certain types of correspondence from us or to whether your information will be shared with third parties for marketing purposes. You may also make a request regarding how your information is used by sending an e-mail to modate613@gmail.com. Clearly indicate what choices you are making (electing not to receive promotional communications messages from us or requesting that we not share information with third parties for the third parties’ marketing purposes). Please understand that if you withdraw your consent or opt-out of receiving promotional correspondence from us, we may still contact you in connection with your relationship, activities, donations, and communications with us.
            </p>

            <p>
              Please note that if you withdraw your consent or opt-out as described above, we will not be able to remove Personal Information about you from the databases of third parties with which we have already shared Personal Information as of the date on which we implement your opt-out request. If you wish to cease receiving marketing-related emails from third parties, please contact the third parties directly or use any opt-out mechanisms set forth in their respective privacy policies or marketing-related emails.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Access and Correction</strong>
            </p>

            <p>
              If you would like to access Personal Information we have collected about you through the Website or if you would like to update, correct, or limit the use of any Personal Information you have provided to us via the Website, please Contact Us.
            </p>

            <p>
              For your protection, we will only implement such requests with respect to the Personal Information associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request and consistent with applicable law. You must clearly indicate the Personal Information that you wish to have changed, clearly indicate that you wish to have your Personal Information or clearly indicate the limitations you wish to put on our use of your Personal Information. We may need to retain certain information for recordkeeping purposes, and there may also be residual information that will remain within our databases and other records, which will not be removed.
            </p>

            <p>
              You may Contact Us to exercise your rights. We will respond to your request in a reasonable timeframe, and in any event in less than 30 days.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Closing Accounts</strong>
            </p>

            <p>
              Please Contact Us to close your account. If you close your account, we have no obligation to retain your information, and we may delete any or all of your information without liability.
            </p>

            <p>
              However, we may retain information related to you if we believe it may be necessary to prevent fraud or future abuse, if required by law, or for legitimate purposes, such as analysis of non-personal information, account recovery, auditing our records, and enforcing our rights and obligations under our agreements. Also, if you have posted content on or through the Site/App, we may not be able to delete it.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Children</strong>
            </p>

            <p>
              The Site/App is not directed to individuals under the age of 18, and we request that these individuals do not provide Personal Information through the Site/App. If we learn that we have collected Personal Information from an individual under the age of 18, we will take steps to delete the information as soon as possible. If you become aware that a person under the age of 18 has provided Personal Information through the Site/App, please Contact Us.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Links to Other Websites</strong>
            </p>

            <p>
              The Site/App may contain links to other websites that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other websites or third parties. We encourage you to be aware when you leave the Site/App and to read the privacy policies of each and every website that collects Personal Information.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Changes to Privacy Policy</strong>
            </p>

            <p>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will indicate the date that revisions were last made by updating the Effective Date at the top of this page.
            </p>

            <p style={{ color: "#FBCF0B" }}>
              <strong>Contact Us</strong>
            </p>

            <p>
              If you have any questions or suggestions about our Privacy Policy, do not hesitate to Contact Us at modate613@gmail.com.
            </p>

        





                </div>
            </React.Fragment>
        </WhiteText>
    </>       
    );
}
export default PrivacyPolicy;