import React, { useState, useEffect, useContext } from 'react';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import { TokenContext } from './utilities/accessTokenContext';
import { getRefreshTokenBeforeApiCall, reviewMatchByModater } from './utilities/EndPoints';
import { motion } from 'framer-motion';
import PhotoCarousel from './PhotoCarousel';

function RateCard({ urls, id, setModal, setHeader, setBody, setHandler, setDisabled }) {

    const {token, setToken, setLoggedIn, navigate, setError, setUser } = useContext(TokenContext);
    const [curImageIndex, setCurImageIndex] = useState(0);
    const [isAMatch, setIsAMatch] = useState(false);
    const [reviewed, setReviewed] = useState(false);
    const [likedMatch, setLikedMatch] = useState(false);

    const likeMatch = (id) => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, reviewMatchByModater, [id, true, setUser, setDisabled, setError, navigate, setIsAMatch, setModal, setReviewed, setLikedMatch]);
    }

    const rejectMatch = (id) => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, reviewMatchByModater, [id, false, setUser, setDisabled, setError, navigate, setIsAMatch, setModal, setReviewed, setLikedMatch]);
    }

    useEffect(() => {
        if (urls.length <= curImageIndex) {
            setCurImageIndex(0)
        }
    }, [urls])

    return (
        <div className="flex justify-center items-center flex-none">
            {/* ${height} ${width} */}
            <div className={`flex-none h-[28rem] shadow-xl shadow-black/60 w-80 rounded-lg bg-gray-200 special border border-gray-700 overflow-hidden relative`}>
                {/* image of person */}
                <PhotoCarousel
                    urls={urls}
                    curImageIndex={curImageIndex}
                    setCurImageIndex={setCurImageIndex}
                />
                {/* this is match animation */}
                {isAMatch &&
                    <motion.div className="absolute top-0 w-full h-full bg-black bg-opacity-30" initial={{opacity: 0}} animate={{opacity: 1}}>
                        <motion.div 
                            // initial={{opacity: 0, y: -100, scale: 1, rotate: 0}} 
                            animate={{opacity:1, y: 0, x:25, scale: 1.3, rotate: -25}} 
                            transition={{ from: 90, duration: .5 }}
                            className="absolute top-24 left-10 py-2 px-3 border-2 border-green-400 rounded-sm text-green-400 tracking-widest uppercase font-sans font-bold">
                                It's A Match
                        </motion.div>
                    </motion.div>
                }
                {/* this is animation for rating match*/}
                {!isAMatch && reviewed && likedMatch &&
                    <motion.div className="absolute top-0 w-full h-full bg-black bg-opacity-30" initial={{opacity: 0}} animate={{opacity: 1}}>
                        <motion.div 
                            // initial={{opacity: 0, y: -100, scale: 1, rotate: 0}} 
                            animate={{opacity:1, y: 0, x:25, scale: 1.3, rotate: -25}} 
                            transition={{ from: 90, duration: .5 }}
                            className="absolute top-24 left-10 py-2 px-3 border-2 border-blue-400 rounded-sm text-blue-400 tracking-widest uppercase font-sans font-bold">
                                Liked Match
                        </motion.div>
                    </motion.div>
                }
                {!isAMatch && reviewed && !likedMatch &&
                    <motion.div className="absolute top-0 w-full h-full bg-black bg-opacity-30" initial={{opacity: 0}} animate={{opacity: 1}}>
                        <motion.div 
                            // initial={{opacity: 0, y: -100, scale: 1, rotate: 0}} 
                            animate={{opacity:1, y: 0, x:25, scale: 1.3, rotate: -25}} 
                            transition={{ from: 90, duration: .5 }}
                            className="absolute top-24 left-10 py-2 px-3 border-2 border-red-400 text-red-400 rounded-sm tracking-widest uppercase font-sans font-bold">
                                Not A Fit
                        </motion.div>
                    </motion.div>
                }

                <div className="p-4 absolute top-3/4 h-1/4 w-full bg-gray-700 bg-opacity-30 flex">
                    <div className="w-full flex text-white items-center justify-between px-4">
                        <button className="transform hover:scale-125 transition duration-500" onClick={(e) => {
                            e.stopPropagation();
                            setModal(true);
                            setHeader('Are you sure you want to decline this match?');
                            setBody('If you choose to decline this match, the match will no longer be available on your MoDater dashboard. We hope the next match works out.');
                            setHandler(() => () => rejectMatch(id));
                        }}><ThumbDownOffAltOutlinedIcon fontSize="large" className="text-red-400"/></button>
                        <div className="text-sm tracking-wider text-center uppercase w-36">Want to go on a date?</div>
                        <button className="transform hover:scale-125 transition duration-500" onClick={(e) => {
                            console.log('set Modal');
                            e.stopPropagation();
                            setModal(true);
                            setHeader('Are you sure you want to accept this match?');
                            setBody("If you choose to accept this match your potential match will NOT be notified unless they have already accepted the match. If the match simply disappears, it means that your potential match has not yet reviewed the match. If your potential match accepts the match after you have already accepted the match you will be notified and phone numbers will be exchanged. If both sides agree to the match your account will become INACTIVE and you will not receive any new matches unless you close out the match.");
                            setHandler(() => () => likeMatch(id));
                        }}><ThumbUpOffAltOutlinedIcon fontSize="large" className="text-green-400"/></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RateCard
