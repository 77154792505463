import React, { useContext, useEffect, useMemo, useState, useRef } from 'react'
import '../Components/nav.css';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { getUnverifiedModaters, getModatersUnreviewedMatches, getMyModaters, getRefreshTokenBeforeApiCall, sendVerificationRequestOfConnector, getMyConnectorVerificationStatus } from '../Components/utilities/EndPoints';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import HelpIcon from '@mui/icons-material/Help';
import { ConnectorDashboardContext } from '../Components/utilities/connectorDashboardContext';
import ToolTip from '../Components/ToolTip';
import InfoIcon from '@mui/icons-material/Info';

function ConnectorDashboard() {

    const { token, setToken, setLoggedIn, navigate, modaters, setModaters, user, setUser, setSuccess, setError } = useContext(TokenContext);
    const [unreviewedMatches, setUnreviewedMatches] = useState([]);
    const [unverifiedModaters, setUnverifiedModaters] = useState([]);
    const [isVerifiedConnector, setIsVerifiedConnector] = useState(false);
    const [_, setLoading] = useState(false);
    const _isMounted = useRef(true);
    const acceptedModaters = modaters ? modaters.filter(modater => modater.extraInfo.reviewed) : [];

    const providerValue = useMemo(
        () => { 
            return {
                unreviewedMatches, 
                setUnreviewedMatches,
                unverifiedModaters,
                setUnreviewedMatches,
                isVerifiedConnector,
                setIsVerifiedConnector
            }
        }, 

        [unreviewedMatches, setUnreviewedMatches, unverifiedModaters, setUnverifiedModaters, isVerifiedConnector, setIsVerifiedConnector]
    )

    // console.log(modaters);
    // console.log(matches)
    // console.log(unverifiedModaters)
    // console.log(acceptedModaters);
    // console.log(unreviewedMatches)

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getMyModaters, [setModaters, _isMounted, setLoading]);
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getModatersUnreviewedMatches, [setUnreviewedMatches, _isMounted]);
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getUnverifiedModaters, [setUnverifiedModaters, _isMounted]);
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getMyConnectorVerificationStatus, [setIsVerifiedConnector, _isMounted]);
    }, [token])

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    const handleVerificationRequestClick = () => {
        getRefreshTokenBeforeApiCall(
            token,
            setToken,
            setLoggedIn,
            navigate,
            sendVerificationRequestOfConnector,
            [user.connectorProfileId, setUser, setSuccess, setError]
        )
    }

    return (
        <ConnectorDashboardContext.Provider value={providerValue}>
            { (user && user.connectorProfileId) ?
                window.location.pathname === '/dashboard/connectorDashboard' ?
                    <motion.div initial={{opacity: 0, x: 100}} animate={{opacity: 1, x: 0}}>
                        <div className="h-full w-full flex flex-col space-y-10 text-gray-200 p-12">
                            <div className="w-full flex flex-col items-center justify-center space-y-10 md:space-y-0 md:space-x-12 md:flex-row">
                                <div className="w-80 lg:w-96 h-60 nav-bg rounded-md border border-gray-800 p-2 shadow-xl shadow-black/60">
                                    { modaters && 
                                        <div className="h-full w-full flex flex-col space-y-2 items-center mt-3">
                                            <div className="flex flex-col items-center space-y-2 w-full px-3 text-center">
                                                <div className="text-2xl special text-gray-200 text-center">MoDater Reviews Needed</div>
                                                <div className="text-xs text-gray-400 leading-5 md:leading-6">Please answer a few personality questions about your MoDater. Once you review your MoDater, he or she will be able to begin receiving matches.</div>
                                            </div>
                                            <div className={`text-2xl font-light ${unverifiedModaters.length === 0 ? 'text-green-400' : 'text-red-400'}`}>{ unverifiedModaters.length }</div>
                                            { <Link to="/dashboard/connectorDashboard/reviewModaters" className="py-1 px-4 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400">Review MoDaters</Link> }
                                        </div>
                                    }
                                </div>

                                <div className="lg:w-96 w-80 h-60 nav-bg rounded-md border border-gray-800 p-2 shadow-xl shadow-black/60">
                                    { modaters &&
                                        <div className="h-full w-full flex flex-col space-y-4 items-center mt-3">
                                            <div className="flex flex-col items-center space-y-2 w-full h-48 px-3">
                                                <div className="text-2xl special text-gray-200">Verified MoDaters</div>
                                                <div className="flex flex-col overflow-hidden h-full w-full overflow-y-scroll px-3 py-1 rounded-sm border border-gray-800">
                                                    {acceptedModaters.length > 0 ? acceptedModaters.map(modater => {
                                                        return (
                                                            <Link to={`/dashboard/connectorDashboard/modaterProfile/${modater._id}`} className="hover:bg-gray-800" key={modater._id}>
                                                                <div className="flex justify-between items-center p-2">
                                                                    <div className="text-sm text-gray-300 tracking-wider font-light">{modater.firstName} {modater.lastName}</div>
                                                                    <div className={`${modater.activeStatus ? 'text-green-500' : 'text-red-500'} px-3 py-0.5 rounded-sm body-bg font-semibold text-xs`}>{modater.activeStatus ? 'ACTIVE' : 'INACTIVE'}</div>
                                                                </div>
                                                            </Link>
                                                        )
                                                    }) : 
                                                    <div className="flex flex-col justify-center items-center h-full tracking-wider space-y-3">
                                                        <div className="text-md text-center">You Don’t Have Any MoDaters Yet</div>
                                                        <div className="font-sm font-mono tracking-normal text-gray-400">…Start Connecting!</div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="w-full flex flex-col space-y-10 md:space-y-0 md:space-x-12 items-center justify-center md:flex-row">
                                <div className="lg:w-96 w-80 h-60 nav-bg rounded-md border border-gray-800 p-3 shadow-xl shadow-black/60">
                                    { modaters &&
                                        <div className="h-full w-full flex flex-col space-y-6 items-center mt-3">
                                            <div className="flex flex-col items-center space-y-3 w-full px-3">
                                                <div className="flex items-center">
                                                    <div className="text-2xl special text-gray-200 mr-3">New Matches</div>
                                                    <ToolTip title="You will only be able to rate matches once the MoDate algorithm provides potential matches for you to review (unless you are a Super Connector).">
                                                        <InfoIcon fontSize="small" className="cursor-pointer text-blue-300"/> 
                                                    </ToolTip>    
                                                </div>

                                                <div className="flex justify-center items-center space-y-4 h-20 flex-col">
                                                    <br/>
                                                    <div className="flex items-center space-x-2 tracking-wider font-light">
                                                        <span className="text-sm tracking-wider font-bold">{isVerifiedConnector ? "You are verified!" : "You're not verified yet –"}
                                                            {!isVerifiedConnector && <button onClick={handleVerificationRequestClick} className="py-1 px-1 rounded-sm text-blue-300 text-sm ">
                                                                Get verified!
                                                            </button>}
                                                        </span>

                                                        {!isVerifiedConnector &&                                                         
                                                            <ToolTip title='Verified connectors can see pictures of their MoDaters potential matches. Clicking "Get Verified!" will send a verification request to the MoDate team'>
                                                                <HelpIcon fontSize="small" className="cursor-pointer text-blue-300"/> 
                                                            </ToolTip>      
                                                        }                                                                                             
                                                    </div>
                                                    <div className="flex items-center space-x-2 tracking-wider font-light">
                                                        <span className="text-sm tracking-wider font-bold">{modaters?.length >= 3 ? "You're a Super Connector" : "Become a Super Connector"}</span>
                                                        <ToolTip title="'Super Connector' is a new feature in which connectors with 3 or more MoDaters signed up through them can get match suggestions and create matches that go straight to their MoDaters">
                                                            <HelpIcon fontSize="small" className="cursor-pointer text-blue-300"/> 
                                                        </ToolTip>                                                         
                                                    </div>
                                                    <div className="flex space-x-2 items-center">
                                                        <div className={`text-2xl font-light ${unreviewedMatches.length === 0 ? 'text-green-400' : 'text-red-400'}`}>{unreviewedMatches.length}</div>
                                                        <span className="text-xs lg:text-sm tracking-wide font-light">Of Your MoDaters Have New Matches</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <Link to="/dashboard/connectorDashboard/matches" className="py-1 px-4 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400">Start Matching</Link>
                                        </div>
                                    }
                                </div>

                                <div className="lg:w-96 w-80 h-60 nav-bg rounded-md border border-gray-800 p-3 shadow-xl shadow-black/60">
                                    { modaters &&
                                        <div className="h-full w-full flex flex-col space-y-4 items-center mt-3">
                                            <div className="flex flex-col items-center space-y-2 w-full px-3">
                                                <div className="text-xl lg:text-2xl text-center special text-gray-200">Status Of Matches</div>
                                                <div className="text-xs text-gray-400 sm:leading-5">Here you can check if the corresponding Connectors have reviewed matches. You can also cancel matches if corresponding Connectors have been unresponsive for at least 5 days. We recommend doing so if corresponding Connectors have been unresponsive.</div>
                                            </div>
                                            <Link to="/dashboard/connectorDashboard/matchesStatus" className="py-1 px-4 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400">Matches Status</Link>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </motion.div> :
                    <Outlet /> 
                :
                <div className="w-full h-full flex justify-center items-center text-gray-300 text-3xl font-light tracking-wider">You must be a connector to access this route</div>
            }
        </ConnectorDashboardContext.Provider>
    )
}

export default ConnectorDashboard

