import React, { useState, useEffect, useContext, useRef } from 'react'
import Modal from '../Modal';
import ModalChild from './ModalChild';
import { getRefreshTokenBeforeApiCall, getMyModaters } from '../../utilities/EndPoints';
import { TokenContext } from '../../utilities/accessTokenContext';

function MatchModal({ setModal }) {

    const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);
    const [selected, setSelected] = useState();
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [step, setStep] = useState(0);
    const [loadingModaters, setLoadingModaters] = useState(false);
    const [suggestions, setSuggestions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cancelMatch, setCancelMatch] = useState(null);
    const [modaters, setModaters] = useState(null);
    const childRef = useRef();
    const _isMounted = useRef(true);

    const onCancelClickHandler = (e) => {
        if (step === 0) {
            e.stopPropagation();
            setModal(prev => !prev);
            document.body.style.overflow = "auto"
        }
        else {
            e.stopPropagation();
            setStep(prev => prev - 1);
        }
    }

    const onCloseHandler = (e) => {
        e.stopPropagation();
        setModal(prev => !prev);
        document.body.style.overflow = "auto"
    }
    

    // const modalSubmit = (selected) => {
    //     // const data = {modaterIds: [selected[0]?.value, selected[1]?.value]}
    //     // getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, createMatchBetweenModaters, [data, setError, setSuccess, setModal, setLoading, setToken])
    // }

    const getSubmitButtonText = () => {
        switch (step) {
            case 0:
                return "Continue";
            case 1:
                return cancelMatch ? "Discard" : "Next";
            default:
                return "Create Match";
        }
    }

    const getCancelButtonText = () => {
        switch (step) {
            case 0:
                return "Cancel"
            default:
                return "Prev"
        }
    }

    const onSubmitHandler = () => {
        childRef.current.onSubmitHandler()
    }

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getMyModaters, [setModaters, _isMounted, setLoadingModaters]);
    }, [])

    useEffect(() => {
        setSelectedMatch(null);
        setCancelMatch(null);
    }, [selected])

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])
    
    return (
        <Modal 
            title="Create match between Modaters" 
            onPrevHandler={onCancelClickHandler}
            onSubmitHandler={onSubmitHandler}
            submitButtonText={getSubmitButtonText()}
            cancelButtonText={getCancelButtonText()}
            onCloseHandler={onCloseHandler}
        >
            <ModalChild 
                step={step}
                setStep={setStep}
                modaters={modaters}
                selected={selected}
                setSelected={setSelected}
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                selectedMatch={selectedMatch}
                setSelectedMatch={setSelectedMatch}
                loading={loading}
                setLoading={setLoading}
                cancelMatch={cancelMatch}
                setCancelMatch={setCancelMatch}
                loadingModaters={loadingModaters}
                ref={childRef}
            />
        </Modal>
    )
}

export default MatchModal;
