import React, { useContext, useEffect } from 'react';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { Outlet } from 'react-router-dom';
import { getRefreshToken } from '../Components/utilities/EndPoints';

function PersistLogin() {

    const { token, setLoggedIn, setToken, navigate } = useContext(TokenContext);
    // console.log(loggedIn)

    useEffect(() => {
        try {
            console.log('CHECKING IF LOGGED IN');
            getRefreshToken(token, setLoggedIn, setToken, navigate);
        } catch (error) {
            console.log(error)
        } 
    }, [])

    return <Outlet />
}

export default PersistLogin