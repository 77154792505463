import React, { useContext, useEffect, useRef } from 'react';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { getModaterStageMatches, getRefreshTokenBeforeApiCall, getUser } from '../Components/utilities/EndPoints';
import Sidebar from '../Components/Sidebar';
import { Outlet } from 'react-router';
import '../Components/nav.css';
import Banner from '../Components/utilities/Banner';
import SuccessBanner from '../Components/SuccessBanner';


const Dashboard = () => {

    const {token, setToken, user, setLoggedIn, setUser, navigate, error, setError, success, setSuccess, setMatches, setMatchesLoading } = useContext(TokenContext)
    const _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            console.log("ModaterDashboard Component Unmounting")
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getUser, [setUser]) 
    }, [token])

    useEffect(() => {
        if (user && user?.modaterProfileId && !user?.modaterProfileId?.isDating?.status){
            getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getModaterStageMatches, [setMatches, setMatchesLoading, _isMounted]);
        }
    }, [token, user])

    // console.log(token)
    // console.log(error)

    return (
        <div className="h-full w-full flex montserrat">
            <Sidebar />
            <div className="h-full w-full lg:ml-72 flex flex-col relative">
                { success && <SuccessBanner message={success} setSuccess={setSuccess}/> }
                {error.length > 0 && 
                    <div className="w-full z-20 fixed top-[5rem]">
                        { window.location.pathname !== '/dashboard/profile/modaterEdit' && error.map(error => {
                            return <Banner color="bg-red-400" error={error} disappearing setError={setError} key={error.id}/>
                        })}
                    </div>
                }
                {window.location.pathname === '/dashboard' ||  window.location.pathname === '/' &&
                    <div className="w-full h-full flex justify-center items-center">
                        <div className="text-gray-200 text-4xl tracking-wider">Welcome to MoDate</div>
                    </div>
                }
                <Outlet />
            </div>
        </div>
    )
}

export default Dashboard; 