import './Components/nav.css'
import { Outlet } from "react-router-dom";
import NavBar from './Components/NavBar';


function App() {
  return (
    <div className="h-screen w-screen body-bg pt-20">
      <NavBar />
      <Outlet />
    </div>
  );
}

export default App;


