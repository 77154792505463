import React, { useState, useMemo, useEffect } from 'react';
import { Routes, Route, useNavigate} from "react-router-dom";
import App from '../App.js';
import ConnectorSignUp from './ConnectorSignUp';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
import { TokenContext } from '../Components/utilities/accessTokenContext.js';
import ForgotPassword from './ForgotPassword.jsx';
import ResetPassword from './ResetPassword.jsx';
import TotalSignUp from './TotalSignUp.jsx';
import Profile from './Profile.jsx';
import { editModater, registerModater } from '../Components/utilities/EndPoints.js';
import EditModaterProfile from './EditModaterProfile.jsx';
import ConnectorDashboard from './ConnectorDashboard.jsx';
import ReviewModaters from './ReviewModaters.jsx';
import Matches from './Matches.jsx';
import ModaterDashboard from './modaterDash/ModaterDashboard.jsx';
import MatchesStatus from './MatchesStatus.jsx';
import ReviewMatch from './ReviewMatch.jsx';
import ReviewDate from './ReviewDate.jsx';
import NotFound from './NotFound.jsx';
import Share from '../Components/Share.jsx';
import TermsAndCond from '../Components/utilities/TermsAndCond.jsx';
import PrivacyPolicy from '../Components/utilities/PrivacyPolicy.jsx';
import ModaterProfile from './ModaterProfile.jsx';
import AdminDashboard from './adminDash/AdminDashboard.jsx';
import PersistLogin from './PersistLogin.jsx';
import RolesAuth from './RolesAuth.jsx';
import Podcast from './Podcast.jsx';
import HowItWorks from './HowItWorks.jsx';
import ConnectorInfo from './adminDash/ConnectorInfo.jsx';
import VerifyConnectorInfo from './adminDash/VerifyConnectorInfo.jsx';
import UserActions from './adminDash/UserActions.jsx';
import ModatersInfo from './adminDash/ModatersInfo.jsx';
import MatchInfo from './adminDash/MatchInfo.jsx';
import ModaterMatches from './modaterDash/ModaterMatches.jsx';
import ModaterMatchesStatus from './modaterDash/ModaterMatchesStatus.jsx';

const AllRoutes = () => {
    const [token, setToken] = useState('');
    const [user, setUser] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [modaters, setModaters] = useState();
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState();
    const [matches, setMatches] = useState([]);
    const [matchesLoading, setMatchesLoading] = useState(false);
    const navigate = useNavigate();

    // console.log(loggedIn)
    // console.log(user)
    // console.log(window.location.pathname)

    const providerValue = useMemo(
        () => ({ 
                token, 
                setToken, 
                user, 
                setUser, 
                loggedIn, 
                setLoggedIn, 
                navigate, 
                modaters, 
                setModaters, 
                error,
                setError,
                success, 
                setSuccess,
                matches,
                setMatches,
                matchesLoading, 
                setMatchesLoading

            }), 

        [ token, setToken, user, setUser, loggedIn, setLoggedIn, navigate, modaters, setModaters, error, setError, success, setSuccess, matches, setMatches, matchesLoading, setMatchesLoading ]
    )

    return (
        <TokenContext.Provider value={providerValue}>
            <Routes>
                <Route path="/" element={<App />} >
                    <Route path='*' element={<NotFound />} />
                    <Route path="signIn" element={<SignIn />}/>
                    <Route path="signUp" element={<SignUp />}/>
                    <Route path="listen" element={<Podcast />}/>
                    <Route path="howItWorks" element={<HowItWorks />}/> 
                    <Route path="TermsAndCond" element={<TermsAndCond />} /> 
                    <Route path="PrivacyPolicy" element={<PrivacyPolicy />} /> 
                    
                    <Route element={<PersistLogin />} >
                        <Route index element={loggedIn ? <Dashboard /> : <HowItWorks />} />
                        <Route path="connectorSignUp" element={<ConnectorSignUp />} />
                        <Route path="modaterSignUp" element={<TotalSignUp header="MoDater Sign Up" submitButtonText="Create Account" submitHandler={registerModater} />}/>
                        <Route path="Share" element={<Share />} />

                        <Route path="dashboard" element={<Dashboard />}>
                            <Route path="profile" element={<Profile />} />
                            <Route path="profile/modaterEdit" element={<EditModaterProfile editModater={editModater} />}/>
                            <Route path="connectorDashboard" element={<ConnectorDashboard />}>
                                <Route path="reviewModaters" element={<ReviewModaters />}/>
                                <Route path="matches" element={<Matches/>}/>
                                <Route path="matchesStatus" element={<MatchesStatus/>}/>
                                <Route path="modaterProfile/:id" element={<ModaterProfile />} />
                                
                            </Route>
                            <Route path="modaterDashboard" element={<ModaterDashboard />}>
                                <Route path="matches" element={<ModaterMatches />} />
                                <Route path="matches/status" element={<ModaterMatchesStatus />} />
                                <Route path="reviewMatch/:id" element={<ReviewMatch />} />
                                <Route path="reviewDate/:id" element={<ReviewDate />} />
                            </Route>
                            <Route element={<RolesAuth allowedRoles={[5718]}/>}>
                                <Route path="adminDashBoard" element={<AdminDashboard/>}>
                                    <Route path="activity" element={<UserActions />} />
                                    <Route path="connectors" element={<ConnectorInfo />} />
                                    <Route path="modaterProfile/:id" element={<ModaterProfile />} />
                                    <Route path="modaters" element={<ModatersInfo />} />
                                    <Route path="matches" element={<MatchInfo />} />
                                    <Route path="verifyConnectors" element={<VerifyConnectorInfo />} />
                                    
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="forgotPassword" element={<ForgotPassword />}/>
                    <Route path="resetPassword/:token" element={<ResetPassword />}/>
                </ Route>
            </Routes>
        </TokenContext.Provider>
    )
}

export default AllRoutes;