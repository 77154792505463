import React, { useContext, useState, useEffect } from 'react';
import Banner from '../Components/utilities/Banner';
import Modal from '../Components/Modal';
import '../Components/nav.css';
import CloseIcon from '@mui/icons-material/Close';
import { deletePhoto, getRefreshTokenBeforeApiCall } from '../Components/utilities/EndPoints';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { motion } from 'framer-motion';


const Photo = ({ files, setFiles, disabled, imagePreview, setImagePreview, urls }) => {

    const { token, setToken, setLoggedIn, navigate, setUser, error, setError, setSuccess } = useContext(TokenContext);
    const [modal, setModal] = useState(false);
    const [photoIssueModal, setPhotoIssueModal] = useState(false)
    const [key, setKey] = useState();
    const [modalDisabled, setModalDisabled] = useState();
    const MAX_FILE_SIZE = 5000000;

    const clickDeletePhoto = (key) => {
        setKey(key)
        setModal(prev => !prev)
    }

    const onFileChange = e => {
        let newFiles = files.map(file => file);
        for (let i = 0; i < e.target.files.length; i++) {
            if (!newFiles.some(f => f.name === e.target.files[i].name)) {
                newFiles.push(e.target.files[i]);
            }
        }
        setImagePreview([]);
        setFiles(newFiles);
    }

    const handleClick = chosen => {
        const results = Array.from(files).filter(file => file.name !== chosen.name)
        setFiles(results)
    }

    const readFile = file => {
        return new Promise(function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = e => {
                resolve(e.target.result);
            };
            reader.onerror = reader.onabort = reject;
            reader.readAsDataURL(file);
        });
    }

    const readmultifiles = files => {
        const fileArray = []
        for (let i = 0; i < files.length; i++) {
            fileArray.push(files[i]);
        }
        return Promise.all(fileArray.map(readFile));
    }

    useEffect(() => {
        if (files) {
            readmultifiles(files).then(results => {
                setImagePreview(results)
            })
        }

    }, [files])
    
    return (
        <>
            { modal && 
                    <Modal 
                        disabled={modalDisabled}
                        rejectHandler={() => {getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, deletePhoto, [key, setModalDisabled, setModal, setUser, setSuccess])}}
                        setModal={setModal}
                        header={`Are you sure that you want to delete this Photo`}
                        body={`By clicking Confirm this photo will be deleted from your profile`}
                    />
            }
            <div className="body-bg w-full lg:w-4/5 xl:w-3/5 px-10">
                <form className="text-gray-400">
                    <div className="flex flex-col w-full items-center">
                        <div className="mb-2 w-full">
                            { disabled ? <Banner color={'bg-yellow-500'} message="Submitting Data. This may take a moment" disappearing={false}/> : null}
                            { error.map(error => {
                                return <Banner color="bg-red-400" error={error} disappearing setError={setError} key={error.id}/>
                            })}
                        </div>
                        <div>Please select between 2 and 5 images</div>
                        <div className="flex w-full justify-center space-x-4">
                            <div className="flex items-center p-2 w-full rounded-sm nav-bg overflow-x-scroll space-x-4 max-w-xl">
                                {files?.length > 0 ? Array.from(files).map((file, index) => {
                                    return <div className="py-1 px-3 bg-gray-800 rounded-sm flex items-center space-x-2 whitespace-nowrap" key={index}>
                                            <div className="text-xs">{file.name}</div>
                                            <div className="text-xs">Size: <span className={`${file.size/1000000 > 5 ? 'text-red-300' : 'text-green-300'}`}>{(file.size/1000000).toFixed(2)} MB</span></div>
                                            <button type="button" className="px-0.5 flex justify-center hover:text-red-400" onClick={() => handleClick(file)}><CloseIcon fontSize="small"/></button>
                                        </div>
                                }) : 'No Files Chosen'}
                            </div>
                            <label htmlFor="file-upload" className="bg-gray-700 p-3 rounded-sm text-sm flex-none hover:bg-gray-800 cursor-pointer">
                                Choose Images
                            </label>
                        </div>
                        <input type="file" accept='image/*' className="hidden" id="file-upload" multiple onChange={onFileChange}/>
                    </div>
                </form>

                {((!urls && imagePreview.length === 0) || (urls?.length === 0 && imagePreview?.length === 0)) && <div className="text-center text-gray-200 text-lg leading-9 special mt-12">Note: Other Connectors Will Not Be Able to See Your Name or Photos. The Only People That Will Be Able to See Your Name and Photo(s) Are The MoDaters You Have Matched With.</div>}

                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-3 gap-y-6 mt-12 w-full px-5 md:px-10">
                    { (urls && !imagePreview.length > 0) && urls.map((img, index) => {
                        return (
                            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} transition={{ delay: index*.1 }} className="relative w-28 h-36" key={index}>
                                <button type="button" className="hover:text-red-300 nav-bg shadow-xl shadow-black rounded-md absolute -top-2.5 -right-2.5 h-5 w-5 flex justify-center items-center text-red-100 text-sm" onClick={() => clickDeletePhoto(img.key)}><CloseIcon fontSize="small"/></button>
                                <img src={img.url} alt="" className="h-full w-full object-cover rounded-md"/>
                            </motion.div>
                        )
                    })}
                    { imagePreview.map((img, index) => {
                        return (
                            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} transition={{ delay: index*.1 }} className="relative w-28 h-36" key={index}>
                                <img src={img} alt="" className="h-full w-full object-cover rounded-md"/>
                            </motion.div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Photo
