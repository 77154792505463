import React, { useContext, useState, useEffect, useRef } from "react";
import Photo from "./Photo.jsx";
import ModaterSignUp from "./ModaterSignUp.jsx";
import "../Components/nav.css";
import { TokenContext } from "../Components/utilities/accessTokenContext.js";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getRefreshTokenBeforeApiCall } from "../Components/utilities/EndPoints.js";
import { HEIGHTS } from "../constants.js";

function TotalSignUp({ header, submitButtonText, submitHandler }) {
  const {
    token,
    setToken,
    setLoggedIn,
    navigate,
    setUser,
    user,
    setError,
    setSuccess,
  } = useContext(TokenContext);

  const [data, setData] = useState();
  const componentIsMounted = useRef(true);

  // data for Photo.jsx
  const [step, setStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);

  // data for ModaterSignUp.jsx
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .test("alphabets", "No numbers or spaces in Names Allowed", (value) => {
        return /^[A-Za-z]*$/.test(value);
      })
      .required("Please enter a First Name"),
    lastName: yup
      .string()
      .test("alphabets", "No numbers in Names Allowed", (value) => {
        return /^[A-Za-z]*$/.test(value);
      })
      .required("Please enter a Last Name"),
    phoneNumber: yup
      .string()
      .test(
        "numbers",
        "please Enter Phone Number in the format xxx-xxx-xxxx",
        (value) => {
          return /^(\+\d{1,2})?[-]*\d{3}[-]*\d{3}[-]*\d{4}$/.test(value);
        }
      ),
    sex: yup.string().required("You need to choose an option").nullable(),
    dateOfBirth: yup
      .date()
      .min(new Date("01/01/1900"), "Date must be later than 1900")
      .typeError("Please enter a valid date in the format mm/dd/yyyy")
      .required(),
    connectorId: yup.object().required("please choose a Connector").nullable(),
    state: yup.object().required("please choose a Location").nullable(),
    // community: yup.object().required('please choose one of the options').nullable(),
    city: yup.string().required("please enter a city").nullable(),
    // willingToDate: yup.array().min(1, 'please select at least One Location').required('please select at least One Location').nullable(),
    height: yup.object().required("please choose a Height").nullable(),
    // minHeight: yup.object().required('please choose a Height').nullable(),
    IE: yup.string().required("You need to choose an option").nullable(),
    SN: yup.string().required("You need to choose an option").nullable(),
    TF: yup.string().required("You need to choose an option").nullable(),
    JP: yup.string().required("You need to choose an option").nullable(),
    characteristics: yup
      .array()
      .test(
        "len",
        "Must be exactly 3 characteristics",
        (val) => val && val.length === 3
      )
      .nullable(),
    characteristicsInMatch: yup
      .array()
      .test(
        "len",
        "Must be exactly 3 characteristics",
        (val) => val && val.length === 3
      )
      .nullable(),
    kosher: yup.string().required("You need to choose an option").nullable(),
    shabbos: yup.string().required("You need to choose an option").nullable(),
    shomerNegiah: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    learns: yup.string().required("You need to choose an option").nullable(),
    aliyah: yup.string().required("You need to choose an option").nullable(),
    kosherMatch: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    shabbosMatch: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    shomerNegiahMatch: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    learnsMatch: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    aliyahMatch: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    oldestAge: yup.object().required("please choose an age").nullable(),
    youngestAge: yup.object().required("please choose an age").nullable(),
    divorcedStatus: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    childrenStatus: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    vocation: yup
      .string()
      .required("Please enter what you do or are pursuing")
      .nullable(),
    college: yup
      .string()
      .required("Please enter where you went for College")
      .nullable(),
    highSchool: yup
      .string()
      .required("Please enter where you went for High School")
      .nullable(),
    israelSchool: yup
      .string()
      .required(
        "Please enter where you went to yeshiva or Seminary in Israel or just NA if you didn't"
      )
      .nullable(),
    relationToJudaism: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    activities: yup
      .array()
      .test(
        "len",
        "Must be exactly 3 activities",
        (val) => val && val.length === 3
      )
      .nullable(),
    vacationPreference: yup
      .string()
      .required("You need to choose an option")
      .nullable(),
    politics: yup.string().required("You need to choose an option").nullable(),
    bio: yup.string().min(50),
  });

  const {
    register,
    handleSubmit,
    control,
    trigger,
    getValues,
    reset,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const nextStep = () => {
    setStep((step) => step + 1);
  };

  const prevStep = () => {
    setStep((step) => step - 1);
  };

  // console.log(token)
  // console.log(user)
  // console.log(step)
  // console.log(componentIsMounted.current)

  const submitForm = async (data) => {
    getRefreshTokenBeforeApiCall(
      token,
      setToken,
      setLoggedIn,
      navigate,
      submitHandler,
      [data, files, setDisabled, navigate, setUser, setError, setSuccess]
    );
  };

  useEffect(() => {
    return () => {
      // console.log('total sign up unmounting')
      componentIsMounted.current = false;
    };
  }, []);

  // useEffect(() => {
  //     // console.log('modatersignup useEffect getting new refresh token')
  //     getRefreshToken(token, setLoggedIn, setToken, navigate)
  // }, [])

  useEffect(() => {
    if (user?.modaterProfileId) {
      const name = data
        ? data.filter(
            (tConnector) => tConnector._id === user.modaterProfileId.connectorId
          )[0]
        : null;
      const totalName = name
        ? `${name.firstName} ${name.lastName}`
        : "Choose a connector";

      reset({
        ...user?.modaterProfileId,
        willingToDate: user?.modaterProfileId.willingToDate
          ? user.modaterProfileId.willingToDate.map((location) => {
              return { value: location, label: location };
            })
          : "",
        dateOfBirth: user?.modaterProfileId.dateOfBirth,
        height: user?.modaterProfileId.height
          ? {
              value: user.modaterProfileId.height,
              label: HEIGHTS[user.modaterProfileId.height],
            }
          : "",
        minHeight: user?.modaterProfileId.minHeight
          ? {
              value: user.modaterProfileId.minHeight,
              label: HEIGHTS[user.modaterProfileId.minHeight],
            }
          : "",
        youngestAge: user?.modaterProfileId.youngestAge
          ? {
              value: user.modaterProfileId.youngestAge,
              label: user.modaterProfileId.youngestAge,
            }
          : "",
        oldestAge: user?.modaterProfileId.oldestAge
          ? {
              value: user.modaterProfileId.oldestAge,
              label: user.modaterProfileId.oldestAge,
            }
          : "",
        state: user?.modaterProfileId.state
          ? {
              value: user.modaterProfileId.state,
              label: user.modaterProfileId.state,
            }
          : "",
        community: user?.modaterProfileId.community
          ? {
              value: user.modaterProfileId.community,
              label: user.modaterProfileId.community,
            }
          : "",
        connectorId: user?.modaterProfileId.connectorId
          ? { value: user.modaterProfileId.connectorId, label: totalName }
          : "",
      });
    }
  }, [data, user]);

  console.log(errors);

  return (
    <div className="w-full h-full flex justify-center relative">
      <div
        className={`w-full h-full ${
          user ? "" : "mt-12"
        } sm:w-4/5 md:w-4/5 lg:w-full flex flex-col items-center justify-between`}
      >
        {step === 0 && (
          <>
            <div className="mt-20 flex items-center justify-between w-full lg:w-1/2 xl:w-2/5 px-12 mb-12 text-gray-200">
              <div className="flex text-2xl font-bold">{header}</div>
              <div className="flex space-x-2 items-center">
                <div
                  className={`bg-gray-200 w-10 h-10 rounded-full flex justify-center items-center text-black text-xl border border-gray-700`}
                >
                  1
                </div>
                <div className="border-b w-8"></div>
                <div
                  className={`nav-bg w-10 h-10 rounded-full flex justify-center items-center text-white text-xl border border-gray-700`}
                >
                  2
                </div>
              </div>
            </div>
            <ModaterSignUp
              register={register}
              control={control}
              errors={errors}
              trigger={trigger}
              nextStep={nextStep}
              data={data}
              setFocus={setFocus}
              reset={reset}
              getValues={getValues}
            />
          </>
        )}
        {step === 1 && (
          <>
            <div className="w-full flex flex-col items-center justify-center">
              <div className="mt-20 flex items-center justify-between w-full lg:w-1/2 xl:w-2/5 px-12 mb-12 text-gray-200">
                <div className="flex text-2xl font-bold">{header}</div>
                <div className="flex space-x-2 items-center">
                  <div
                    className={`nav-bg w-10 h-10 rounded-full flex justify-center items-center text-white text-xl border border-gray-700`}
                  >
                    1
                  </div>
                  <div className="border-b w-8"></div>
                  <div
                    className={`bg-gray-200 w-10 h-10 rounded-full flex justify-center items-center text-black text-xl border border-gray-700`}
                  >
                    2
                  </div>
                </div>
              </div>
              <Photo
                files={files}
                setFiles={setFiles}
                disabled={disabled}
                imagePreview={imagePreview}
                setImagePreview={setImagePreview}
                urls={
                  user &&
                  user.modaterProfileId &&
                  user.modaterProfileId.photoUrls
                }
              />
            </div>
            <div className="flex w-full justify-center space-x-8">
              <button
                className="px-3 py-0.5 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400 mb-12 mt-12"
                onClick={prevStep}
              >
                Previous
              </button>
              <button
                className="px-3 py-0.5 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400 mb-12 mt-12"
                disabled={disabled}
                onClick={handleSubmit(submitForm)}
              >
                {submitButtonText}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TotalSignUp;
