import React, { useContext, useEffect, useState } from 'react'
import './nav.css'
import { TokenContext } from './utilities/accessTokenContext';
import BreakUpForm from './BreakUpForm';
import { getCurrentDate, getRefreshTokenBeforeApiCall } from './utilities/EndPoints';
import CardProfile from './CardProfile';
import { Link } from 'react-router-dom';

function Dating({ setModal, setHeader }) {

    const { token, setToken, setLoggedIn, navigate, setError } = useContext(TokenContext);
    const [date, setDate] = useState();
    const [showForm, setShowForm] = useState(false);

    console.log(date)

    const handleClick = () => {
        setShowForm(prev => !prev);
    }

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getCurrentDate, [setDate, setError]);
    }, [token])

    return (
        <div className="w-full h-full">
            { showForm && 
                <BreakUpForm 
                    setShowForm={setShowForm}
                />
            }
            <div className="w-full h-full flex flex-col justify-center items-center text-white space-y-6 special relative">
                <div className="space-x-4 tracking-wider">
                    <span className="text-lg tracking-widest text-blue-200">Phone Number</span> 
                    {/* <span className="text-xl tracking-widest text-blue-200">|</span>  */}
                    <span className="text-lg text-blue-200 tracking-widest">{date?.phoneNumber}</span>
                </div>
                <div className="h-[28rem] w-80 rounded-lg bg-gray-200 special border border-gray-700 overflow-hidden relative">
                    { date && <CardProfile user={{modaterProfileId: date}} urls={date.photoUrls}/> }
                </div>
                <Link to={`/dashboard/modaterDashboard/reviewDate/${date}`} className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-200 px-2 py-0.5 bg-gray-700 bg-opacity-50 hover:bg-opacity-80 rounded-sm uppercase tracking-wider text-sm`} >See Details</Link>
                <button className="px-2 py-0.5 nav-bg border border-gray-700 text-red-300 rounded-sm tracking-wider text-xs uppercase" onClick={handleClick}>End Match</button>
            </div>
        </div>
    )
}

export default Dating
