import { yellow } from '@mui/material/colors';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CopyAllIcon from '@mui/icons-material/CopyAll';



const Share = () => {
const [showPopUp, setShowPopUp] = useState(false)
   
const CopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
        setShowPopUp(true);
      })
      .catch((error) => {
        console.error('Failed to copy text to clipboard:', error);
      });
      
    };

 return (
    <>
        <div className="h-full w-full flex flex-col space-y-2 items-center mt-3">
            <div className="flex flex-col items-center space-y-2 w-full px-3 text-center">
                <div style={{ display:'flex', color: '#FBCF0B'}} className="text-3xl special text-gray-200 text-center "><strong>Share With Future MoDaters and Connectors!</strong></div>
                    <div className="text-l text-gray-400 leading-5 md:leading-6">Copy link to clipboard to share with future MoDaters and Connectors.</div>
            </div>
                
            <div>    
                <button className="py-1 px-8 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400" onClick={() => CopyToClipboard('https://www.modate.org/signUp')}>{<CopyAllIcon/>}Sign Up</button>
            </div>

            {showPopUp && <div style={{ color: '#0093D1'}}>Copied To Clipboard!</div>}
        </div>

    </>
            
 );
}

export default Share;
