import React, { useContext, useState, useEffect } from 'react'
import { TokenContext } from '../../Components/utilities/accessTokenContext';
import { breakUpWithMatch, getRefreshTokenBeforeApiCall } from '../../Components/utilities/EndPoints';
import Dating from '../../Components/Dating.jsx';
import Modal from '../../Components/Modal.jsx';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';

function ModaterDashboard() {

    const { token, setToken, setLoggedIn, navigate, user, setUser, setError, matches } = useContext(TokenContext);
    const [modal, setModal] = useState(false);
    const [header, setHeader] = useState();
    const [disabled, setDisabled] = useState(false)

    // console.log(user)
    // console.log(matches)

    return (
        <div className="w-full h-full">
            {modal && 
                <Modal 
                    setModal={setModal}
                    disabled={disabled}
                    header={header}
                    body="This action is not reversable. When you click 'Confirm' your status will be set to active and you will be able to get/see any matches you may have"
                    rejectHandler={() => getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, breakUpWithMatch, [setUser, setDisabled, setModal, setError])}
                />
            }
            { window.location.pathname === '/dashboard/modaterDashboard' ? 
                (!user?.modaterProfileId?.isDating?.status ?
                    <div className="w-full h-full flex flex-col p-8 space-y-8 items-center">
                        <Link to="/dashboard/modaterDashboard/matches" className={`w-full md:w-1/2 h-28 bg-black rounded-md overflow-hidden flex border-r-4 ${matches.length === 0 ? 'border-green-500' : 'border-orange-500'}`}>
                            <img src="https://modate-photos.s3.amazonaws.com/illustration+5.svg" alt="" className="h-full w-24"/>
                            <div className="w-full h-full flex flex-col px-4 py-2">
                                <div className="text-white w-full text-center text-md special uppercase tracking-widest">View Matches</div>
                                <div className="text-gray-400 tracking-wider text-xs text-center">Matches are generated daily Sunday through Thursday. Review your matches here.</div>
                            </div>
                        </Link>

                        <Link to="/dashboard/modaterDashboard/matches/status" className="w-full md:w-1/2 h-28 bg-black rounded-md overflow-hidden flex">
                            {/* <img src="/matches.jpg" alt="" className="h-full w-24"/> */}
                            <div className="h-full w-[125px] flex justify-center items-center text-gray-300">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-checkbox" width="48" height="48" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <polyline points="9 11 12 14 20 6"></polyline>
                                    <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"></path>
                                </svg>
                            </div>
                            <div className="w-full h-full flex flex-col px-4 py-2">
                                <div className="text-white w-full text-center text-md special uppercase tracking-widest">Matches Status</div>
                                <div className="text-gray-400 tracking-wider text-xs text-center">See status of matches you've liked. Matches that haven't been reviewed for 30 days will expire.</div>
                            </div>
                        </Link>
                    </div> 
                    : <Dating 
                        setHeader={setHeader}
                        setModal={setModal}
                    />
                ) : <Outlet />
            }
        </div>
    )
}

export default ModaterDashboard
