import React from 'react'

const ConnectorStatusBar = ({ connectorTimeToRateMatch, overallConnectorTimeToRateMatch }) => {

    console.log(connectorTimeToRateMatch, overallConnectorTimeToRateMatch)
    return (
        <div className="flex space-x-1">
            <span className={`${connectorTimeToRateMatch <= 36 ? 'text-green-700' : connectorTimeToRateMatch > 36 && connectorTimeToRateMatch <= 72 ? 'text-green-300' : connectorTimeToRateMatch > 72 && connectorTimeToRateMatch <= 108 ? 'text-yellow-300' : connectorTimeToRateMatch > 108 && connectorTimeToRateMatch <= overallConnectorTimeToRateMatch ? 'text-orange-300' : connectorTimeToRateMatch > overallConnectorTimeToRateMatch ? 'text-red-400' : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-justified" width="24" height="24" viewBox="0 0 24 24" strokeWidth="6" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="4" y1="12" x2="20" y2="12"></line>
                </svg>
            </span>
            <span className={`${connectorTimeToRateMatch <= 36 ? 'text-green-700' : connectorTimeToRateMatch > 36 && connectorTimeToRateMatch <= 72 ? 'text-green-300' : connectorTimeToRateMatch > 72 && connectorTimeToRateMatch <= 108 ? 'text-yellow-300' : connectorTimeToRateMatch > 108 && connectorTimeToRateMatch <= overallConnectorTimeToRateMatch ? 'text-orange-300' : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-justified" width="24" height="24" viewBox="0 0 24 24" strokeWidth="6" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="4" y1="12" x2="20" y2="12"></line>
                </svg>
            </span>
            <span className={`${connectorTimeToRateMatch <= 36 ? 'text-green-700' : connectorTimeToRateMatch > 36 && connectorTimeToRateMatch <= 72 ? 'text-green-300' : connectorTimeToRateMatch > 72 && connectorTimeToRateMatch <= 108 ? 'text-yellow-300' : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-justified" width="24" height="24" viewBox="0 0 24 24" strokeWidth="6" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="4" y1="12" x2="20" y2="12"></line>
                </svg>
            </span>
            <span className={`${connectorTimeToRateMatch <= 36 ? 'text-green-700' : connectorTimeToRateMatch > 36 && connectorTimeToRateMatch <= 72 ? 'text-green-300' : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-justified" width="24" height="24" viewBox="0 0 24 24" strokeWidth="6" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="4" y1="12" x2="20" y2="12"></line>
                </svg>
            </span>
            <span className={`${connectorTimeToRateMatch <= 36 ? 'text-green-700' : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-justified" width="24" height="24" viewBox="0 0 24 24" strokeWidth="6" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="4" y1="12" x2="20" y2="12"></line>
                </svg>
            </span>
        </div>
    )
}

export default ConnectorStatusBar