import React from 'react';
import Error from './utilities/Error';
import './nav.css';

function ExtraInfoForm({ register, errors }) {
    
    return (
        <div className="md:overflow-y-scroll overflow-y-visible">
            <form  className="flex body-bg text-gray-200">
                <div className="flex flex-col w-full justify-center items-center space-y-8 tracking-wide special">

                    <div {...register('q1')} className="flex flex-col w-full space-y-2">
                        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">Which best describes your friend?</label>
                        <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-2">
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q1")} type="radio" value="An Amazing Leader" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">An Amazing Leader</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q1")} type="radio" value="Gets Along with Everyone" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Gets Along with Everyone</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q1")} type="radio" value="Gives the Best Advice" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Gives the Best Advice</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q1")} type="radio" value="Life of the Party" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Life of the Party</label>
                            </div>
                        </div>
                        {errors.q1 && <Error errorMessage={ errors.q1.message } /> }
                    </div>

                    <div {...register('q2')} className="flex flex-col w-full space-y-2">
                        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">You are on a trip with your MoDater: what role do they fill?</label>
                        <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-2">
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q2")} type="radio" value="The Chef" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Chef</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q2")} type="radio" value="The Planner" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Planner</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q2")} type="radio" value="The Driver" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Driver</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q2")} type="radio" value="The Entertainment" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Entertainment</label>
                            </div>
                        </div>
                        {errors.q2 && <Error errorMessage={ errors.q2.message } /> }
                    </div>

                    <div {...register('q3')} className="flex flex-col w-full space-y-2">
                        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">You are at a Shabbat meal with your MoDater: what role do they fill?</label>
                        <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-2">
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q3")} type="radio" value="The Entertainment" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Entertainment</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q3")} type="radio" value="The Host" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Host</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q3")} type="radio" value="The Person Who Can’t Wait to Debrief Afterwards" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Person Who Can’t Wait to Debrief Afterwards</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q3")} type="radio" value="The Person Who’s Friends With Everyone There" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Person Who’s Friends With Everyone There</label>
                            </div>
                        </div>
                        {errors.q3 && <Error errorMessage={ errors.q3.message } /> }
                    </div>

                    <div {...register('q4')} className="flex flex-col w-full space-y-2">
                        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">Which song would you choose to be your MoDater's theme song?</label>
                        <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-2">
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q4")} type="radio" value="Happy" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Happy (Pharrell Williams) - Always Cheerful and Has a Positive Outlook</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q4")} type="radio" value="Eye of the Tiger" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Eye of the Tiger (Survivor) - Extremely Determined to Keep Growing and Achieving</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q4")} type="radio" value="Party in the USA" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Party in the USA (Miley Cyrus) - Always the Life of the Party Whether Actually in the US or Abroad</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q4")} type="radio" value="The Scientist" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Scientist (Coldplay) - Loves Solving Problems and Understanding the World</label>
                            </div>
                        </div>
                        {errors.q4 && <Error errorMessage={ errors.q4.message } /> }
                    </div>

                    <div {...register('q5')} className="flex flex-col w-full space-y-2">
                        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">It is your MoDater’s birthday and you want to celebrate: what do you organize?</label>
                        <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-2">
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q5")} type="radio" value="A Party" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">A Party</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q5")} type="radio" value="An intimate dinner with close friends" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">An intimate dinner with close friends</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q5")} type="radio" value="An activity with close friends (EX: Escape the Room, Bowling)" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">An activity with close friends (EX: Escape the Room, Bowling)</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q5")} type="radio" value="A Trip" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">A Trip</label>
                            </div>
                        </div>
                        {errors.q5 && <Error errorMessage={ errors.q5.message } /> }
                    </div>
                    
                    <div {...register('q6')} className="flex flex-col w-full space-y-2">
                        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">What would you say is your MoDater’s primary focus?</label>
                        <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-2">
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q6")} type="radio" value="Constant Growth and Advancement" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Constant Growth and Advancement</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q6")} type="radio" value="Living in the Present Day by Day" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Living in the Present Day by Day</label>
                            </div>
                        </div>
                        {errors.q6 && <Error errorMessage={ errors.q6.message } /> }
                    </div>

                    <div {...register('q7')} className="flex flex-col w-full space-y-2">
                        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">Would you say your MoDater adheres to the status quo or blazes their own trail?</label>
                        <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-2">
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q7")} type="radio" value="Adheres to Status Quo" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Adheres to Status Quo</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q7")} type="radio" value="Blazes a Trail" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">Blazes a Trail</label>
                            </div>
                        </div>
                        {errors.q7 && <Error errorMessage={ errors.q7.message } /> }
                    </div>

                    <div {...register('q8')} className="flex flex-col w-full space-y-2">
                        <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">What does your MoDater appreciate more?</label>
                        <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-2">
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q8")} type="radio" value="The Big Picture" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Big Picture</label>
                            </div>
                            <div className="flex justify-start items-center space-x-2">
                                <input {...register("q8")} type="radio" value="The Little Things" className="w-4 h-4 flex-none"/>
                                <label className="flex justify-start text-sm font-light text-grey-darkest">The Little Things</label>
                            </div>
                        </div>
                        {errors.q8 && <Error errorMessage={ errors.q8.message } /> }
                    </div>

                </div>
            </form>
        </div>
    )
}

export default ExtraInfoForm
