import React, { useEffect, useRef } from 'react'
import './nav.css';
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close';

function Modal({ setModal, header, body, rejectHandler, disabled }) {

    const elRef = useRef();

    useEffect(() => {
        const onClick = (e) => {
            if(!elRef?.current?.contains(e.target)) {
                setModal(prev => !prev);
                document.body.style.overflow = "auto";
            }
        }
        console.log(window.scrollY)
        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick)
        }
    }, [])

    return (
        <div className="w-full h-screen absolute flex justify-center items-center z-10 bg-black bg-opacity-20" style={{top: `${window.scrollY}px`}}>
            {/* need to figure out how to center the modal no matter how big the screen is */}
            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} className="relative w-72 sm:w-96 mb-20"  ref={elRef}>
                <button className="absolute z-10 top-4 right-4 hover:text-gray-500 text-gray-300" onClick={(e) => {
                    e.stopPropagation();
                    setModal(prev => !prev);
                    document.body.style.overflow = "auto";
                }}><CloseIcon /></button>
                <div className="flex flex-col nav-bg rounded-lg p-4 space-y-8 ring-2 ring-gray-700 ring-opacity-20 text-gray-300">
                    <div className="text-sm sm:text-base font-medium tracking-wider mt-10 underline text-center">{header}</div>
                    <div className="text-xs sm:text-sm text-center tracking-wide font-light">{body}</div>
                    <div className="w-full flex justify-center item-center space-x-8 text-black">
                        <button className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800" onClick={(e) => {
                            e.stopPropagation();
                            setModal(prev => !prev);
                            document.body.style.overflow = "auto";
                        }}>Cancel</button>
                        <button className="text-sm nav-bg px-3 py-1 rounded-sm text-red-400 border border-gray-800" onClick={(e) => {
                            e.stopPropagation();
                            rejectHandler();
                            document.body.style.overflow = "auto";
                        }} disabled={disabled}>Confirm</button>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default Modal
