import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import ExtraInfoView from '../Components/ExtraInfoView';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { getMatchById, getRefreshTokenBeforeApiCall } from '../Components/utilities/EndPoints';
import RateCard from '../Components/RateCard';
import Modal from '../Components/Modal';
import { motion } from 'framer-motion';
import { getRelationToJudaism } from '../Components/utilities/funcs';
import '../Components/nav.css';

function ReviewMatch() {

    const {token, setToken, setLoggedIn, navigate, user, setError } = useContext(TokenContext);
    const [match, setMatch] = useState();
    const [matchProfile, setMatchProfile] = useState();
    const [modal, setModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [header, setHeader] = useState();
    const [body, setBody] = useState();
    const [handler, setHandler] = useState();
    const { id } = useParams();
    const _isMounted = useRef(true);

    useEffect(() => {
        return () => {
            // console.log("Review Match Component Unmounting")
            _isMounted.current = false;
        }
    }, []);
    
    useEffect(() => {
        // console.log("getting match by id in reviewMatch")
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getMatchById, [id, setMatch, setError, _isMounted, navigate]);
    }, [token])


    useEffect(() => {
        if (!user || !user.modaterProfileId || !match) return
        if (user.modaterProfileId.sex === 'Male') {
            setMatchProfile(match.girlModaterId);
        }
        else {
            setMatchProfile(match.guyModaterId);
        }
    }, [token, match])

    const matchesConnector = match?.connectorsId?.filter(match => match._id === matchProfile?.connectorId)[0];
    // console.log(match)
    return (
            <div className="w-full h-full sm:-mt-0">
                { modal && 
                    <Modal 
                        setModal={setModal}
                        disabled={disabled}
                        header={header}
                        body={body}
                        rejectHandler={handler}
                    />
                }
                { matchProfile &&
                    <div className="w-full md:h-full flex flex-col xl:flex-row py-8 px-4">
                        <div className="w-full h-full flex flex-col-reverse md:flex-row md:mb-12 xl:w-2/3 p-4">
                            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} transition={{ delay: 0 }} className="w-full h-full text-gray-300 space-y-4 md:overflow-y-scroll">
                                <div className="flex sticky top-0 body-bg items-center">
                                    <div className="text-lg font-medium tracking-wider uppercase special">{`${matchProfile.firstName} ${matchProfile.lastName},`}</div>
                                    <div className="text-2xl ml-2 tracking-widest font-light special">{new Date(Date.now() - new Date(matchProfile.dateOfBirth)).getFullYear() - 1970}</div>
                                    <div className="text-xl tracking-wider uppercase ml-4 special">{matchProfile?.community ? matchProfile.community : ''}</div>
                                </div>
                                <div className="flex space-x-1 items-center">
                                    <div className="tracking-wide font-medium uppercase text-xs">Connector - {matchesConnector?.firstName} {matchesConnector?.lastName}</div>
                                </div>
                                <div className="flex space-x-1 items-center">
                                    <div className="tracking-wide uppercase text-xs">{matchProfile.vocation} -</div>
                                    <div className="tracking-wide uppercase text-xs">{matchProfile.college}</div>
                                </div>
                                <div className="flex space-x-1 items-center">
                                    <div className="tracking-wide uppercase text-xs">High School -</div>
                                    <div className="tracking-wide uppercase text-xs">{matchProfile.highSchool}</div>
                                </div>
                                <div className="flex space-x-1 items-center">
                                    <div className="tracking-wide uppercase text-xs">Year In Israel -</div>
                                    <div className="tracking-wide uppercase text-xs">{matchProfile.israelSchool}</div>
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <div className="tracking-wider font-medium uppercase text-blue-200 text-md special">Location</div>
                                    <div className="flex space-x-2 text-xs">
                                        <div className="uppercase tracking-wide">{matchProfile.city},</div>
                                        <div className="uppercase tracking-wide">{matchProfile.state}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">A Little About Me</div>
                                    <div className="tracking-wider special leading-7">{matchProfile.bio}</div>
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-base special">Vacation Preference</div>
                                    <div className="tracking-wide font-light leading-7 ">{matchProfile.vacationPreference}</div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Activities</div>
                                    <div className="flex flex-wrap">
                                        {matchProfile.activities.map((activity, index) => {
                                            return <div key={index} className="uppercase text-sm font-medium bg-indigo-300 px-2 py-0.5 mr-3 mt-3 rounded-sm"><span className="text-black">{activity}</span></div>
                                        })}
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Personality Traits</div>
                                    <div className="flex flex-wrap">
                                        {matchProfile.characteristics.map((characteristic, index) => {
                                            return <div key={index} className="uppercase text-sm nav-bg px-2 py-0.5 mr-3 mt-3 rounded-sm"><span>{characteristic}</span></div>
                                        })}
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Religious Perspective</div>
                                    <div className="tracking-wide font-light uppercase text-sm">{getRelationToJudaism(matchProfile.relationToJudaism)}</div>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Religious Observance</div>
                                    <div className="tracking-wide font-light leading-7 space-x-3"><span>Shabbos:</span><span className="uppercase text-sm">{matchProfile.shabbos}</span></div>
                                    <div className="tracking-wide font-light leading-7 space-x-3"><span>Kosher:</span><span className="uppercase text-sm">{matchProfile.kosher}</span></div>
                                    <div className="tracking-wide font-light leading-7 space-x-3"><span>Shomer Negiyah:</span><span className="uppercase text-sm">{matchProfile.shomerNegiah}</span></div>
                                    <div className="tracking-wide font-light leading-7 space-x-3"><span>Learns:</span><span className="uppercase text-sm">{matchProfile.learns}</span></div>
                                    <div className="tracking-wide font-light leading-7 space-x-3"><span>Aliyah:</span><span className="uppercase text-sm">{matchProfile.aliyah}</span></div>
                                </div> 
                                <div className="flex flex-col space-y-2">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Political Preference</div>
                                    <div className="tracking-wide font-light uppercase text-sm">{matchProfile.politics}</div>
                                </div> 
                                <div className="flex flex-col space-y-2">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Previously Married</div>
                                    <div className="tracking-wide font-light uppercase text-sm">{matchProfile.divorcedStatus}</div>
                                </div>                      
                                <div className="flex flex-col space-y-2">
                                    <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Children</div>
                                    <div className="tracking-wide font-light uppercase text-sm">{matchProfile.childrenStatus}</div>
                                </div>                      
                            </motion.div>

                            <div className="w-full h-full flex flex-col items-center justify-center mb-8 lg:mb-0">
                                <RateCard 
                                    urls={matchProfile.photoUrls}
                                    id={id}
                                    setModal={setModal}
                                    setHeader={setHeader}
                                    setBody={setBody}
                                    setHandler={setHandler}
                                    setDisabled={setDisabled}
                                />
                            </div>
                        </div>
             
                        <motion.div initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0}} transition={{ delay: .1 }} className="flex flex-col h-full w-full xl:w-1/3 text-gray-300 p-4 overflow-y-visible">
                            <div className="text-sm font-medium tracking-wider mb-6 font-sans md:text-center xl:text-left text-blue-200 uppercase special">What the Connector had to say about {`${matchProfile.firstName} ${matchProfile.lastName}`}</div>
                                <ExtraInfoView modater={matchProfile} />
                        </motion.div>

                    </div>
                }
            </div>
    )
}

export default ReviewMatch
