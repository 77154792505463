import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import '../Components/nav.css'

import Error from '../Components/utilities/Error';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { login } from '../Components/utilities/EndPoints';

const schema = yup.object().shape({
    email: yup.string().email().required('Please enter a valid Email'),
    password: yup.string().min(6).max(15).required('Password must be between 6 and 15 Characters long'),
})

const SignIn = () => {

    const { register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const navigate = useNavigate();
    const [formError, setFormError] = useState();
    const { setToken, setLoggedIn } = useContext(TokenContext);

  
    const submitForm = async data => {
        login(data, setToken, setLoggedIn, setFormError, navigate);
    }

    return (
        <form onSubmit={ handleSubmit(submitForm) } className="flex justify-center items-center h-full special text-gray-200">
            <div className="flex flex-col w-full sm:w-1/2 lg:w-2/5 xl:w-2/6 justify-center items-center space-y-4 bg-transparent px-10 tracking-wider">
                <div className="mb-4 text-2xl font-light">Sign In</div>
                {formError && <div className="text-red-400 uppercase text-sm">{formError}</div>}
                <div className="flex flex-col w-full py-2">
                    <label className="flex justify-start mb-4 text-sm ml-6">Email Address</label>
                    <input { ...register('email') } type="text" placeholder="Email" className="border py-2 px-6 rounded-full focus:outline-none focus:bg-gray-200 text-black"/>
                    {errors.email && <Error errorMessage={ errors.email.message }/>}
                </div>
                <div className="flex flex-col w-full py-2">
                    <label className="flex justify-start mb-4 text-sm ml-6">Password</label>
                    <input { ...register('password') } type="password" placeholder="Password" className="border py-2 px-6 rounded-full focus:outline-none focus:bg-gray-200 text-black"/>
                    {errors.password && <Error errorMessage={ errors.password.message } /> }
                </div>
                <div className="flex flex-col w-full py-2">
                    <button type="submit" className="block bg-blue-600 uppercase p-3 font-light hover:bg-blue-900 text-xs rounded-full text-white w-full">Sign In</button>
                </div>
                <p className="text-sm font-light">
                    <Link to="/forgotPassword" className="ml-4 underline">Forgot Password?</Link>
                </p>
                <div className="flex justify-center w-full py-2 text-light">OR</div>
                <div className="flex flex-col w-full py-2">
                    <Link to="/signUp" className="flex justify-center border border-gray-600 uppercase p-3 font-light hover:bg-black text-xs rounded-full w-full">Create Account</Link>
                </div>
            </div>
        </form>
    )
}

export default SignIn;