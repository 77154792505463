import React, { useEffect } from 'react'
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function HomePageLine({ children, index }) {
    const controls = useAnimation();
    const [ref, inView] = useInView({threshold: 1});

    const variants = {
        visible: { opacity: 1, x: 0, y: 0, transition: { duration: .3, delay: index} },
        hidden: { opacity: 0, scale: 1, x: 0, y: 10}
    };

    useEffect(() => {
        // console.log(inView)
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <motion.div variants={variants} initial="hidden" animate={controls} ref={ref}>
            {children}
        </motion.div> 
    )
}

export default HomePageLine