import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function PhotoCarousel({curImageIndex, setCurImageIndex, urls}) {
    return (
        <div className="h-full w-full relative">
            {urls.length > 0 ? <img src={urls[curImageIndex] && urls[curImageIndex].url} alt="" className='object-cover h-full w-full'/> : <img src="https://modate-photos.s3.amazonaws.com/blank-profile-picture.png" alt="" className="object-cover h-96 w-full"/>}
            { curImageIndex > 0 &&
                <button className="absolute top-1/2 left-3 bg-black w-8 h-8 text-white flex justify-center items-center rounded-full bg-opacity-40 hover:bg-opacity-50" onClick={() => setCurImageIndex(index => index - 1)}>
                    <ChevronLeftIcon />
                </button>
            }
            { curImageIndex < urls.length-1 &&
                <button className="absolute top-1/2 right-3 bg-black w-8 h-8 text-white flex justify-center items-center rounded-full bg-opacity-40 hover:bg-opacity-50" onClick={() => setCurImageIndex(index => index + 1)}>
                    <ChevronRightIcon />
                </button>
            }
        </div>
    )

}

export default PhotoCarousel;