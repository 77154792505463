import React, { useEffect, useRef } from 'react';
import '../Components/nav.css'

function Podcast() {

    const ref = useRef();

    useEffect(() => {
        console.log(ref.current)
        ref.current?.scrollIntoView();
    }, [])

    return (
        <div className="flex flex-col items-center justify-center p-4 w-full">
            <div className="flex justify-center h-[600px] bg-[url('https://modate-photos.s3.amazonaws.com/heading-bg-wave-desktop.svg')] bg-repeat-x bg-bottom w-full bg-[length:2200px_300px]" ref={ref}>
                <div className="flex items-center justify-center space-x-12 -mt-16">
                    <img className="w-[300px] hidden md:block rounded-md" src="https://s3-us-west-2.amazonaws.com/anchor-generated-image-bank/production/podcast_uploaded_nologo400/20898085/20898085-1641765913387-6b852c4450fa1.jpg" alt="" />
                    <div className="w-full flex flex-col max-w-[500px] space-y-6">
                        <div className="flex flex-col space-y-2">
                            <div className="text-2xl md:text-3xl tracking-wider text-blue-300">The MoPod</div>
                            <div className="text-sm text-blue-300">By Evan Harris</div>
                        </div>
                        <div className="text-gray-300 w-full tracking-wider font-light">The MoPod is a Modern Orthodox podcast brought to you by MoDate. Hosted by Evan Harris, the founder of MoDate, the MoPod dives into all topics surrounding Modern Orthodoxy with a focus on dating. Disclaimer: The views expressed on this podcast are not universally held by those who consider themselves Modern Orthodox and we are not trying to impose values upon others.</div>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center p-4 bg-[#ebebec]">
                <div className="flex flex-col space-y-4 max-w-[600px] w-full max-h-[600px] p-2 body-bg md:p-8 overflow-y-scroll border border-gray-800 shadow-lg shadow-black rounded-md mb-16">
                <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Aleeza-Ben-Shalom-on-Soulmate-Clarity--Coaching-e1h0mc4/a-a7o0f6q" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Elan-Kornblum-on-Great-Kosher-Restaurants--Connectivity-e1g8m2s/a-a7l1t76" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Josh-Rutta-on-Big-Ideas--VenUride-e1g2cmn/a-a7k9akm" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Sophie-Gordon-on-The-Scene-in-The-Land-e1g08hd/a-a7k0nmj" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Bob-Quillman-on-Jewish-Hoops-e1ffu6r/a-a7i17k8" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Rabbi-Gettinger-on-the-UWS-e1fcmpb/a-a7hl12d" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Laz-on-Life-e1f0fda/a-a7g5ii8" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Ahuva-Shandelman-on-Love--Therapy-e1en7ak/a-a7f26cb" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Jake-Schrier-on-Trusting-the-Process-e1ed4mu/a-a7defu0" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Lizzy-Brenner-on-The-Power-of-Influence-e1e2if4/a-a7c50e2" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Jessica-Myers-on-The-Stigma-of-Expiration-Dating-e1dn95u/a-a7at84j" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Zahava-Moskowitz-on-The-Stress-After-The-Yes-e1dcouo/a-a79mpbq" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Lauren-Sorscher-on-Age-vs-Stage-e1d2m1s/a-a78hkr7" height="102px" frameborder="0" scrolling="no"></iframe>
                    <iframe src="https://anchor.fm/evan-harris8/embed/episodes/Solmno-and-the-Theory-of-Should-e1cpp86/a-a77gppd" height="102px" frameborder="0" scrolling="no"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Podcast