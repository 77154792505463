import React, { useEffect } from 'react'
import '../Components/nav.css';
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function HomePageCard({ children, span, startX, startY, index }) {

    const controls = useAnimation();
    const [ref, inView] = useInView({threshold: .75});

    const variants = {
        visible: { opacity: 1, scale: 1, x: 0, y: 0, transition: { duration: .5, delay: index} },
        hidden: { opacity: 0, scale: .75, x: startX, y: startY }
    };

    useEffect(() => {
        // console.log(inView)
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <motion.div variants={variants} initial="hidden" animate={controls} className={`max-h-[600px] rounded-lg shadow-lg shadow-black/80 body-bg border border-gray-800 z-10 space-y-4 md:col-span-${span} overflow-y-scroll`} ref={ref}>
            {children}
        </motion.div> 
    )
}

export default HomePageCard