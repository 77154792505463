import React, { useContext, useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import { Outlet } from 'react-router-dom';
import { TokenContext } from '../Components/utilities/accessTokenContext';

function RolesAuth({ allowedRoles }) {

    const { token } = useContext(TokenContext);
    
    const roles = token && token.length > 0 ? jwtDecode(token)?.roles : [];

    return roles.map(role => allowedRoles.includes(role)).find(bool => bool === true) ? <Outlet /> : <div className="w-full h-full flex justify-center items-center text-gray-300 text-3xl font-light tracking-wider">Not Authorized To Access This Route</div>
}

export default RolesAuth