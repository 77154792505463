import React, { useEffect } from 'react'
import TotalSignUp from './TotalSignUp'
import { Link } from 'react-router-dom';
 
const EditModaterProfile = ({ editModater }) => {

    return (
        <div className="w-full h-full relative">
            <Link to="/dashboard/profile" className="absolute top-4 left-4 px-3 py-0.5 body-bg rounded-sm text-blue-300 text-sm hover:text-blue-400 border border-blue-300 hover:border-blue-400 z-10">Back to Profile</Link>
            <TotalSignUp header="Edit Profile" submitButtonText="Submit Changes" submitHandler={editModater} />
        </div>
    )
}

export default EditModaterProfile
