import React, { useContext, useEffect, useState } from 'react';
import CardProfile from '../Components/CardProfile';
import ExtraInfoForm from '../Components/ExtraInfoForm';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { ConnectorDashboardContext } from '../Components/utilities/connectorDashboardContext';
import Select from 'react-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { confirmModater, getRefreshTokenBeforeApiCall, getUnverifiedModaters, rejectModater } from '../Components/utilities/EndPoints';
import Modal from '../Components/Modal';
import { STYLES } from '../constants.js'

function ReviewModaters() {

    const { token, setToken, setLoggedIn, navigate, modaters, setError, setSuccess } = useContext(TokenContext);
    const { unverifiedModaters, setUnverifiedModaters } = useContext(ConnectorDashboardContext);
    const [selected, setSelected] = useState();
    const [disabled, setDisabled] = useState(false);
    const [modal, setModal] = useState(false);

    const schema = yup.object().shape({

        q1: yup.string().required('You need to choose an option').nullable(),
        q2: yup.string().required('You need to choose an option').nullable(),
        q3: yup.string().required('You need to choose an option').nullable(),
        q4: yup.string().required('You need to choose an option').nullable(),
        q5: yup.string().required('You need to choose an option').nullable(),
        q6: yup.string().required('You need to choose an option').nullable(),
        q7: yup.string().required('You need to choose an option').nullable(),
        q8: yup.string().required('You need to choose an option').nullable(),
        
    })

    const { register, handleSubmit, reset, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const submitForm = async data => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, confirmModater, [data, selected.value, reset, setSelected, setDisabled, setToken, setError, setSuccess]);
    }

    const rejectHandler = () => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, rejectModater, [selected.value, reset, setSelected, setDisabled, setModal, setToken, setError, setSuccess]);
    }

    const people = unverifiedModaters && unverifiedModaters.map(modater => {
        return {
            modaterProfileId: {
                ...modater
            }
        }
    })

    const selectedPerson = selected ? people.filter(modater => modater.modaterProfileId._id === selected.value)[0] : null;
    
    return (
        <div className="w-full h-full py-8 lg:py-0 relative">
            { modal && 
                <Modal 
                    setModal={setModal}
                    body={selected && `By clicking Confirm you will not become ${selected && selected.label}'s Connector`}
                    rejectHandler={rejectHandler}
                    disabled={disabled}
                    header="Are you sure you do not know this Person?"
                />
            }
            <div className="w-full h-full p-4 md:p-12 space-y-8">
                <div className="md:h-full flex flex-col-reverse items-center md:flex-row lg:space-x-8">
                    <div className="flex flex-col w-full md:w-1/2 h-full space-y-8 mt-12 md:mt-0">
                        { selectedPerson &&
                            <div className="ml-4 text-white text-xl">Please fill out these questions for {`${selectedPerson.modaterProfileId.firstName} ${selectedPerson.modaterProfileId.lastName}`}</div>
                        }
                        <ExtraInfoForm 
                            register={register}
                            errors={errors}
                        />
                    </div>
                    { modaters &&
                        <div className="w-full h-full md:w-1/2 flex flex-col items-center space-y-8">
                            <div className="w-full lg:w-5/6">
                                <Select 
                                    value={selected}
                                    styles={STYLES}
                                    isClearable
                                    options={unverifiedModaters ? unverifiedModaters.map(modater => ({label: `${modater.firstName} ${modater.lastName}`, value: modater._id})) : []}
                                    onChange={setSelected}
                                    placeholder="Select MoDater to review"
                                />
                            </div>
                            { selectedPerson ?
                                <div className="flex flex-col space-y-8 w-full h-full items-center md:justify-end">
                                    <CardProfile user={selectedPerson} urls={selectedPerson.modaterProfileId.photoUrls} height="cardHeight" width="cardWidth"/>
                                    <div className="w-full flex items-center justify-center space-x-8">
                                        <button className="p-2 text-xs bg-green-400 flex items-center rounded-md uppercase" onClick={handleSubmit(submitForm)} disabled={disabled}>Submit</button>
                                        <button className="p-2 text-xs bg-red-400 flex items-center rounded-md uppercase" onClick={() => setModal(prev => !prev)} type='button'>Don't Know This Person</button>
                                    </div>
                                    <div className="text-xs px-4 md:px-12 text-gray-400 lg:w-3/4 lg:p-0">By clicking submit you are accepting the invitation to be {`${selectedPerson.modaterProfileId.firstName} ${selectedPerson.modaterProfileId.lastName}'s`} connector if you do not know this person please click "DON'T KNOW THIS PERSON" and you will not be responsible for them</div>
                                </div> :
                                <div className="text-gray-200">Please select one of your Modaters to review</div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ReviewModaters
