import React, { useContext, useEffect, useRef, useState } from 'react'
import { TokenContext } from '../../Components/utilities/accessTokenContext';
import { getAggregateLikesForModater, getAllModaterDates, getAllPastModaterDates, getMatchesStats, getMostRecentLikedMatches, getRefreshTokenBeforeApiCall } from '../../Components/utilities/EndPoints';
import CircularProgress from '@mui/material/CircularProgress';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import dateFormat from 'dateformat';
import '../../Components/nav.css';
import { useLocalStorage } from '../../Components/utilities/hooks';
import Loading from '../../Components/Loading';

function MatchInfo() {

    const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);
    const [stats, setStats] = useState();
    const [statsLoading, setStatsLoading] = useState(false);
    const [currentDates, setCurrentDates] = useState();
    const [currentDatesLoading, setCurrentDatesLoading] = useState(false);
    const [aggregateLikes, setAggregateLikes] = useState();
    const [aggregateLikesLoading, setAggregateLikesLoading] = useState(false);
    const [mostRecentLikes, setMostRecentLikes] = useState();
    const [mostRecentLikesLoading, setMostRecentLikesLoading] = useState(false);
    const [pastDates, setPastDates] = useState();
    const [pastDatesLoading, setPastDatesLoading] = useState();
    const [selected, setSelected] = useLocalStorage('matchInfo', JSON.parse(localStorage.getItem('matchInfo')) || 'current');
    const _isMounted = useRef(true);

    const LikedComponent = ({ match }) => {
        return (
            <div className="flex items-center space-x-4 body-bg px-4 py-3 rounded-md border border-gray-800 overflow-hidden" >
                <div className="flex items-center -space-x-4 flex-none">
                    { match.guyReview.LikedMatch ? 
                        <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white" src={match.matchInfo.guy.photoUrls[0]?.url ? match.matchInfo.guy.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" /> :
                        <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white" src={match.matchInfo.girl.photoUrls[0]?.url ? match.matchInfo.girl.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                    }
                    { match.guyReview.LikedMatch ? 
                        <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white" src={match.matchInfo.girl.photoUrls[0]?.url ? match.matchInfo.girl.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" /> :
                        <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white" src={match.matchInfo.guy.photoUrls[0]?.url ? match.matchInfo.guy.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                    }
                </div>
                <div className="flex flex-col space-y-3 w-full">
                    <div className="flex space-x-2 items-center w-full">
                        <div className="text-xs max-w-[50%] truncate">{match.guyReview.LikedMatch ? `${match.matchInfo.guy.firstName} ${match.matchInfo.guy.lastName}` : `${match.matchInfo.girl.firstName} ${match.matchInfo.girl.lastName}`}</div>
                        <ArrowRightAltIcon className="text-blue-400" fontSize="small" />
                        <div className="text-xs max-w-[50%] truncate">{match.guyReview.LikedMatch ? `${match.matchInfo.girl.firstName} ${match.matchInfo.girl.lastName}` : `${match.matchInfo.guy.firstName} ${match.matchInfo.guy.lastName}`}</div>
                    </div>
                    <div className="text-xs text-gray-400">Liked Match On {dateFormat(new Date(match.timeReviewed), 'm/dd/yy')}</div> 
                </div>
            </div>
        )
    }

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getMatchesStats, [setStats, setStatsLoading, _isMounted]);
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getMostRecentLikedMatches, [setMostRecentLikes, setMostRecentLikesLoading, _isMounted]);
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getAggregateLikesForModater, [setAggregateLikes, setAggregateLikesLoading, _isMounted]);
    }, [token])

    useEffect(() => {
        if (selected === 'current') {
            getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getAllModaterDates, [setCurrentDates, setCurrentDatesLoading, _isMounted])
        } 
        else {
            console.log('calling')
            getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getAllPastModaterDates, [setPastDates, setPastDatesLoading, _isMounted])
        }
    }, [selected])

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])
    console.log(aggregateLikes)

    return (
        <div className="flex justify-start w-full max-w-[896px]">
            <div className="flex flex-col  w-full">
                <div className="flex flex-col-reverse w-full md:space-x-8 md:flex-row">
                    <div className="h-[30rem] md:space-y-8 w-full md:w-1/2 mb-8">
                        <div className="nav-bg h-1/2 px-4 overflow-y-scroll space-y-2 rounded-md mt-8 md:mt-0 border border-gray-800 shadow-lg shadow-black">
                            <div className="w-full sticky top-0 nav-bg px-4 pt-4 pb-2 font-bold z-20">Match Satistics</div>
                            { statsLoading ?
                                <Loading type="statistics" numPlaceholders={10} /> :
                                stats &&
                                    <>
                                        <div className="flex space-x-2 items-center body-bg p-2 rounded-md flex-1">
                                            <span className="font-light tracking-wide">Matches:</span><span className="text-md font-bold">{stats?.numberOfMatches}</span>
                                        </div>
                                        <div className="flex space-x-2 items-center body-bg p-2 rounded-md">
                                            <span className="font-light tracking-wide">Activated Matches:</span><span className="text-md font-bold">{stats?.numberOfActivatedMatches}</span>
                                        </div>
                                        <div className="flex space-x-2 items-center body-bg p-2 rounded-md">
                                            <span className="font-light tracking-wide">Unactivated Matches:</span><span className="text-md font-bold">{stats?.numberOfMatches - stats?.numberOfActivatedMatches}</span>
                                        </div>
                                        <div className="flex space-x-2 items-center body-bg p-2 rounded-md">
                                            <span className="font-light tracking-wide">Matches In Connector Stage:</span><span className="text-md font-bold">{stats?.numberOfMatchesToBeReviewedByConnectors}</span>
                                        </div>
                                        <div className="flex space-x-2 items-center body-bg p-2 rounded-md">
                                            <span className="font-light tracking-wide">Matches In Modater Stage:</span><span className="text-md font-bold">{stats?.numberOfMatchesToBeReviewedByModaters}</span>
                                        </div>
                                        <div className="flex space-x-2 items-center body-bg p-2 rounded-md">
                                            <span className="font-light tracking-wide">Matches Where One Modater Liked:</span><span className="text-md font-bold">{stats?.numberOfMatchesWhereOneModaterLikedMatch}</span>
                                        </div>
                                    </>
                            }
                        </div>
                        <div className="nav-bg h-1/2 px-4 overflow-y-scroll space-y-2 rounded-md border border-gray-800 shadow-lg shadow-black mt-8">
                            <div className="w-full sticky top-0 nav-bg px-4 pt-4 pb-2 font-bold z-20">Most Recently Liked Matches</div>
                            { mostRecentLikesLoading ? 
                                <Loading type="mostRecentLikes" numPlaceholders={10} /> :
                                mostRecentLikes && mostRecentLikes.map(match => <LikedComponent match={match} key={match._id} /> ) 
                            }
                        </div> 
                    </div>
                    
                    <div className="nav-bg w-full md:w-1/2 h-[32rem] overflow-y-scroll rounded-md border border-gray-800 shadow-lg shadow-black">
                        <div className="flex space-x-4 w-full sticky top-0 nav-bg px-4 pt-4 pb-2 z-20">
                            <button className={`tracking-wider ${selected === 'current' && 'underline'}`} onClick={() => setSelected('current')}>Current Dates</button>                        
                            <button className={`tracking-wider ${selected === 'past' && 'underline'}`} onClick={() => setSelected('past')}>Past Relationships</button>                        
                        </div>
                        <div className="space-y-2 p-4">
                            { selected === 'current' ? currentDatesLoading ?
                                <Loading type="dating" numPlaceholders={10} /> :
                                currentDates && currentDates.map(date => {
                                    const guy = date.daters.filter(dater => dater.sex === 'Male')[0]
                                    const girl = date.daters.filter(dater => dater.sex === 'Female')[0]
                                    return (
                                        <div className="flex w-full space-x-4 body-bg p-4 rounded-md" key={date._id}>
                                            <div className="flex items-center justify-center -space-x-4">
                                                <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white" src={guy?.photoUrls[0]?.url ? guy.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                                                <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white" src={girl?.photoUrls[0]?.url ? girl.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                                            </div>
                                            <div className="flex flex-col justify-center space-y-2">
                                                <div className="flex items-center justify-center space-x-2">
                                                    <div className="text-xs">{guy?.firstName} {guy?.lastName}</div>
                                                    <FavoriteIcon className="text-red-400" fontSize="small"/>
                                                    <div className="text-xs">{girl?.firstName} {girl?.lastName}</div>
                                                </div>
                                                <div className="text-xs tracking-wider text-gray-400">Matched On {dateFormat(new Date(date.startedDating), 'm/dd/yy')}</div>
                                            </div>
                                        </div>
                                    )   
                                }) : null
                            }
                            { selected === 'past' ? pastDatesLoading ?
                                <Loading type="dating" numPlaceholders={10} /> :
                                pastDates && pastDates.map(modater => {
                                    // console.log(modater)
                                    // console.log(modater.datesInfo.details.lengthOfRelationship === '0 DATE(S)', modater.modaterInfo._id === modater.datesInfo.details.modaterProfileId, modater.datesInfo.details.initiated)
                                    // console.log(modater.datesInfo.details.lengthOfRelationship === '0 DATE(S)', modater.datesInfo.matchId._id === modater.datesInfo.details.modaterProfileId, modater.datesInfo.details.initiated)
                                    const modaterInitiatedBreakUpId = modater.modaterInfo._id !== modater.datesInfo.details.modaterProfileId && modater.datesInfo.details.initiated ? modater.modaterInfo._id :  modater.datesInfo.matchId._id
                                    return (
                                        <div className="flex flex-col w-full space-y-2 body-bg p-4 rounded-md" key={modater.datesInfo.details._id}>
                                            <div className='flex w-full space-x-4'>
                                                <div className="flex items-center justify-center -space-x-4">
                                                    <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white" src={modater.modaterInfo.photoUrls[0]?.url ? modater.modaterInfo.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                                                    <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white" src={modater.datesInfo.matchId.photoUrls[0]?.url ? modater.datesInfo.matchId.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                                                </div>
                                                <div className="flex flex-col justify-center space-y-2">
                                                    <div className="flex items-center justify-start space-x-2">
                                                        <div className={`text-xs ${modater.datesInfo.details.lengthOfRelationship === '0 DATE(S)' && modaterInitiatedBreakUpId === modater.modaterInfo._id ? 'text-red-400' : ''}`}>{modater.modaterInfo.firstName} {modater.modaterInfo.lastName}</div>
                                                        <HeartBrokenIcon className="text-red-400" fontSize="small"/>
                                                        <div className={`text-xs ${modater.datesInfo.details.lengthOfRelationship === '0 DATE(S)' && modaterInitiatedBreakUpId === modater.datesInfo.matchId._id ? 'text-red-400' : ''}`}>{modater.datesInfo.matchId.firstName} {modater.datesInfo.matchId.lastName}</div>
                                                    </div>
                                                    <div className="text-xs tracking-wider">Ended {dateFormat(new Date(modater.datesInfo.details.dateEnded), 'm/dd/yy')} after {modater.datesInfo.details.lengthOfRelationship}</div>
                                                </div>
                                            </div>
                                            { modater.datesInfo.details.lengthOfRelationship === '0 DATE(S)' &&
                                                <div className=''>
                                                    <span className='text-sm mr-2'>Details:</span>
                                                    <span className='text-xs'>{modater.datesInfo.details.reason}</span>
                                                </div>
                                            }
                                        </div>
                                    )
                                }) : null
                            }
                        </div>
                    </div>
                </div>
                
                <div className="nav-bg w-full h-[20rem] rounded-md border border-gray-800 shadow-lg shadow-black overflow-hidden mt-16 md:mt-8">
                    <div className="w-full sticky top-0 nav-bg px-4 pt-4 pb-2 font-bold">Liked Matches Aggregation</div>
                    <div className="space-x-8 p-4 flex overflow-x-scroll h-full w-full snap-x">
                        { aggregateLikesLoading ? 
                            <Loading type="aggregateLikes" numPlaceholders={10} /> :
                            aggregateLikes &&
                                aggregateLikes.map(dater => {
                                    return (
                                        <div className="snap-center flex flex-col body-bg h-[230px] w-[160px] flex-none rounded-lg overflow-hidden relative hover:opacity-80 hover:cursor-pointer" key={dater.modaterId._id}>
                                            <img src={dater.modaterId.photoUrls[0]?.url ? dater.modaterId.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} className="h-3/4 object-center object-cover" alt="" />
                                            <div className="h-1/4 w-full flex flex-col py-2 space-y-1 px-2">
                                                    <div className="tracking-wider text-xs text-gray-300 truncate">{dater.modaterId.firstName} {dater.modaterId.lastName}</div>
                                                    <div className="text-sm text-gray-200 font-bold flex items-center">{dater.numMatches} <span className="text-xs text-gray-300 tracking-widest font-extralight ml-2">Likes</span></div>
                                            </div>
                                            { !dater.modaterId.activeStatus && <div className={`absolute top-2 right-2 text-xs body-bg px-2 py-0.5 rounded-sm font-bold text-red-400`}>Busy</div> }
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MatchInfo