import React from 'react'

function NotFound() {
    return (
        <div className="w-full h-full text-white flex justify-center items-center text-4xl">
            page Not found
        </div>
    )
}

export default NotFound
