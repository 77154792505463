import React, { forwardRef } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const ModalChild = forwardRef(({ setDisabled, step, setStep, modaters, setSelected, selected, suggestions, setSuggestions, selectedMatch, setSelectedMatch, loading, cancelMatch, setCancelMatch, setLoading, loadingModaters }, ref) => {

    switch (step) {
        case 0:
            return (
                <Step1 
                    setDisabled={setDisabled} 
                    setStep={setStep} 
                    setSelected={setSelected} 
                    selected={selected} 
                    suggestions={suggestions} 
                    setSuggestions={setSuggestions}
                    setLoading={setLoading}
                    modaters={modaters}
                    loadingModaters={loadingModaters}
                    ref={ref}
                />
            )
        case 1:
            return (
                <Step2 
                    setDisabled={setDisabled} 
                    setStep={setStep} 
                    selected={selected} 
                    suggestions={suggestions} 
                    setSuggestions={setSuggestions}
                    selectedMatch={selectedMatch} 
                    setSelectedMatch={setSelectedMatch}
                    cancelMatch={cancelMatch}
                    setCancelMatch={setCancelMatch}
                    loading={loading}
                    ref={ref}
                />
            )
        case 2:
            return (
                <Step3
                    setDisabled={setDisabled} 
                    selectedMatch={selectedMatch} 
                    selected={selected}
                    setStep={setStep}
                    setSuggestions={setSuggestions}
                    ref={ref}
                />
            )
        default:
            return null;
    }
    
})

export default ModalChild;