import React, { useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';


function ModatersInfoTable({ modater }) {

    const [open, setOpen] = useState(false);

    const getColoring = (match, field) => {
        const modaterField = field;
        const matchField = modaterField === 'guyReview' ? 'girlReview' : 'guyReview';

        if (match.wasActivated) {
            if (match?.expirationDetails?.expired === true) {
                console.log(match?.expirationDetails?.expired, match)
                return 'text-yellow-400';
            }
            if (match.isActiveForConnectors) {
                return 'text-cyan-500';
            }
            if (match.isActiveForModaters) {
                if (!match[modaterField].reviewed && !match[matchField].reviewed) {
                    return '';
                }
                else if (match[modaterField].reviewed && match[modaterField].LikedMatch) {
                    return 'text-green-400';
                }
                else if (match[matchField].reviewed && match[matchField].LikedMatch) {
                    return 'text-blue-400'
                }
            }
            if (!match.isActiveForConnectors && !match.isActiveForModaters) {
                if (match[modaterField].reviewed && !match[modaterField].LikedMatch) {
                    return 'text-red-400';
                }
                else if (match[matchField].reviewed && !match[matchField].LikedMatch) {
                    return 'text-indigo-400';
                }
                else if (match[modaterField].reviewed && match[modaterField].LikedMatch && match[matchField].reviewed && match[matchField].LikedMatch) {
                    return 'text-pink-400';
                }
                return 'text-orange-400';
            }
        }
        else {
            return ''
        }
    }

    const getAciveStatus = () => {
        if (modater.activeStatus) {
            return <div className="px-3 inline-block uppercase text-[#50a831] bg-[#234228] rounded-sm border border-[#50a831] py-0.5 text-xs font-medium tracking-widest">active</div>
        }
        else if (!modater.activeStatus) {
            if (modater.datingStatus) {
                return <div className="px-3 inline-block uppercase text-[#9f7dcb] bg-[#453159] rounded-sm border border-[#aa87d9] py-0.5 text-xs font-medium tracking-widest">dating</div>
            }
            else {
                return <div className="px-3 inline-block uppercase text-[#dd5d40] bg-[#5e3535] rounded-sm border border-[#c7563d] py-0.5 text-xs font-medium tracking-widest">inactive</div>
            }
        }
    }

    return (
        <>
            <div className="min-w-[896px] flex p-2 items-center border-b border-gray-800">
                <div className="ml-4 w-1/4 flex">
                    <button onClick={() => setOpen(prev => !prev)} className={`${open ? 'tansform duration-200 rotate-90' : 'tansform duration-200'} p-2 text-gray-400`}>
                        <ArrowRightIcon />
                    </button>
                    <Link to={`/dashboard/adminDashboard/modaterProfile/${modater?.id}`} className=" text-blue-300 p-2 hover:text-white">{modater?.firstName} {modater?.lastName}</Link>
                </div>
                <div className="w-1/4">
                    <div className="w-full ml-14 text-sm">{modater.matches[0].modaterMatch ? modater.matches.length : 0}</div>
                </div>
                <div className="w-1/4">
                    <div className="w-full ml-8 text-sm">{dateFormat(new Date(modater.dateCreated), 'm/dd/yy')}</div>
                </div>
                <div className="text-sm w-1/4">
                    {getAciveStatus()}
                </div>
            </div>
            <AnimatePresence >
                { open && 
                    <motion.div className={`w-full flex-wrap flex justify-start body-bg`} initial={{opacity: 0, y: -10, maxHeight: 0}} animate={{opacity: 1, y: 0, maxHeight:1000}} transition={{ type: "tween", duration: .4 }} exit={{ opacity: 0, y: -10, height: 0 }}>
                        <div className={`${modater.matches[0].matchInfo ? 'px-6 pb-4 flex flex-wrap' : ''}`}>
                            { modater.matches.map(match => {
                                if (match.modaterMatch) {
                                    const field = modater.id === match.modaterMatch.guyModaterId ? 'guyReview' : 'girlReview'
                                    return (
                                        <Link to={`/dashboard/adminDashboard/modaterProfile/${match.matchInfo._id}`} className={`flex justify-center items-center mt-4 mr-2 nav-bg px-3 py-2 space-x-2 rounded-md border border-gray-800`} key={match.matchInfo._id}>
                                            <div className="relative w-12 h-12">
                                                <img className={`shadow-lg shadow-black h-full w-full object-cover rounded-full ring-1 ring-gray-700 outline-none`} src={match.matchInfo.photoUrls[0]?.url ? match.matchInfo.photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                                            </div>
                                            <div className="flex flex-col space-y-1 text-sm">
                                                <span className={`text-ellipsis overflow-hidden ${getColoring(match.modaterMatch, field)}`}>{match.matchInfo.firstName} {match.matchInfo.lastName}</span>
                                                <span className={`${!match.modaterMatch.timeActivated ? `${match.matchInfo.activeStatus ? 'text-green-400' : 'text-red-400'}`: ''} text-xs`}>{ match.modaterMatch.timeActivated ? `Activated - ${dateFormat(new Date(match.modaterMatch.timeActivated), 'm/dd/yy')}` : `${match.matchInfo.activeStatus ? 'Active' : 'Inactive'}`}</span>
                                            </div>
                                        </Link>
                                    )                       
                                }
                                else {
                                    return null
                                }
                            })}
                        </div>
                    </motion.div> 
                }
            </AnimatePresence>
        </>
    )
}

export default ModatersInfoTable
