import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { TokenContext } from '../../Components/utilities/accessTokenContext';
import { getRefreshTokenBeforeApiCall, getAllModaterMatches, getNumberOfMatchesPages, getModaterAdmin, getModaterOptionsAdmin, deleteTotalAccount } from '../../Components/utilities/EndPoints';
import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import { useLocalStorage } from '../../Components/utilities/hooks';
import AsyncSelect from 'react-select/async';
import { STYLES } from '../../constants.js'
import '../../Components/nav.css'
import ModatersInfoTable from '../../Components/adminDash/ModatersInfoTable';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteModaterModal from '../../Components/DeleteModaterModal';
import FilterListIcon from '@mui/icons-material/FilterList';
import Loading from '../../Components/Loading';
import Dropdown from '../../Components/Dropdown/Dropdown';
import DropDownItem from '../../Components/Dropdown/DropDownItem';
import Tooltip from '@mui/material/Tooltip';
import { components } from 'react-select';
const { Option } = components;

const useStyles = makeStyles(() => ({
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#fff",
            background: "transparent",
            '&.Mui-selected': {
                color: '#92c5fd',
            },
        },
        "& .MuiPaginationItem-root:hover": {
            background: "#272829",
        },
        "& .MuiPaginationItem-root:active": {
            background: "#272829",
        }
    }
}));

function ModatersInfo() {
    
    const { token, setToken, setLoggedIn, navigate, setError, setSuccess } = useContext(TokenContext);
    const [modaterMatches, setModaterMatches] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [totalFilteredPages, setTotalFilteredPages] = useState();
    const [modaterStatusTotals, setModaterStatusTotals] = useState();
    const [currentPage, setCurrentPage] = useLocalStorage('currentMatchesPage', +localStorage.getItem('currentMatchesPage') || 1);
    const [filterPage, setFilterPage] = useLocalStorage('currentMatchesFilterPage', +localStorage.getItem('currentMatchesFilterPage') || 1);
    const [queryResult, setQueryResult] = useState(null);
    const [selected, setSelected] = useLocalStorage('currentSelectedModater', JSON.parse(localStorage.getItem('currentSelectedModater')));
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [userToBeDeleted, setUserToBeDeleted] = useState();
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [dropDownSelected, setDropDownSelected] = useLocalStorage('dropDownSelected', JSON.parse(localStorage.getItem('dropDownSelected')) || 'all');
    const [activatedMatchesBool, setActivatedMatchesBool] = useLocalStorage('currentActivationStatus', JSON.parse(localStorage.getItem('currentActivationStatus') || true));
    const _isMounted = useRef(true);
    const classes = useStyles();
    const elRef = useRef();
    
    const handleChange = (event, value) => {
        console.log('called')
        !isFiltering() ? setCurrentPage(value) : setFilterPage(value);
    };

    const debounce = (func, delay) => {
        let id;
        return (...args) => {
            return new Promise(resolve => {
                if (id) { clearTimeout(id); }
                id = setTimeout(async () => {
                    const res = await func(...args);
                    resolve(res)
                }, delay);
            })
        }
    }
    
    const loadOptions = (input) => {
        return getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getModaterOptionsAdmin, [input, "GET", _isMounted]);
    }
    
    const debounced = useCallback(debounce(loadOptions, 400), [token]);

    const deleteUserFromApp = () => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, deleteTotalAccount, [userToBeDeleted.value, currentPage, activatedMatchesBool, setToken, setModal, setUserToBeDeleted, setDisabled, setError, setSuccess, _isMounted]);
    }
    // this is for disabling scroll when modal pops up
    const modalHandler = () => {
        setModal(prev => !prev);
        document.body.style.overflow = "hidden"
    }

    const isFiltering = () => {
        return dropDownSelected !== 'all';
    }

    const IconOption = (props) => {
        return <Option {...props}>
            <div className="flex items-center space-x-4">
                { props.data.img ? 
                    <img src={props.data.img} className="h-8 w-8 object-cover rounded-full ring-1 ring-gray-800"/> :
                    <img src="https://modate-photos.s3.amazonaws.com/blank-profile-picture.png" alt="" className="h-8 w-8 object-cover rounded-full ring-1 ring-gray-800"/>
                }
                <div className="text-md tracking-wider font-medium">{props.data.label}</div>
            </div>
        </Option>
    }

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getNumberOfMatchesPages, [!isFiltering() ? setTotalPages: setTotalFilteredPages, !isFiltering() ? setCurrentPage : setFilterPage, _isMounted, dropDownSelected, isFiltering(), setModaterStatusTotals]);
    }, [dropDownSelected])
    
    useEffect(() => {
        const controller = new AbortController()
        if ((!isFiltering() && !isNaN(currentPage)) || (isFiltering() && !isNaN(filterPage))) {
            getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getAllModaterMatches, [setModaterMatches, !isFiltering() ? currentPage : filterPage, dropDownSelected, _isMounted, activatedMatchesBool, controller, setIsLoading]);
        }
        return () => {
            controller.abort()
        }
    }, [currentPage, filterPage, activatedMatchesBool, dropDownSelected, token])

    useEffect(() => {
        if (selected) {
            getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getModaterAdmin, [setQueryResult, selected.label, _isMounted, activatedMatchesBool]);
        }
    }, [selected, activatedMatchesBool])

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    return (
        <>
            {modal && 
                <DeleteModaterModal 
                    setModal={setModal} 
                    rejectHandler={deleteUserFromApp} 
                    disabled={disabled}
                    selected={userToBeDeleted}
                    setSelected={setUserToBeDeleted}
                /> 
            }
            <div className="w-full max-w-[896px] flex space-x-4 z-20">
                <AsyncSelect
                    components={{Option:IconOption}}
                    loadOptions={debounced}
                    isClearable
                    onInputChange={setInput}
                    onChange={setSelected}
                    value={selected}
                    className="w-[300px]"
                    styles={STYLES}
                />
                <button className={`nav-bg px-4 py-1 rounded-md border border-gray-800 hover:border-gray-700 text-xs sm:text-sm ${activatedMatchesBool ? 'text-gray-200' : 'text-gray-400'}`} onClick={() => setActivatedMatchesBool(true)}>Activated</button>
                <button className={`nav-bg px-4 py-1 rounded-md border border-gray-800 hover:border-gray-700 text-xs sm:text-sm ${!activatedMatchesBool ? 'text-gray-200' : 'text-gray-400'}`} onClick={() => setActivatedMatchesBool(false)}>Unactivated</button>
            </div>
            <div className="nav-bg w-full rounded-md shadow-md shadow-black border border-gray-800 overflow-y-scroll h-[475px] max-w-[896px] relative overflow-x-scroll">
                <div className="flex h-16 min-w-[896px] items-center p-6 sidebar-bg sticky top-0 border-b border-gray-800 z-10">
                    <div className="ml-4 w-1/4 relative flex items-center">
                        <div className="uppercase text-sm tracking-widest">Name</div>
                        <div className="absolute right-10 uppercase text-xs text-red-400 px-2 py-0.5 cursor-pointer rounded-sm" onClick={modalHandler}><DeleteOutlinedIcon fontSize="small"/></div>
                    </div>
                    <div className="relative w-1/4 flex items-center uppercase text-sm tracking-widest space-x-2">
                        <span className="font-thin absolute -left-8 border-0 border-l border-gray-700 h-full"></span>
                        <div className="">Matches</div>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="16" y1="15" x2="12" y2="19"></line>
                            <line x1="8" y1="15" x2="12" y2="19"></line>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="16" y1="9" x2="12" y2="5"></line>
                            <line x1="8" y1="9" x2="12" y2="5"></line>
                        </svg> 
                        <Tooltip title="Filter Options" placement="top" arrow>
                            <div className="hover:text-gray-200 hover:cursor-pointer relative" onClick={(e) => {e.stopPropagation(); setDropDownOpen(prev => !prev)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-adjustments-horizontal" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="14" cy="6" r="2"></circle>
                                    <line x1="4" y1="6" x2="12" y2="6"></line>
                                    <line x1="16" y1="6" x2="20" y2="6"></line>
                                    <circle cx="8" cy="12" r="2"></circle>
                                    <line x1="4" y1="12" x2="6" y2="12"></line>
                                    <line x1="10" y1="12" x2="20" y2="12"></line>
                                    <circle cx="17" cy="18" r="2"></circle>
                                    <line x1="4" y1="18" x2="15" y2="18"></line>
                                    <line x1="19" y1="18" x2="20" y2="18"></line>
                                </svg>
                                {/* { dropDownOpen &&
                                    <Dropdown open={dropDownOpen} setOpen={setDropDownOpen} ref={elRef}>
                                        <DropDownItem name={`active (${modaterStatusTotals?.active})`} status='active' setOpen={setDropDownOpen} setSelected={setDropDownSelected} selected={dropDownSelected} />
                                        <DropDownItem name={`inactive (${modaterStatusTotals?.inactive})`} status='inactive' setOpen={setDropDownOpen} setSelected={setDropDownSelected} selected={dropDownSelected} />
                                        <DropDownItem name={`dating (${modaterStatusTotals?.dating})`} status='dating' setOpen={setDropDownOpen} setSelected={setDropDownSelected} selected={dropDownSelected} />
                                        <DropDownItem name='all' setOpen={setDropDownOpen} status='all' setSelected={setDropDownSelected} selected={dropDownSelected} />
                                    </Dropdown>
                                }
                            </div>
                        </Tooltip> */}
                    </div>
                    <div className="relative w-1/4 flex items-center uppercase text-sm tracking-widest">
                        <span className="font-thin absolute -left-8 border-0 border-l border-gray-700 h-full"></span>
                        <div className="">Date Joined</div>
                    </div>
                    <div className="relative w-1/4 flex items-center uppercase text-sm tracking-widest">
                        <span className="font-thin absolute -left-8 border-0 border-l border-gray-700 h-full"></span>
                        <div className="">status</div>
                        <div className="flex flex-col ml-6">
                            <Tooltip title="Filter Status" placement="top" arrow>
                                <div className="text-gray-300 cursor-pointer relative" onClick={(e) => {e.stopPropagation(); setDropDownOpen(prev => !prev)}}>
                                    <FilterListIcon fontSize="small"/>
                                    { dropDownOpen &&
                                        <Dropdown open={dropDownOpen} setOpen={setDropDownOpen} ref={elRef}>
                                            <DropDownItem name={`active (${modaterStatusTotals?.active})`} status='active' setOpen={setDropDownOpen} setSelected={setDropDownSelected} selected={dropDownSelected} />
                                            <DropDownItem name={`inactive (${modaterStatusTotals?.inactive})`} status='inactive' setOpen={setDropDownOpen} setSelected={setDropDownSelected} selected={dropDownSelected} />
                                            <DropDownItem name={`dating (${modaterStatusTotals?.dating})`} status='dating' setOpen={setDropDownOpen} setSelected={setDropDownSelected} selected={dropDownSelected} />
                                            <DropDownItem name='all' setOpen={setDropDownOpen} status='all' setSelected={setDropDownSelected} selected={dropDownSelected} />
                                        </Dropdown>
                                    }
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    { 
                        selected ? queryResult?.map(modater => <ModatersInfoTable modater={modater} key={modater.id}/>) :
                        isLoading ? <Loading type="modaterInfo" numPlaceholders={20}/> :
                        modaterMatches.map(modater => {
                            return <ModatersInfoTable modater={modater} key={modater.id}/>
                        })
                    }
                </div>
            </div>
            <>
                {(!isFiltering() ? totalPages : totalFilteredPages) && (!isFiltering() ? currentPage : filterPage) && 
                    <div className="nav-bg rounded-md">
                        {!selected &&
                            <Pagination count={!isFiltering() ? totalPages : totalFilteredPages} classes={{ ul: classes.ul }} page={!isFiltering() ? +currentPage : +filterPage} onChange={handleChange} sx={{color: 'white'}}/>
                        }
                    </div>
                }
            </> 
        </>
    )
}

export default ModatersInfo