import React, { useContext, useState } from 'react'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import './nav.css'
import { TokenContext } from './utilities/accessTokenContext';

function StatusLine({ modater, setModal, setMatchToBeCancelled }) {

    const [open, setOpen] = useState(true);
    const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);

    const handleClick = match => {
        setMatchToBeCancelled(match);
        setModal(prev => !prev);
        document.body.style.overflow = "hidden";
    }

    const getStatus = (match) => {
        if (match.matchWasCancelled.status) {
            return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-red-400">MATCH WAS CANCELED</div>
        }
        else if (match?.expirationDetails?.expired === true) {
            return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-yellow-400">MATCH EXPIRED</div>
        }
        else if (match.isActiveForConnectors) {
            const connectorsOwnReview = match.connectorReviews.filter(review => review.connectorId === modater.connectorId)[0];
            if (!connectorsOwnReview.reviewed) {
                return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-yellow-400">PENDING YOUR REVIEW</div>
            }
            else {
                const dateActivated = new Date(connectorsOwnReview.dateUpdated);
                const threshold = new Date();
                threshold.setDate(threshold.getDate() - 5);

                return (
                    <>
                        <div className="text-xs nav-bg px-2 py-0.5 inline-block text-blue-400">PENDING OTHER CONNECTOR'S REVIEW</div>
                        {threshold > dateActivated && <button className="text-red-500 ml-4 transform hover:scale-110 transition duration-500" onClick={(e) => {e.stopPropagation(); handleClick(match)}}><DeleteOutlinedIcon fontSize="small"/></button>}
                    </>
                )
            }
        }
        else if((match.connectorReviews[0]?.reviewed && match.connectorReviews[1]?.reviewed) || match.connectorReviews[0]?.rating === 1 || match.connectorReviews[1]?.rating === 1) {
            if (match.connectorReviews[0].rating + match.connectorReviews[1].rating < 6) {
                return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-red-400">DIDN'T MEET THRESHOLD</div>
            }
            else if ((match.guyReview.reviewed && !match.guyReview.LikedMatch) || (match.girlReview.reviewed && !match.girlReview.LikedMatch)) {
                return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-red-400">DIDN'T WORK OUT</div>
            }
            else if (!match.guyReview.reviewed || !match.girlReview.reviewed) {
                if (!match.guyReview.reviewed && !match.girlReview.reviewed) {
                    return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-indigo-400">PENDING MODATER REVIEW</div>
                }
                else if ((modater.sex === 'Female' && !match.girlReview.reviewed) || (modater.sex === 'Male' && !match.guyReview.reviewed)) {
                    return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-indigo-400">PENDING MODATER REVIEW</div>
                }
                return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-indigo-400">PENDING MODATER REVIEW</div>
            }
            else {
                return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-green-400">BOTH SIDES LIKED MATCH</div>
            }
        }
        else {
            if ((match.guyReview.reviewed && !match.guyReview.LikedMatch) || (match.girlReview.reviewed && !match.girlReview.LikedMatch)) {
                return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-red-400">DIDN'T WORK OUT</div>
            }
            else if (!match.guyReview.reviewed || !match.girlReview.reviewed) {
                if (!match.guyReview.reviewed && !match.girlReview.reviewed) {
                    return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-indigo-400">PENDING BOTH MODATER'S REVIEW</div>
                }
                else if ((modater.sex === 'Female' && !match.girlReview.reviewed) || (modater.sex === 'Male' && !match.guyReview.reviewed)) {
                    return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-yellow-400">PENDING YOUR MODATER'S REVIEW</div>
                }
                return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-blue-400">PENDING OTHER MODATER'S REVIEW</div>
            }
            else {
                return <div className="text-xs nav-bg px-2 py-0.5 inline-block text-green-400">BOTH SIDES LIKED MATCH</div>
            } 
        }

    }

    return (
        <div className="w-full flex flex-col border-b border-gray-700 p-2">
            <div className="w-full flex items-center space-x-4 tracking-wider">
                <button className="nav-bg p-0.5 rounded-sm border border-gray-800 flex items-center" onClick={() => setOpen(prev => !prev)}>{open ? <RemoveOutlinedIcon fontSize="small"/> : <AddOutlinedIcon fontSize="small"/>}</button>
                <div className="font-light text-gray-300">{modater.name}</div>
            </div>
            <div className={`${open ? 'block' : 'hidden'} flex flex-col space-y-2 px-4 py-2`}>
                {
                    modater.allActivatedMatchesForModater.map(match => {
                        return (
                            <div className="flex items-center mt-2" key={match._id}>
                                <div className="text-sm flex-1">{new Date(match.timeActivated).toDateString()}</div>
                                <div className="text-sm tracking-wide font-light w-16 uppercase flex-1">{modater.sex === 'Male' ? match.girlAlias : match.guyAlias}</div>
                                <div className="flex-1">
                                    {getStatus(match)}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default StatusLine
