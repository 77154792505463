import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import {
  LOCATIONS,
  COMMUNITY,
  HEIGHTS,
  CHARACTERISTICS,
  AGES,
  ACTIVITIES,
  STYLES,
  PERSONALITY_QUESTIONS,
} from "../constants";
import Error from "../Components/utilities/Error";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "../Components/nav.css";
import { TokenContext } from "../Components/utilities/accessTokenContext";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  getAllConnectors,
  getRefreshTokenBeforeApiCall,
} from "../Components/utilities/EndPoints";
import { createTheme, ThemeProvider } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import MyersBriggsIntrovert_Extrovert from "../Components/myersBriggsQuestions/introvertExtrovert";
import MyersBriggsSensing_Intuition from "../Components/myersBriggsQuestions/sensingIntuition";
import MyersBriggsThinking_Feeling from "../Components/myersBriggsQuestions/thinkingFeeling";
import MyersBriggsJudging_Perceiving from "../Components/myersBriggsQuestions/judgingPerceiving";


const ModaterSignUp = ({
  register,
  control,
  trigger,
  errors,
  nextStep,
  data,
  setFocus,
}) => {
  const { token, setLoggedIn, setToken, navigate, user, setUser, loggedIn } =
    useContext(TokenContext);

  // console.log('refresh token', token)
  // console.log(errors)
  // console.log('rendering')
  // console.log(user)
  // console.log(data)

  const [input, setInput] = useState("");
  const _isMounted = useRef(true);
  const [politics, setPolitics] = useState('0');

  const handlePoliticsChange = (value) => {
    setPolitics(value);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: blueGrey[600],
      },
      background: {
        default: blueGrey,
      },
      text: {
        primary: "#d7dadf",
      },
    },
  });

  // const options = data ? data.map(item => {
  //     return {
  //         value: item._id,
  //         label: `${item.firstName} ${item.lastName}`
  //     }
  // }) : []

  const debounce = (func, delay) => {
    let id;
    // console.log('id is', id);
    return (...args) => {
      return new Promise((resolve) => {
        if (id) {
          clearTimeout(id);
        }
        id = setTimeout(async () => {
          const res = await func(...args);
          resolve(res);
        }, delay);
      });
    };
  };

  const loadOptions = (input) => {
    return getRefreshTokenBeforeApiCall(
      token,
      setToken,
      setLoggedIn,
      navigate,
      getAllConnectors,
      [input, _isMounted]
    );
  };

  const debounced = useCallback(debounce(loadOptions, 400), [token]);

  // wont work unless i get the user beforehand
  useEffect(() => {
    console.log(loggedIn, user);
    if (
      loggedIn &&
      user &&
      user.modaterProfileId &&
      window.location.pathname !== "/dashboard/profile/modaterEdit"
    ) {
      console.log("navigating");
      navigate("/");
    }
  }, [loggedIn, user]);

  useEffect(() => {
    const error = Object.keys(errors);
    if (error.length > 0) {
      setFocus(error[0]);
    }
  }, [errors]);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      // console.log('total sign up unmounting')
      _isMounted.current = false;
    };
  }, []);

  // make sure you cant access this route unless you are signed in
  // useEffect(() => {
  //     if (window.location.pathname !== '/dashboard/profile/modaterEdit') {
  //         getRefreshToken(token, setLoggedIn, setToken, navigate);
  //     }
  // }, [])

  // useEffect(() => {
  //     console.log('modaterSignup get Profile')
  //     getRefreshTokenBeforeApiCall(token, setLoggedIn, setToken, navigate, getUser, [setUser])
  // }, [token])

  return (
    <form className="flex w-full justify-center items-center body-bg text-gray-200">
      <div className="flex flex-col w-full lg:w-1/2 xl:w-2/5 h-1/2 justify-center items-center space-y-8 px-10 tracking-wide special">
        <div className="w-full flex">
          <div className="flex flex-col w-full text-sm">
            <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
              First Name
            </label>
            <div className="flex justify-start">
              <input
                {...register("firstName")}
                type="text"
                className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800 w-4/5"
              />
            </div>
            {errors.firstName && (
              <Error errorMessage={errors.firstName.message} />
            )}
          </div>

          <div className="flex flex-col text-sm w-full">
            <label className="flex justify-end mb-2 font-semibold ml-4 text-xs">
              Last Name
            </label>
            <div className="flex justify-end">
              <input
                {...register("lastName")}
                type="text"
                className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800 w-4/5"
              />
            </div>
            {errors.lastName && (
              <Error errorMessage={errors.lastName.message} />
            )}
          </div>
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Phone Number
          </label>
          <input
            {...register("phoneNumber")}
            type="text"
            placeholder="xxx-xxx-xxxx"
            className="py-2 px-4 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800"
          />
          {errors.phoneNumber && (
            <Error errorMessage={errors.phoneNumber.message} />
          )}
        </div>

        <div className="flex flex-col w-full text-sm">
          {/* <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">Date Of Birth</label> */}
          <Controller
            control={control}
            defaultValue={null}
            name="dateOfBirth"
            render={({ field: { onChange, value, ref } }) => (
              <ThemeProvider theme={darkTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date Of Birth"
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            )}
          />
          {errors.dateOfBirth && (
            <Error errorMessage={errors.dateOfBirth.message} />
          )}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Connector Name (You must know a Connector to sign up for MoDate. If
            you don’t know any Connectors then we recommend asking a friend to
            sign up as a Connector.)
          </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={null}
              name="connectorId"
              render={({ field: { onChange, value, ref } }) => (
                <AsyncSelect
                  loadOptions={debounced}
                  placeholder="Search for a Connector"
                  isClearable
                  onInputChange={setInput}
                  value={value}
                  onChange={(val) => onChange(val)}
                  className="w-[300px]"
                  styles={STYLES}
                  isDisabled={
                    user &&
                    user.modaterProfileId &&
                    user.modaterProfileId.connectorId &&
                    window.location.pathname === "/dashboard/profile/modaterEdit"
                  }
                />
              )}
            />
          </div>
          {errors.connectorId && (
            <Error errorMessage={errors.connectorId.message} />
          )}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Location
          </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={null}
              name="state"
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  styles={STYLES}
                  isClearable
                  ref={ref}
                  value={value}
                  onChange={(val) => onChange(val)}
                  options={LOCATIONS.map((location) => {
                    return {
                      value: location,
                      label: location,
                    };
                  })}
                />
              )}
            />
          </div>
          {errors.state && <Error errorMessage={errors.state.message} />}
        </div>

        <div className="flex flex-col text-sm w-full">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            City
          </label>
          <input
            {...register("city")}
            type="text"
            className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800 w-full"
          />
          {errors.city && <Error errorMessage={errors.city.message} />}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Community (Optional)
          </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={null}
              name="community"
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  styles={STYLES}
                  isClearable
                  ref={ref}
                  value={value}
                  onChange={(val) => onChange(val)}
                  options={COMMUNITY.map((community) => {
                    return {
                      value: community,
                      label: community,
                    };
                  })}
                />
              )}
            />
          </div>
          {errors.community && (
            <Error errorMessage={errors.community.message} />
          )}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Where are you willing to date? (You can select multiple places or
            you can leave blank and you will be matched with people from
            anywhere)
          </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={null}
              name="willingToDate"
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  styles={STYLES}
                  isClearable
                  ref={ref}
                  value={value}
                  onChange={(val) => onChange(val)}
                  options={LOCATIONS.map((location) => {
                    return {
                      value: location,
                      label: location,
                    };
                  })}
                  isMulti
                  className="text-black"
                />
              )}
            />
          </div>
          {errors.willingToDate && (
            <Error errorMessage={errors.willingToDate.message} />
          )}
        </div>

        <div className="flex flex-col text-sm w-full">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Profession (write "N/A" if not applicable)
          </label>
          <input
            {...register("vocation")}
            type="text"
            className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800 w-full"
          />
          {errors.vocation && <Error errorMessage={errors.vocation.message} />}
        </div>

        <div className="flex flex-col text-sm w-full">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            High School (write “N/A” if you did not attend)
          </label>
          <input
            {...register("highSchool")}
            type="text"
            className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800 w-full"
          />
          {errors.highSchool && (
            <Error errorMessage={errors.highSchool.message} />
          )}
        </div>

        <div className="flex flex-col text-sm w-full">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            College/University (write “N/A” if you did not attend)
          </label>
          <input
            {...register("college")}
            type="text"
            className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800 w-full"
          />
          {errors.college && <Error errorMessage={errors.college.message} />}
        </div>

        <div className="flex flex-col text-sm w-full">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Yeshiva/Seminary (write “N/A” if you did not attend)
          </label>
          <input
            {...register("israelSchool")}
            type="text"
            className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800 w-full"
          />
          {errors.israelSchool && (
            <Error errorMessage={errors.israelSchool.message} />
          )}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Sex
          </label>
          <div
            {...register("sex")}
            className="flex border border-gray-800 p-2 rounded-md w-full space-x-4 hover:border-gray-700 nav-bg"
          >
            <div className="flex justify-center items-center ml-4 space-x-2">
              <label className="flex justify-start text-sm font-light">
                Male
              </label>
              <input
                {...register("sex")}
                type="radio"
                value="Male"
                className="flex-none"
              />
            </div>
            <div className="flex justify-center items-center space-x-2">
              <label className="flex justify-start text-sm font-light">
                Female
              </label>
              <input
                {...register("sex")}
                type="radio"
                value="Female"
                className="flex-none"
              />
            </div>
          </div>
          {errors.sex && <Error errorMessage={errors.sex.message} />}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Height
          </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={null}
              name="height"
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  styles={STYLES}
                  isClearable
                  ref={ref}
                  value={value}
                  onChange={(val) => onChange(val)}
                  options={Object.keys(HEIGHTS).map((height) => {
                    return {
                      value: height,
                      label: HEIGHTS[height],
                    };
                  })}
                />
              )}
            />
          </div>
          {errors.height && <Error errorMessage={errors.height.message} />}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            What is the shortest height that you're willing to date? (Optional)
          </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={null}
              name="minHeight"
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  styles={STYLES}
                  isClearable
                  ref={ref}
                  value={value}
                  onChange={(val) => onChange(val)}
                  options={Object.keys(HEIGHTS).map((height) => {
                    return {
                      value: height,
                      label: HEIGHTS[height],
                    };
                  })}
                  className="text-black"
                />
              )}
            />
          </div>
          {errors.minHeight && (
            <Error errorMessage={errors.minHeight.message} />
          )}
        </div>

        <div className="w-full">
          <MyersBriggsIntrovert_Extrovert/>
        </div>
        
        <div {...register("IE")} className="flex flex-col w-full space-y-2">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">
            Based on the above, are you an introvert or extrovert?
          </label>
          <div className="flex w-full items-center justify-between border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg">
            <p className="text-xs sm:text-sm">Introvert (I)</p>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("IE")} type="radio" value="1" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                1
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("IE")} type="radio" value="2" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                2
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("IE")} type="radio" value="3" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                3
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("IE")} type="radio" value="4" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                4
              </label>
            </div>
            <p className="text-xs sm:text-sm">Extrovert (E)</p>
          </div>
          {errors.IE && <Error errorMessage={errors.IE.message} />}
        </div>

        <div className="w-full">
          <MyersBriggsSensing_Intuition/>
        </div>

        <div {...register("SN")} className="flex flex-col w-full space-y-2">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">
            Based on the above, how do you prefer to take in information?
          </label>
          <div className="flex w-full items-center justify-between border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg">
            <p className="text-xs sm:text-sm">Sensing (S)</p>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("SN")} type="radio" value="1" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                1
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("SN")} type="radio" value="2" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                2
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("SN")} type="radio" value="3" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                3
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("SN")} type="radio" value="4" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                4
              </label>
            </div>
            <p className="text-xs sm:text-sm">Intuition (N)</p>
          </div>
          {errors.SN && <Error errorMessage={errors.SN.message} />}
        </div>

        <div className="w-full">
          <MyersBriggsThinking_Feeling/>
        </div>

        <div {...register("TF")} className="flex flex-col w-full space-y-2">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">
            Based on the above, how do you prefer to make decisions?
          </label>
          <div className="flex w-full items-center justify-between border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg">
            <p className="text-xs sm:text-sm">Thinking (T)</p>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("TF")} type="radio" value="1" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                1
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("TF")} type="radio" value="2" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                2
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("TF")} type="radio" value="3" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                3
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("TF")} type="radio" value="4" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                4
              </label>
            </div>
            <p className="text-xs sm:text-sm">Feeling (F)</p>
          </div>
          {errors.TF && <Error errorMessage={errors.TF.message} />}
        </div>

        <div className="w-full">
          <MyersBriggsJudging_Perceiving/>
        </div>

        <div {...register("JP")} className="flex flex-col w-full space-y-2">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">
            Based on the above, how do you prefer to live your outer life?
          </label>
          <div className="flex w-full items-center justify-between border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg">
            <p className="text-xs sm:text-sm">Judging (J)</p>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("JP")} type="radio" value="1" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                1
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("JP")} type="radio" value="2" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                2
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("JP")} type="radio" value="3" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                3
              </label>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <input {...register("JP")} type="radio" value="4" />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                4
              </label>
            </div>
            <p className="text-xs sm:text-sm">Perceiving (P)</p>
          </div>
          {errors.JP && <Error errorMessage={errors.JP.message} />}
        </div>

        <div className="w-full flex flex-col">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Which of these 3 characteristics best describe you?
          </label>
          <div className="flex border border-gray-800 flex-wrap w-full items-start p-3 rounded-md hover:border-gray-700 nav-bg">
            {CHARACTERISTICS.map((characteristic, index) => {
              return (
                <div
                  className="flex justify-center items-center space-x-2 m-4"
                  key={index}
                >
                  <input
                    type="checkbox"
                    value={characteristic}
                    {...register("characteristics")}
                    className="h-4 w-4 flex-none"
                  />
                  <label className="flex justify-start font-light ml-4 text-xs">
                    {characteristic}
                  </label>
                </div>
              );
            })}
          </div>
          {errors.characteristics && (
            <Error errorMessage={errors.characteristics.message} />
          )}
        </div>

        <div className="w-full flex flex-col">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Which of these 3 characteristics are most important to you in a
            match?
          </label>
          <div className="flex border border-gray-800 flex-wrap w-full items-start p-3 rounded-md hover:border-gray-700 nav-bg">
            {CHARACTERISTICS.map((characteristic, index) => {
              return (
                <div
                  className="flex justify-center items-center space-x-2 m-4"
                  key={index}
                >
                  <input
                    type="checkbox"
                    value={characteristic}
                    {...register("characteristicsInMatch")}
                    className="h-4 w-4 flex-none"
                  />
                  <label className="flex justify-start font-light ml-4 text-xs">
                    {characteristic}
                  </label>
                </div>
              );
            })}
          </div>
          {errors.characteristicsInMatch && (
            <Error errorMessage={errors.characteristicsInMatch.message} />
          )}
        </div>

        <div className="flex flex-col w-full border border-gray-800 rounded-md p-4 hover:border-gray-700 nav-bg space-y-4">
   <div className=" justify-start items-center space-x-4">
   <label htmlFor="politics" className="text-sm font-light text-grey-darkest">
   Select your political preference:</label>
     <input
       type="range"
       min="0"
       max="5"
       step="1"
       value={politics}
       {...register("politics")}
       onChange={(e) => handlePoliticsChange(e.target.value)}
       className="flex-none"
     />
     <label className="flex justify-start text-sm font-light text-grey-darkest">
       { politics === '0' ? 'Liberal' :
         politics === '1' ? 'More Often Liberal' :
         politics === '2' ? 'Moderate' :
         politics === '3' ? 'More Often Conservative' :
         politics === '4' ? 'Conservative' :
         'Prefer Not to Answer'}
     </label>
   </div>
   {/* ... other options ... */}
 </div>


        <div className="w-full border border-gray-800 p-3 rounded-md space-y-2 hover:border-gray-700 nav-bg">
          <div
            {...register("kosher")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-start font-semibold ml-4 text-xs underline">
              Kosher
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("kosher")}
                  type="radio"
                  value="Yes"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Yes
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("kosher")}
                  type="radio"
                  value="Dairy Out"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Dairy Out
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("kosher")}
                  type="radio"
                  value="No"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  No
                </label>
              </div>
            </div>
            {errors.kosher && <Error errorMessage={errors.kosher.message} />}
          </div>

          <div
            {...register("shabbos")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-start font-semibold ml-4 text-xs underline">
              Shabbos
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shabbos")}
                  type="radio"
                  value="Yes"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Yes
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shabbos")}
                  type="radio"
                  value="It's Complicated"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  It's Complicated
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shabbos")}
                  type="radio"
                  value="No"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  No
                </label>
              </div>
            </div>
            {errors.shabbos && <Error errorMessage={errors.shabbos.message} />}
          </div>

          <div
            {...register("shomerNegiah")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-startfont-semibold ml-4 text-xs underline">
              Shomer Negiah
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shomerNegiah")}
                  type="radio"
                  value="Yes"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Yes
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shomerNegiah")}
                  type="radio"
                  value="Start Shomer"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Start Shomer
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shomerNegiah")}
                  type="radio"
                  value="No"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  No
                </label>
              </div>
            </div>
            {errors.shomerNegiah && (
              <Error errorMessage={errors.shomerNegiah.message} />
            )}
          </div>

          <div
            {...register("learns")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-start font-semibold ml-4 text-xs underline">
              Do you learn consistently?
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("learns")}
                  type="radio"
                  value="Yes"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Yes
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("learns")}
                  type="radio"
                  value="Here And There"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Here And There
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("learns")}
                  type="radio"
                  value="No"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  No
                </label>
              </div>
            </div>
            {errors.learns && <Error errorMessage={errors.learns.message} />}
          </div>

          <div
            {...register("aliyah")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-start font-semibold ml-4 text-xs underline">
              Aliyah? (if you live in Israel select "Yes")
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("aliyah")}
                  type="radio"
                  value="Yes"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Yes
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("aliyah")}
                  type="radio"
                  value="Maybe"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Maybe
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("aliyah")}
                  type="radio"
                  value="No"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  No
                </label>
              </div>
            </div>
            {errors.aliyah && <Error errorMessage={errors.aliyah.message} />}
          </div>
        </div>

        <div className="w-full flex justify-center mb-2 font-semibold text-sm">
          Religious Observance Match
        </div>

        <div className="w-full border border-gray-800 p-3 rounded-md space-y-2 hover:border-gray-700 nav-bg">
          <div
            {...register("kosherMatch")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-start font-semibold ml-4 text-xs underline">
              Keeps strict Kosher?
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("kosherMatch")}
                  type="radio"
                  value="Very Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Very Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("kosherMatch")}
                  type="radio"
                  value="Somewhat Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Somewhat Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("kosherMatch")}
                  type="radio"
                  value="Not Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Not Important
                </label>
              </div>
            </div>
            {errors.kosherMatch && (
              <Error errorMessage={errors.kosherMatch.message} />
            )}
          </div>

          <div
            {...register("shabbosMatch")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-start font-semibold ml-4 text-xs underline">
              Keeps Shabbos?
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shabbosMatch")}
                  type="radio"
                  value="Very Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Very Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shabbosMatch")}
                  type="radio"
                  value="Somewhat Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Somewhat Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shabbosMatch")}
                  type="radio"
                  value="Not Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Not Important
                </label>
              </div>
            </div>
            {errors.shabbosMatch && (
              <Error errorMessage={errors.shabbosMatch.message} />
            )}
          </div>

          <div
            {...register("shomerNegiahMatch")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-startfont-semibold ml-4 text-xs underline">
              Is Shomer Negiah?
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shomerNegiahMatch")}
                  type="radio"
                  value="Very Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Very Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shomerNegiahMatch")}
                  type="radio"
                  value="Somewhat Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Somewhat Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("shomerNegiahMatch")}
                  type="radio"
                  value="Not Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Not Important
                </label>
              </div>
            </div>
            {errors.shomerNegiahMatch && (
              <Error errorMessage={errors.shomerNegiahMatch.message} />
            )}
          </div>

          <div
            {...register("learnsMatch")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-start font-semibold ml-4 text-xs underline">
              Learns consistently throughout the week?
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("learnsMatch")}
                  type="radio"
                  value="Very Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Very Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("learnsMatch")}
                  type="radio"
                  value="Somewhat Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Somewhat Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("learnsMatch")}
                  type="radio"
                  value="Not Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Not Important
                </label>
              </div>
            </div>
            {errors.learnsMatch && (
              <Error errorMessage={errors.learnsMatch.message} />
            )}
          </div>

          <div
            {...register("aliyahMatch")}
            className="flex flex-col w-full space-y-2"
          >
            <label className="flex justify-start font-semibold ml-4 text-xs underline">
              Aliyah? (if you live in Israel select "Very Important")
            </label>
            <div className="flex w-full items-center justify-between p-3 body-bg">
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("aliyahMatch")}
                  type="radio"
                  value="Very Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Very Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("aliyahMatch")}
                  type="radio"
                  value="Somewhat Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Somewhat Important
                </label>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <input
                  {...register("aliyahMatch")}
                  type="radio"
                  value="Not Important"
                  className="flex-none"
                />
                <label className="flex justify-start text-sm font-light text-grey-darkest">
                  Not Important
                </label>
              </div>
            </div>
            {errors.aliyahMatch && (
              <Error errorMessage={errors.aliyahMatch.message} />
            )}
          </div>
        </div>

        <div
          {...register("relationToJudaism")}
          className="flex flex-col w-full space-y-2"
        >
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">
            How do you best relate to/identify with Judaism?
          </label>
          <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-4">
            <div className="flex justify-start items-center space-x-4">
              <input
                {...register("relationToJudaism")}
                type="radio"
                value="Community"
                className="flex-none"
              />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                Community - I love the fact that Judaism has so many communal
                aspects and provides me with a built-in community
              </label>
            </div>
            <div className="flex justify-start items-center space-x-4">
              <input
                {...register("relationToJudaism")}
                type="radio"
                value="Virtuous Ideology"
                className="flex-none"
              />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                Virtuous Ideology - I believe that Judaism is simply the correct
                religion and identify strongly with our principles
              </label>
            </div>
            <div className="flex justify-start items-center space-x-4">
              <input
                {...register("relationToJudaism")}
                type="radio"
                value="Discipline"
                className="flex-none"
              />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                Discipline - Living a Judaic lifestyle leads me to live a more
                disciplined and productive lifestyle
              </label>
            </div>
            <div className="flex justify-start items-center space-x-4">
              <input
                {...register("relationToJudaism")}
                type="radio"
                value="Tradition"
                className="flex-none"
              />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                Tradition - I resonate the most with the fact that Judaism has
                been around for thousands of years and love that our traditions
                have been passed down from generation to generation
              </label>
            </div>
          </div>
          {errors.relationToJudaism && (
            <Error errorMessage={errors.relationToJudaism.message} />
          )}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Were you previously married?
          </label>
          <div
            {...register("divorcedStatus")}
            className="flex border border-gray-800 p-2 rounded-md w-full space-x-4 hover:border-gray-700 nav-bg"
          >
            <div className="flex justify-center items-center ml-4 space-x-2">
              <label className="flex justify-start text-sm font-light">
                Yes
              </label>
              <input
                {...register("divorcedStatus")}
                type="radio"
                value="Yes"
                className="flex-none"
              />
            </div>
            <div className="flex justify-center items-center space-x-2">
              <label className="flex justify-start text-sm font-light">
                No
              </label>
              <input
                {...register("divorcedStatus")}
                type="radio"
                value="No"
                className="flex-none"
              />
            </div>
          </div>
          {errors.divorcedStatus && (
            <Error errorMessage={errors.divorcedStatus.message} />
          )}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Do you have children?
          </label>
          <div
            {...register("childrenStatus")}
            className="flex border border-gray-800 p-2 rounded-md w-full space-x-4 hover:border-gray-700 nav-bg"
          >
            <div className="flex justify-center items-center ml-4 space-x-2">
              <label className="flex justify-start text-sm font-light">
                Yes
              </label>
              <input
                {...register("childrenStatus")}
                type="radio"
                value="Yes"
                className="flex-none"
              />
            </div>
            <div className="flex justify-center items-center space-x-2">
              <label className="flex justify-start text-sm font-light">
                No
              </label>
              <input
                {...register("childrenStatus")}
                type="radio"
                value="No"
                className="flex-none"
              />
            </div>
          </div>
          {errors.childrenStatus && (
            <Error errorMessage={errors.childrenStatus.message} />
          )}
        </div>

        <div className="w-full flex space-x-4 items-end">
          <div className="flex flex-col w-full text-sm">
            <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
              Youngest age willing to date?
            </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={null}
                name="youngestAge"
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    styles={STYLES}
                    isClearable
                    ref={ref}
                    value={value}
                    onChange={(val) => onChange(val)}
                    options={AGES.map((age) => {
                      return {
                        value: age,
                        label: age,
                      };
                    })}
                    className="text-black"
                  />
                )}
              />
            </div>
            {errors.youngestAge && (
              <Error errorMessage={errors.youngestAge.message} />
            )}
          </div>

          <div className="flex flex-col w-full text-sm">
            <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
              Oldest age willing to date?
            </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={null}
                name="oldestAge"
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    styles={STYLES}
                    isClearable
                    ref={ref}
                    value={value}
                    onChange={(val) => onChange(val)}
                    options={AGES.map((age) => {
                      return {
                        value: age,
                        label: age,
                      };
                    })}
                    className="text-black"
                  />
                )}
              />
            </div>
            {errors.oldestAge && (
              <Error errorMessage={errors.oldestAge.message} />
            )}
          </div>
        </div>

        <div className="w-full flex flex-col">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Which 3 activities do you enjoy the most?
          </label>
          <div className="flex border border-gray-800 flex-wrap w-full items-start p-3 rounded-md hover:border-gray-700 nav-bg">
            {ACTIVITIES.map((activity, index) => {
              return (
                <div
                  className="flex justify-center items-center space-x-2 m-4"
                  key={index}
                >
                  <input
                    type="checkbox"
                    value={activity}
                    {...register("activities")}
                    className="h-4 w-4 flex-none"
                  />
                  <label className="flex justify-start font-light ml-4 text-xs">
                    {activity}
                  </label>
                </div>
              );
            })}
          </div>
          {errors.activities && (
            <Error errorMessage={errors.activities.message} />
          )}
        </div>

        <div
          {...register("vacationPreference")}
          className="flex flex-col w-full space-y-2"
        >
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs ">
            Where would you prefer to go on vacation?
          </label>
          <div className="flex flex-col w-full border border-gray-800 rounded-md p-3 hover:border-gray-700 nav-bg space-y-4">
            <div className="flex justify-start items-center space-x-4">
              <input
                {...register("vacationPreference")}
                type="radio"
                value="A New City You’ve Never Visited"
                className="flex-none"
              />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                A new city I’ve never visited
              </label>
            </div>
            <div className="flex justify-start items-center space-x-4">
              <input
                {...register("vacationPreference")}
                type="radio"
                value="Somewhere Warm and/or Tropical"
                className="flex-none"
              />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                Somewhere warm and/or tropical
              </label>
            </div>
            <div className="flex justify-start items-center space-x-4">
              <input
                {...register("vacationPreference")}
                type="radio"
                value="Somewhere Cold for Skiing/Snowboarding"
                className="flex-none"
              />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                Somewhere cold for skiing/snowboarding
              </label>
            </div>
            <div className="flex justify-start items-center space-x-4">
              <input
                {...register("vacationPreference")}
                type="radio"
                value="National Parks/Roadtrips"
                className="flex-none"
              />
              <label className="flex justify-start text-sm font-light text-grey-darkest">
                National parks/roadtrips
              </label>
            </div>
          </div>
          {errors.vacationPreference && (
            <Error errorMessage={errors.vacationPreference.message} />
          )}
        </div>

        <div className="flex flex-col w-full text-sm">
          <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">
            Bio - Using your name in your bio is completely up to your
            preference. If you’d like the Connector of your match to be able to
            identify you to potentially avoid matching with people you know then
            we recommend including your name. However, if you want to remain as
            anonymous as possible from other Connectors, we recommend using your
            first initial or writing your bio in the first person. We strongly
            recommend including more information with regards to your hashkafa
            and personality in your bio. (Minimum 50 Characters)
          </label>
          <textarea
            {...register("bio")}
            className="w-full outline-none border-gray-800 rounded-md h-40 p-4 nav-bg"
          />
          {errors.bio && <Error errorMessage={errors.bio.message} />}
        </div>

        <div className="flex flex-col w-full py-2">
          <button
            className="uppercase p-3 font-light hover:bg-black hover:text-white text-xs rounded-full w-full mb-20 nav-bg"
            type="button"
            onClick={async () => {
              const valid = await trigger();
              if (valid) {
                nextStep();
              }
            }}
          >
            Continue To Next Step
          </button>
        </div>
      </div>
    </form>
  );
};

export default ModaterSignUp;
