export const getRelationToJudaism = (community) => {
    switch(community) {
        case "Community":
            return <div className="text-base text-gray-300 font-light overflow-y-scroll tracking-wide">Community - I Love the Fact that Judaism Has So Many Communal Aspects and Provides Me With a Built-in Community</div> 
        case "Virtuous Ideology":
            return <div className="text-base text-gray-300 font-light overflow-y-scroll tracking-wide">Virtuous Ideology - I Believe that Judaism is Simply the Correct Religion and Identify Strongly With Our Principles</div> 
        case "Discipline":
            return <div className="text-base text-gray-300 font-light overflow-y-scroll tracking-wide">Discipline - Living a Judaic Lifestyle Leads Me to Live a More Disciplined and Productive Lifestyle</div> 
        case "Tradition":
            return <div className="text-base text-gray-300 font-light overflow-y-scroll tracking-wide">Tradition - I Resonate the Most With the Fact that Judaism Has Been Around For Thousands of Years and Love that Our Traditions Have Been Passed Down From Generation to Generation</div> 
        default:
            return <div></div>
    }
}