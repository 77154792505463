import React from 'react';

const DropDownItem = ({name, status, setOpen, setSelected, selected}) => {

    const dropDownHandler = (e, status) => {
        e.stopPropagation();
        setOpen(prev => !prev);
        setSelected(status);
    }

    return <li className={`text-gray-300 px-6 w-[170px] py-3 hover:bg-gray-800 hover:cursor-pointer hover:text-blue-300 ${status === selected ? 'text-blue-400' : ''}`} onClick={(e) => dropDownHandler(e, status)}>{name}</li>
}

export default DropDownItem