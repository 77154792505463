import React, { useContext, useState } from 'react'
// import { Link } from 'react-router-dom';
import Select from 'react-select';
import MatchCard from '../Components/MatchCard';
import MatchModal from '../Components/Modal/ConnectorCreateMatchModal/MatchModal';
import { ConnectorDashboardContext } from '../Components/utilities/connectorDashboardContext';
import ExtraInfoView from '../Components/ExtraInfoView';
import { STYLES } from '../constants';
import AddIcon from '@mui/icons-material/Add';
import ToolTip from '../Components/ToolTip';
import { TokenContext } from '../Components/utilities/accessTokenContext';

function Matches() {

    const { unreviewedMatches, setUnreviewedMatches, isVerifiedConnector } = useContext(ConnectorDashboardContext);
    const { modaters } = useContext(TokenContext);
    const [selected, setSelected] = useState();
    const [cardIndex, setCardIndex] = useState(0);
    const [curImageIndex, setCurImageIndex] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [modal, setModal] = useState(false);

    const changeSelected = (val) => {
        setSelected(val);
        setCardIndex(0);
    }

    const handleButtonClick = (e) => {
        console.log(e)
        e.stopPropagation();
        setModal(prev => !prev);
    }

    const matchesForSelected = selected ? unreviewedMatches.filter(matches => matches._id === selected.value)[0] : null;
    const property = matchesForSelected && matchesForSelected.sex === 'Male' ? 'girlModaterId' : 'guyModaterId';
    const selectedMatch = matchesForSelected && matchesForSelected.matches[cardIndex];
    const connectorOfMatch = selectedMatch?.connectorsId.filter(connector => connector?._id !== matchesForSelected?.connectorId)[0];
    
    // console.log(matchesForSelected)
    // console.log(property)
    // console.log(selectedMatch)
    // console.log(connectorOfMatch)
    // console.log(unreviewedMatches)

    return (
        <>
        { modal && 
            <MatchModal setModal={setModal}/>
        }
        <div className="w-full flex flex-col lg:flex-row items-center p-4 md:p-8 lg:space-x-8 space-y-12 lg:space-y-0 relative">
            <div className="h-full w-full sm:w-1/2 flex flex-col items-center justify-start space-y-8">
                <Select 
                    styles={STYLES}
                    value={selected}
                    isClearable
                    options={unreviewedMatches ? unreviewedMatches.map(modater => ({label: modater.name, value: modater._id})) : []}
                    onChange={changeSelected}
                    className="text-black w-full cardWidth text-sm"
                    placeholder="Please select one of your MoDaters to start matching for"
                />
                { unreviewedMatches &&
                    <div className="w-full h-full flex flex-col items-center justify-center">
                        { matchesForSelected && connectorOfMatch ?
                            <MatchCard 
                                user={matchesForSelected} 
                                cardIndex={cardIndex}
                                setCardIndex={setCardIndex}
                                property={property}
                                selectedMatch={selectedMatch}
                                connectorOfMatch={connectorOfMatch}
                                setSelected={setSelected}
                                setUnreviewedMatches={setUnreviewedMatches}
                                disabled={disabled}
                                setDisabled={setDisabled}
                                urls={selectedMatch[property].photoUrls}
                                curImageIndex={curImageIndex}
                                setCurImageIndex={setCurImageIndex}
                            /> :
                            <div className="text-gray-400 text-center">Please Select One Of Your MoDaters To Start Matching For</div>
                        }
                    </div>
                }
            </div>
            { unreviewedMatches && selectedMatch &&
                <div className="w-full sm:w-1/2 text-gray-300 mb-16 md:mb-0">
                    <div className="text-sm font-semibold tracking-wider mb-6 font-sans md:text-center xl:text-left text-blue-200 uppercase">What the Connector had to say about {`${selectedMatch[property].alias}`}</div>
                    <ExtraInfoView modater={selectedMatch[property]} />
                </div>
            }
            { modaters?.length >= 3 &&
                <ToolTip title="Create Match">
                    <button className={`bg-blue-400 text-black rounded-full p-3 fixed bottom-8 left-8 sm:left-72 shadow-md shadow-black hover:scale-110 transition duration-500 hover:text-white  ${selected ? 'hidden' : ''}`} onClick={handleButtonClick}>
                        <AddIcon fontSize="large"/>
                    </button>
                </ToolTip>
            }
        </div>
        </>
    )
}

export default Matches
