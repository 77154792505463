import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import './nav.css'

function SuccessBanner({ message, setSuccess }) {
    useEffect(() => {
        const timeId = setTimeout(() => {
            setSuccess(null);
        }, 2000)

        return () => {
            clearTimeout(timeId)
        }
    }, [message]);

    return (
        <div className="fixed top-[5.5rem] lg:ml-36 left-1/2 transform -translate-x-1/2 bg-green-400 px-4 py-1 text-xs rounded-sm tracking-wider animation z-20 shadow-xl shadow-black/60">
            <div>{message}</div>
        </div>
    )
}

export default SuccessBanner
