import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import Error from './utilities/Error';
import { QUANTITY, PERIOD, STYLES, REASONS } from '../constants';
import './nav.css'
import { breakUpWithMatch, getRefreshTokenBeforeApiCall } from './utilities/EndPoints';
import { TokenContext } from './utilities/accessTokenContext';

function BreakUpForm({ setShowForm }) {

    const { token, setToken, setLoggedIn, navigate, setError, setUser, setSuccess } = useContext(TokenContext);
    const [time, setTime] = useState();
    const [disabled, setDisabled] = useState();

    const schema = yup.object().shape({
        quantity: yup.object().required('please choose an option').nullable(),
        period: yup.object().required('please choose an option').nullable(),
        reason: yup.object().when("quantity", {
            is: quantity => {console.log(quantity?.value === '0'); return quantity?.value === '0'},
            then: yup.object().required("Please choose a reason").nullable()
        }).nullable()
    })

    const { handleSubmit, control, setValue, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const submitForm = data => {
        console.log(data)
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, breakUpWithMatch, [{period: `${data.quantity.value} ${data.period.value}`, reason: data.reason?.value || null}, setUser, setDisabled, setError, setSuccess])
    }

    return (
        <div className="w-full h-full absolute flex justify-center items-center z-10 bg-black bg-opacity-50">
            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} className="w-72 sm:w-96 relative ">
                <button className="absolute z-10 top-4 right-4 hover:text-gray-500 text-gray-300" onClick={(e) => {
                    e.stopPropagation()
                    setShowForm(prev => !prev)
                }}><CloseIcon /></button>
                <div className="flex flex-col sidebar-bg rounded-lg p-4 space-y-6 ring-2 ring-gray-700 ring-opacity-20 text-gray-300">
                    <div className="text-2xl text-center font-sans tracking-widest underline text-white">End Match</div>
                    <div className="text-sm tracking-wider mt-6 font-sans p-2">We are sorry to hear that you want to end things with your match. If you wouldn't mind, please fill out this short form before continuing.</div>
                    <div className="w-full flex space-x-4">
                        <div className="flex flex-col w-full h-full text-sm">
                            <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">Quantity of time</label>
                            <div className='w-full'>
                                <Controller
                                    control={control}
                                    defaultValue={null}
                                    name="quantity"
                                    render={({ field: { onChange, value, ref }}) => (
                                        <Select
                                            styles={STYLES}
                                            isClearable
                                            ref={ref}
                                            value={value}
                                            onChange={val => {
                                                setValue('reason', null)
                                                setTime(val?.value)
                                                onChange(val)
                                            }}
                                            options={QUANTITY.map(quantity => {
                                                return {
                                                    value: quantity,
                                                    label: quantity
                                                }
                                            })}
                                            className="text-black"
                                        />
                                    )}
                                />
                            </div>
                            {errors.quantity && <Error errorMessage={ errors.quantity.message } /> }
                        </div >
                        <div className="flex flex-col w-full h-full text-sm">
                            <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">Period of time</label>
                            <div className='w-full'>
                                <Controller
                                    control={control}
                                    defaultValue={null}
                                    name="period"
                                    render={({ field: { onChange, value, ref }}) => (
                                        <Select
                                            styles={STYLES}
                                            isClearable
                                            ref={ref}
                                            value={value}
                                            onChange={val => onChange(val)}
                                            options={PERIOD.map(period => {
                                                return {
                                                    value: period,
                                                    label: period
                                                }
                                            })}
                                            className="text-black"
                                        />
                                    )}
                                />
                            </div>
                            {errors.period && <Error errorMessage={ errors.period.message } /> }
                        </div>
                    </div>
                    { time === '0' && 
                        <div className="flex flex-col w-full h-full text-sm">
                            <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">Reason for not going on Date</label>
                            <Controller
                                control={control}
                                defaultValue={null}
                                name="reason"
                                render={({ field: { onChange, value, ref }}) => (
                                    <Select
                                        styles={STYLES}
                                        isClearable
                                        ref={ref}
                                        value={value}
                                        onChange={val => onChange(val)}
                                        options={REASONS.map(reason => {
                                            return {
                                                value: reason,
                                                label: reason
                                            }
                                        })}
                                        className="text-black"
                                    />
                                )}
                            />
                            {errors.reason && <Error errorMessage={ errors.reason.message } /> }
                        </div>
                    }
                    <div className="w-full flex justify-center items-center space-x-8">
                        <button className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800" onClick={() => setShowForm(prev => !prev)}>Cancel</button>
                        <button className="text-sm nav-bg px-3 py-1 rounded-sm text-red-400 border border-gray-800" onClick={handleSubmit(submitForm)}>Submit Form</button>
                    </div>
                </div> 
            </motion.div>
        </div>
    )
}

export default BreakUpForm
