import React from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const ToolTip = styled(({ className, ...props }) => (
        <Tooltip {...props} onClick={()=> {}} placement="top" arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#141018',
            color: '#9ca3af',
            boxShadow: '#fff',
            fontSize: 14,
            padding: '8px 8px',
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#141018',
        },
    }))

export default ToolTip