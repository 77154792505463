import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Error from '../Components/utilities/Error';
import { Controller } from 'react-hook-form';
import { LOCATIONS, STYLES } from '../constants';
import Select from 'react-select';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import { getRefreshToken, getRefreshTokenBeforeApiCall, getUser, registerConnector } from '../Components/utilities/EndPoints';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material';
import { blueGrey, } from '@mui/material/colors';
import '../Components/nav.css'

const schema = yup.object().shape({
    firstName: yup.string().test('alphabets', 'No numbers in Names Allowed', (value) => {
        return /^[A-Za-z]*$/.test(value);
    }).required('Please enter a First Name'),
    lastName: yup.string().test('alphabets', 'No numbers in Names Allowed', (value) => {
        return /^[A-Za-z]*$/.test(value);
    }).required('Please enter a Last Name'),
    phoneNumber: yup.string().test('numbers', 'please Enter Phone Number in the format xxx-xxx-xxxx', (value) => {
        return /^(\+\d{1,2})?[-]*\d{3}[-]*\d{3}[-]*\d{4}$/.test(value)
    }),
    // sex: yup.string().required('You need to choose an option').nullable()
    discovery: yup.string().required('You need to choose an option').nullable(),
    dateOfBirth: yup.date().min(new Date('01/01/1900'), 'Date must be later than 1900').typeError('Please enter a valid date in the format mm/dd/yyyy').required(),
    hashkafa: yup.string().required('You need to choose an option').nullable(),
    purposeForSignUp: yup.string().required('You need to choose an option').nullable(),
    state: yup.object().required('please choose a Location').nullable()
})

const ConnectorSignUp = () => {

    const { token, setLoggedIn, setToken, navigate, user, setUser, setError } = useContext(TokenContext);
    const [disabled, setDisabled] = useState(false);

    const { register, handleSubmit, control, reset, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const submitForm = async data => {
        getRefreshTokenBeforeApiCall(token, setLoggedIn, setToken, navigate, registerConnector, [data, setError, setDisabled, navigate])
    }

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: blueGrey[600],
            },
            background: {
                default: blueGrey,
            },
            text: {
                primary: '#d7dadf',
            },
        }
    });

    useEffect(() => {
        // console.log('CHECKING IF LOGGED IN')
        getRefreshToken(token, setLoggedIn, setToken, navigate)
    }, [])

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getUser, [setUser]);
    }, [token])

    useEffect(() => {
        if (user?.modaterProfileId) {
            reset({
                firstName: user?.modaterProfileId.firstName,
                lastName: user?.modaterProfileId.lastName,
                phoneNumber: user?.modaterProfileId.phoneNumber,
                dateOfBirth: user?.modaterProfileId.dateOfBirth,
                state: {label: user?.modaterProfileId.state, value: user?.modaterProfileId.state}
            })
        }
    }, [user])

    return (
        <form onSubmit={ handleSubmit(submitForm) } className="flex justify-center items-center py-12">
            <div className="flex flex-col w-full sm:w-1/2 lg:w-2/5 justify-center items-center space-y-6 bg-transparent text-gray-200 px-8 tracking-wide">
                <div className="mb-4 text-2xl font-light">Connector Sign Up</div>
                <div className="flex flex-col w-full text-sm">
                    <label className="flex justify-start mb-2 font-light ml-4">First Name</label>
                    <input { ...register('firstName') } type="text" className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800" disabled={user?.modaterProfileId}/>
                    {errors.firstName && <Error errorMessage={ errors.firstName.message }/>}
                </div>
                <div className="flex flex-col w-full text-sm">
                    <label className="flex justify-start mb-2 font-light ml-4">Last Name</label>
                    <input { ...register('lastName') } type="text" className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800" disabled={user?.modaterProfileId}/>
                    {errors.lastName && <Error errorMessage={ errors.lastName.message } /> }
                </div>
                <div className="flex flex-col w-full text-sm">
                    <label className="flex justify-start mb-2 font-light ml-4">Phone Number</label>
                    <input { ...register('phoneNumber') } type="text" placeholder="xxx-xxx-xxxx" className="py-2 px-6 outline-none hover:bg-gray-800 nav-bg rounded-md border border-gray-800" disabled={user?.modaterProfileId}/>
                    {errors.phoneNumber && <Error errorMessage={ errors.phoneNumber.message } /> }
                </div>
                <div className="flex flex-col w-full text-sm">
                    {/* <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">Date Of Birth</label> */}
                    <Controller
                            control={control}
                            defaultValue={null}
                            name="dateOfBirth"
                            render={({ field: { onChange, value, ref }}) => (
                                <ThemeProvider theme={darkTheme}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Date Of Birth"
                                            inputFormat="MM/dd/yyyy"
                                            value={value}
                                            inputRef={ref}
                                            onChange={onChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>
                            )}
                        />
                    {errors.dateOfBirth && <Error errorMessage={ errors.dateOfBirth.message } /> }
                </div>
                <div className="flex flex-col w-full text-sm">
                    <label className="flex justify-start mb-2 font-light ml-4">How Did You Hear About MoDate?</label>
                    <div {...register('discovery')} className="flex flex-col border border-gray-800 w-full rounded-md space-y-2 py-2 nav-bg hover:border-gray-700">
                        <div className="flex flex-col w-full items-start space-y-2 ml-4">
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("discovery")} type="radio" value="Friends" />
                                <label className="flex justify-start text-sm font-light">Friends</label>
                            </div>
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("discovery")} type="radio" value="Family" />
                                <label className="flex justify-start text-sm font-light">Family</label>
                            </div>
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("discovery")} type="radio" value="Social Media" />
                                <label className="flex justify-start text-sm font-light">Social Media</label>
                            </div>
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("discovery")} type="radio" value="Other" />
                                <label className="flex justify-start text-sm font-light">Other</label>
                            </div>
                        </div>
                        {errors.discovery && <Error errorMessage={ errors.discovery.message } /> }
                    </div>
                </div>
                <div className="flex flex-col w-full text-sm">
                    <label className="flex justify-start mb-2 font-light ml-4">Which Best Describes You Hashkafically?</label>
                    <div {...register("hashkafa")} className="flex flex-col border border-gray-800 w-full rounded-md space-y-2 py-2 nav-bg hover:border-gray-700">
                        
                        <div className="flex flex-col w-full items-start space-y-2 ml-4">
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("hashkafa")} type="radio" value="Chabad" />
                                <label className="flex justify-start text-sm font-light">Chabad</label>
                            </div>

                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("hashkafa")} type="radio" value="Conservadox" />
                                <label className="flex justify-start text-sm font-light">Conservadox</label>
                            </div>

                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("hashkafa")} type="radio" value="Dati Leumi" />
                                <label className="flex justify-start text-sm font-light">Dati Leumi</label>
                            </div>

                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("hashkafa")} type="radio" value="Modern Orthodox (Liberal)" />
                                <label className="flex justify-start text-sm font-light">Modern Orthodox (Liberal)</label>
                            </div>
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("hashkafa")} type="radio" value="Modern Orthodox (Middle of the Road)" />
                                <label className="flex justify-start text-sm font-light">Modern Orthodox (Middle of the Road)</label>
                            </div>
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("hashkafa")} type="radio" value="Modern Orthodox (Machmir)" />
                                <label className="flex justify-start text-sm font-light">Modern Orthodox (Machmir)</label>
                            </div>
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("hashkafa")} type="radio" value="Yeshivish" />
                                <label className="flex justify-start text-sm font-light">Yeshivish</label>
                            </div>
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("hashkafa")} type="radio" value="None of the above" />
                                <label className="flex justify-start text-sm font-light">None of the above</label>
                            </div>
                        </div>
                        {errors.hashkafa && <Error errorMessage={ errors.hashkafa.message } /> }
                    </div>
                </div>
                <div className="flex flex-col w-full text-sm">
                    <label className="flex justify-start mb-2 font-light ml-4">How Do You Plan on Using MoDate?</label>
                    <div {...register("purposeForSignUp")} className="flex flex-col border border-gray-800 w-full rounded-md space-y-2 py-2 nav-bg hover:border-gray-700">
                        <div className="flex flex-col w-full items-start space-y-2 ml-4">
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("purposeForSignUp")} type="radio" value="Just As a Connector" />
                                <label className="flex justify-start text-sm font-light">Just As a Connector</label>
                            </div>
                            <div className="flex justify-center items-center space-x-2">
                                <input {...register("purposeForSignUp")} type="radio" value="As Both a Connector and a MoDater" />
                                <label className="flex justify-start text-sm font-light">As Both a Connector and a MoDater</label>
                            </div>
                        </div>
                        {errors.purposeForSignUp && <Error errorMessage={ errors.purposeForSignUp.message } /> }
                    </div>
                </div>
                <div className="flex flex-col w-full text-sm">
                    <label className="flex justify-start mb-2 font-semibold ml-4 text-xs">Where Are You From?</label>
                    <div className='w-full'>
                        <Controller
                            control={control}
                            defaultValue={null}
                            name="state"
                            render={({ field: { onChange, value, ref }}) => (
                                <Select
                                    styles={STYLES}
                                    isClearable
                                    ref={ref}
                                    value={value}
                                    onChange={val => onChange(val)}
                                    options={LOCATIONS.map(location => {
                                        return {
                                            value: location,
                                            label: location
                                        }
                                    })}
                                    isDisabled={user?.modaterProfileId?.state}
                                />
                            )}
                        />
                    </div>
                    {errors.state && <Error errorMessage={ errors.state.message } /> }
                </div>
                <div className="flex flex-col w-full py-2">
                    <button className="border border-gray-800 uppercase p-3 font-light hover:bg-black hover:text-white text-xs rounded-full w-full nav-bg" disabled={disabled}>Become A Connector</button>
                </div>
            </div>
        </form>
    )
}

export default ConnectorSignUp;