import React, { useEffect, useRef, forwardRef } from 'react';
import { motion } from 'framer-motion';

const Dropdown = forwardRef(({ setOpen, children }, ref) => {

    useEffect(() => {
        const onClick = (e) => {
            if (!ref?.current?.contains(e.target)) {
                setOpen(prev => !prev);
            }
        }
        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick)
        }
    }, [])

    return (
        <>
            {
                <motion.ul initial={{opacity: 0, y: -20, x: -75}} animate={{opacity: 1, y: 5, x:-75}} transition={{ type: "tween", duration: .2 }} className={`nav-bg absolute top-7 transition ease-out duration-300 rounded-sm border border-gray-800 mt-2`} onClick={(e) => {; setOpen(prev => !prev)}} ref={ref}>
                    {children}
                </motion.ul>

            }
        </>
    )
})

export default Dropdown