import React, { useContext } from 'react';
import SidebarItem from './SidebarItem';
import { TokenContext } from './utilities/accessTokenContext';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TimelineIcon from '@mui/icons-material/Timeline';
import QuizIcon from '@mui/icons-material/Quiz';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';

const Sidebar = () => {

    const { user, matches } = useContext(TokenContext);

    return (
        <div className="w-72 fixed h-full sidebar-bg lg:flex flex-col column items-center overflow-y-scroll hidden border-r border-gray-800 z-50">
            <div className="flex flex-col mt-6 w-full">
                { user?.roles.includes(5718) && 
                    <Link to="/dashboard/adminDashboard/activity"><SidebarItem name="Admin Dashboard" icon={<TimelineIcon/>} isDropdown={false}/></Link>
                }
                { user?.connectorProfileId &&
                    <Link to="/dashboard/connectorDashboard"><SidebarItem name="Connector Dashboard" icon={<DashboardIcon/>} isDropdown={false}/></Link>
                }
                { user?.modaterProfileId &&
                    <Link to="/dashboard/modaterDashboard"><SidebarItem name="MoDater Dashboard" icon={<FavoriteBorderIcon/>} notification={!user?.modaterProfileId?.isDating?.status && matches.length > 0} unseen={matches.length} isDropdown={false}/></Link>
                }
                { user?.modaterProfileId &&
                   <Link to= "/Share"><SidebarItem name="Share" icon={<ShareIcon/>} isDropdown={false}></SidebarItem></Link>
                                    
                }
                <Link to="/dashboard/profile"><SidebarItem name="Profile" icon={<AccountCircleIcon/>} isDropdown={false}/></Link>
            </div>
            
        </div>
    )
}

export default Sidebar
