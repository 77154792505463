import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const SidebarItem = ({ name, icon, isDropdown, notification, unseen }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <div className="flex flex-col bg-transparent w-full">
            <div className={`flex bg-transparent w-full justify-start px-8 py-3 text-gray-400 space-x-4 items-center ${isDropdown ? '' : 'hover:bg-gray-700'}`}>
                { isDropdown && 
                    <span className="rounded-sm hover:bg-gray-700" onClick={() => setIsOpen(prev => !prev)}>{isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</span> 
                }
                { icon && 
                    <span>{icon}</span>
                }
                <div className="text-sm">{name}</div>
                { notification && 
                    <div className="w-6 h-4 flex justify-center items-center text-white rounded-full bg-orange-400 text-xs">{unseen}</div>
                }
            </div>
        </div>
    )
}

export default SidebarItem
