import React from 'react'
import { Link } from 'react-router-dom';

function ActionItem({ action }) {

    const getContent = (action) => {
        switch(action.eventType) {
            case "MATCH":
                return <div className="tracking-wider text-gray-400 text-xs"> <span className="font-mono text-xs text-green-600">{`${action.modaterIds[0]?.firstName} ${action.modaterIds[0]?.lastName}`}</span> And <span className="font-mono text-xs text-green-600">{`${action.modaterIds[1]?.firstName} ${action.modaterIds[1]?.lastName}`}</span> Liked Each Others Profiles</div>;
            case "CREATED_MODATER_PROFILE":
                return <div className=" tracking-wider text-gray-400 text-xs"><Link to={`/dashboard/adminDashboard/modaterProfile/${action.modaterIds[0]?._id}`} className="font-mono text-xs text-indigo-400">{`${action.modaterIds[0]?.firstName} ${action.modaterIds[0]?.lastName}`}</Link> Created A MoDater Profile</div>;
            case "CREATED_CONNECTOR_PROFILE":
                return <div className="tracking-wider text-gray-400 text-xs"><span className="font-mono text-xs text-blue-300">{`${action.connectorIds[0]?.firstName} ${action.connectorIds[0]?.lastName}`}</span> Created A Connector Profile</div>;
            case "BREAKUP":
                return <div className="tracking-wider text-gray-400 text-xs"> <span className="font-mono text-xs text-red-400">{`${action.modaterIds[0]?.firstName} ${action.modaterIds[0]?.lastName}`}</span> And <span className="font-mono text-xs text-red-400">{`${action.modaterIds[1]?.firstName} ${action.modaterIds[1]?.lastName}`}</span> Broke Up</div>;
            case "ADMIN_DELETE_ACCOUNT":
                return <div className="tracking-wider text-gray-400 text-xs"> <span className="font-mono text-xs">{`${action.modaterIds[0]?.firstName} ${action.modaterIds[0]?.lastName}`}</span> Deleted <span className="font-mono text-xs text-orange-400">{`${action.fullName}'s `}</span>Account</div>;
            case "USER_DELETE_ACCOUNT":
                return <div className="tracking-wider text-gray-400 text-xs"><span className="font-mono text-xs text-orange-400">{`${action.fullName} `}</span>Deleted their Account</div>;
            default:
                return <div></div>
        }
    }

    return (
        <div className="py-2 px-6 border border-black body-bg text-center rounded-md shadow-md shadow-black/60">{getContent(action)}</div>
    )
}

export default ActionItem