import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { TokenContext } from './utilities/accessTokenContext';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import TimelineIcon from '@mui/icons-material/Timeline';
import './nav.css';
import { motion } from 'framer-motion';
import { logout } from './utilities/EndPoints';
import Home from '../routes/Home';

const NavBar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const { setToken, user, loggedIn, setLoggedIn, setUser, navigate, matches, setMatches } = useContext(TokenContext);
    const elRef = useRef();

    const handleLogout = async () => {
        logout(setLoggedIn, setUser, setToken, setIsOpen, navigate, setMatches)
    }

    useEffect(() => {
        const onClick = (e) => {
            if(!elRef?.current?.contains(e.target)) {
                setIsOpen(false);
            }
        }
        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick)
        }
    }, [])
 
    return (
        <div className="fixed top-0 w-full flex flex-col justify-between items-center special nav-bg z-50 h-20 border-b border-gray-800">
            <div className="w-full py-2 px-12 bg-transparent flex justify-between flex-none">
                <img src="https://modate-photos.s3.amazonaws.com/modate.svg" alt="" className="h-full w-40" />

                {!loggedIn &&
                    <div className="relative hidden lg:flex justify-end items-center space-x-6 lg:w-3/5 lg:text-base sm:w-4/6 sm:flex-row xl:text-lg font-medium text-gray-400">
                        <Link to="/" className="flex items-center hover:text-blue-400 transform hover:scale-110 transition duration-500 sm:p-0">Home</Link>
                        <a href="/" onClick={e => {
                            let works = document.getElementById("works");
                            e.preventDefault();
                            works && works.scrollIntoView({ behavior: "smooth", block: "start" });
                        }} className="flex items-center hover:text-blue-400 transform hover:scale-110 transition duration-500 sm:p-0">How it Works</a>
                        <a target='_blank' href="https://podcasts.apple.com/us/podcast/the-mopod/id1604315531" className="flex items-center hover:text-blue-400 transform hover:scale-110 transition duration-500 sm:p-0">The MoPod</a>
                        
                        <a href="/" onClick={e => {
                            let contacts = document.getElementById("contacts");
                            e.preventDefault();
                            contacts && contacts.scrollIntoView({ behavior: "smooth", block: "start" });
                        }} className="flex items-center hover:text-blue-400 transform hover:scale-110 transition duration-500 sm:p-0">Contact Us</a>
                        
                        <div className="flex space-x-2">
                            <Link to="/signIn" className="flex justify-center items-center bg-gray-600 text-gray-300 px-3 py-1 rounded-md text-sm transform hover:scale-110 transition duration-500">Sign In</Link>
                            <Link to="/signUp" className="flex justify-center items-center bg-blue-500 text-gray-300 px-3 py-1 rounded-md text-sm transform hover:scale-110 transition duration-500">Sign Up</Link>
                        </div>
                    </div>
                }

                {loggedIn && 
                    <div className="relative hidden lg:flex justify-end items-center space-x-6 lg:w-3/5 lg:text-base sm:w-4/6 sm:flex-row xl:text-lg font-medium text-gray-400">
                        <button onClick={handleLogout} className="flex justify-center items-center bg-blue-500 text-gray-300 px-3 py-1 rounded-md text-sm">Sign Out</button>
                    </div>
                }
                {/* <MenuOutlinedIcon className="text-white"/> */}
                <button className="flex lg:hidden items-center justify-center" onClick={ (e) => {
                    e.stopPropagation();
                    setIsOpen(val => !val)}
                }>
                    <MenuOutlinedIcon className="text-white"/>
                </button>
            </div>
            {isOpen ?
                <div className="w-full">
                    { loggedIn ?
                        <motion.div initial={{opacity: 0, y: -300}} animate={{opacity: 1, y: 0}} transition={{ type: "tween", duration: .2 }} className="lg:hidden flex flex-col items-center nav-bg border border-gray-800 w-full p-3">
                            { user?.roles.includes(5718) && 
                                <button className="w-full" onClick={() => setIsOpen(false)}>
                                    <Link className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3" to="/dashboard/adminDashboard/activity">
                                        <TimelineIcon fontSize="medium"/>
                                        <span>Admin Dashboard</span>
                                    </Link>
                                </button>
                            }
                            { user && user.connectorProfileId &&
                                <button className="w-full" onClick={() => setIsOpen(false)}>
                                    <Link className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3" to="/dashboard/connectorDashboard">
                                        <DashboardIcon fontSize="medium"/>
                                        <span>Connector Dashboard</span>
                                    </Link>
                                </button>
                            }
                            { user && user.modaterProfileId &&
                                <button className="w-full" onClick={() => setIsOpen(false)}>
                                    <Link className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3" to="/dashboard/modaterDashboard">
                                        <FavoriteBorderIcon fontSize="medium"/>
                                        <span>MoDater Dashboard</span>
                                        { !user?.modaterProfileId?.isDating?.status && matches.length > 0 && 
                                            <div className="w-6 h-4 flex justify-center items-center text-white rounded-full bg-orange-400 text-xs">{matches.length}</div>
                                        }
                                    </Link>
                                </button>
                            }
                            <button className="w-full" onClick={() => setIsOpen(false)}>
                                <Link className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3" to="/dashboard/profile">
                                    <div><AccountCircleIcon fontSize="medium"/></div>
                                    <span>Profile</span>
                                </Link>
                            </button>
                            <button className="w-full" onClick={() => setIsOpen(false)}>
                                <Link className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3" to="/Share">
                                    <div><ShareIcon fontSize="medium"/></div>
                                    <span>Share</span>
                                </Link>
                            </button>
                        
                            <button onClick={handleLogout} className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3">Sign Out</button>
                        </motion.div> :
                        <motion.div initial={{opacity: 0, y: -300}} animate={{opacity: 1, y: 0}} transition={{ type: "tween", duration: .2 }} className="lg:hidden flex flex-col items-center nav-bg border border-gray-800 w-full p-3">
                            <Link to="/" className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3" onClick={() => setIsOpen(false)}>Home</Link>
                            <a href="/" onClick={e => {
                                let works = document.getElementById("works");
                                e.preventDefault();
                                works && works.scrollIntoView({ behavior: "smooth", block: "start" });
                                setIsOpen(false)
                            }} className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3">How It Works</a>
                            <a target='_blank' href="https://podcasts.apple.com/us/podcast/the-mopod/id1604315531" className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3">The MoPod</a>
                            <a href="/" onClick={e => {
                                let contacts = document.getElementById("contacts");
                                e.preventDefault();
                                contacts && contacts.scrollIntoView({ behavior: "smooth", block: "start" });
                                setIsOpen(false)
                            }} className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3">Contact Us</a>
                            <Link to="/signIn" className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3" onClick={() => setIsOpen(false)}>Sign In</Link>
                            <Link to="/signUp" className="w-full flex justify-center items-center space-x-4 text-gray-300 hover:bg-gray-800 hover:text-blue-400 transform hover:scale-110 transition duration-500 p-3" onClick={() => setIsOpen(false)}>Sign Up</Link>
                        </motion.div>
                    }
                </div>
                : null
            }
        </div>
    )
}

export default NavBar;