import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Error from '../Components/utilities/Error';
import { useParams, Link } from 'react-router-dom';
import '../Components/nav.css';
import { resetPassword } from '../Components/utilities/EndPoints';

const schema = yup.object().shape({
    password: yup.string().min(6).max(15).required('Password must be between 6 and 15 Characters long'),
    confirmPassword:  yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required')
})

const ResetPassword = () => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const [formError, setFormError] = useState();
    const [success, setSuccess] = useState(false);
    const { token } = useParams();

  
    const submitForm = async data => {
        resetPassword(token, data, setFormError, setSuccess);
    }

    return (
        <form onSubmit={ handleSubmit(submitForm) } className="flex justify-center items-center h-full special">
            <div className="flex flex-col w-full sm:w-1/2 lg:w-2/5 xl:w-2/6 h-1/2 justify-center items-center space-y-4 bg-transparent text-gray-200 px-10 tracking-wider">
                <div className="mb-4 text-2xl font-light">Reset Password</div>
                {formError && <div className="text-red-400 uppercase">{formError}</div>}
                { success ? 
                    <div className="pace-x-4 p-1 text-lg w-full flex justify-center text-white">
                        <div className="">Password Reset Success</div>
                        <Link to="/signIn">Click to Sign in</Link> 
                    </div> :
                    <>
                        <div className="flex flex-col w-full py-2">
                            <label className="flex justify-start mb-4 text-sm text-grey-darkest ml-6">Password</label>
                            <input { ...register('password') } type="password" placeholder="Password" className="border py-2 px-6 text-grey-darkest rounded-full focus:outline-none focus:bg-gray-200 text-black"/>
                            {errors.password && <Error errorMessage={errors.password.message} /> }
                        </div>
                        <div className="flex flex-col w-full py-2">
                            <label className="flex justify-start mb-4 text-sm text-grey-darkest ml-6">Confirm Password</label>
                            <input { ...register('confirmPassword') } type="password" className="border py-2 px-6 text-grey-darkest rounded-full focus:outline-none focus:bg-gray-200 text-black"/>
                            {errors.confirmPassword && <Error errorMessage={errors.confirmPassword.message} /> }
                        </div>
                        <div className="w-full space-y-4">
                            <div className="space-y-2" >
                                <p className="text-xs tracking-normal font-light ml-4">By clicking this button you are resetting your password</p>
                                <div className="flex flex-col w-full">
                                    <button type="submit" disabled={success} className="block bg-blue-600 uppercase p-3 font-light hover:bg-blue-900 text-xs rounded-full text-white w-full">Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </form>
    )
}

export default ResetPassword;