import React, { useContext, useState } from 'react'
import { TokenContext } from '../../Components/utilities/accessTokenContext';
import CardPreview from '../../Components/CardPreview.jsx';
import { motion } from 'framer-motion';
import Loading from '../../Components/Loading';
import Modal from '../../Components/Modal';
import { getRefreshTokenBeforeApiCall, reviewMatchByModater } from '../../Components/utilities/EndPoints';

const ModaterMatches = () => {

    const { token, setToken, setLoggedIn, navigate, user, matches, matchesLoading, setUser, setError } = useContext(TokenContext);
    const [modal, setModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [id, setId] = useState();
    const [isAMatch, setIsAMatch] = useState(false);
    const [reviewed, setReviewed] = useState(false);
    const [likedMatch, setLikedMatch] = useState(false);

    const rejectMatch = (id) => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, reviewMatchByModater, [id, false, setUser, setDisabled, setError, navigate, setIsAMatch, setModal, setReviewed, setLikedMatch]);
    }

    return (
        <div className="flex flex-col items-center py-8">
            { modal && 
                <Modal 
                    setModal={setModal}
                    disabled={disabled}
                    header="Are you sure you want to decline this match?"
                    body="If you choose to decline this match, the match will no longer be available on your MoDater dashboard. We hope the next match works out."
                    rejectHandler={() => rejectMatch(id)}
                />
            }
            <div className="flex justify-center items-center">
                {(user && user.modaterProfileId) ? 
                    matchesLoading ? 
                    <Loading 
                        type="cardPreview" 
                        numPlaceholders={3}
                    /> 
                    : (matches.length == 0 && !matchesLoading ? 
                        <div className="flex flex-col justify-center items-center text-white space-y-8">
                            <div className="text-4xl tracking-widest text-center">Nothing To See Here</div>
                            <div className="text-lg tracking-wide font-light">Check Back Later To See New Matches</div>
                        </div> 
                        : <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                            { matches.map((match, index) => {
                                return (
                                    <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} transition={{ delay: index*.1 }} key={match._id}>
                                        <CardPreview 
                                            user={ user?.modaterProfileId.sex === 'Male' ? match.girlModaterId : match.guyModaterId } 
                                            imagePreviewUrl={user?.modaterProfileId.sex === 'Male' ? match?.girlModaterId.photoUrls[0] : match?.guyModaterId.photoUrls[0]} 
                                            matchId={match._id} 
                                            setModal={setModal}
                                            setId={setId}
                                            isAMatch={isAMatch} 
                                            reviewed={reviewed} 
                                            likedMatch={likedMatch}
                                            id={id}
                                            timeTillExpiration={match?.timeTillExpiration}
                                        />
                                    </motion.div>
                                )
                            })}
                        </div>
                    ) 
                : <div className="w-full h-full flex justify-center items-center text-gray-300 text-3xl font-light tracking-wider p-4">You must be a MoDater to access this route</div>
                }
            </div> 
        </div>
    )
}

export default ModaterMatches