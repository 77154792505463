import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReactCardFlip from 'react-card-flip';
import './nav.css'

const CardProfile = ({ user, urls, height, width }) => {

    const [curImageIndex, setCurImageIndex] = useState(0);
    
    const rightArrowClick = () => {
        if (curImageIndex < urls.length - 1) {
            setCurImageIndex(index => index + 1);
        }
    }

    const leftArrowClick = () => {
        if (curImageIndex > 0) {
            setCurImageIndex(index => index - 1);
        }
    }

    useEffect(() => {
        if (urls.length <= curImageIndex) {
            setCurImageIndex(0)
        }
    }, [urls, curImageIndex])

    return (
        <div className="flex justify-center items-center flex-none">
            <div className="flex justify-center items-center flex-none">
            {/* ${height} ${width} */}
                <div className={`flex-none h-[28rem] w-80 shadow-xl shadow-black border border-gray-800 rounded-lg special overflow-hidden relative`}>
                    {/* image of person */}
                    <div className="h-full w-full relative">
                        {urls.length > 0 ? <img src={urls[curImageIndex] && urls[curImageIndex].url} alt="" className="object-cover h-full w-full"/> : <img src="https://modate-photos.s3.amazonaws.com/blank-profile-picture.png" alt="" className="object-cover h-96 w-full"/>}
                        { curImageIndex > 0 &&
                            <button className="absolute top-1/2 left-3 bg-black w-8 h-8 text-white flex justify-center items-center rounded-full bg-opacity-40 hover:bg-opacity-50" onClick={leftArrowClick}>
                                <ChevronLeftIcon />
                            </button>
                        }
                        { curImageIndex < urls.length-1 &&
                            <button className="absolute top-1/2 right-3 bg-black w-8 h-8 text-white flex justify-center items-center rounded-full bg-opacity-40 hover:bg-opacity-50" onClick={rightArrowClick}>
                                <ChevronRightIcon />
                            </button>
                        }
                    </div>

                    {/* details about person */}
                    <div className="p-4 absolute top-2/3 h-1/3 w-full bg-gray-700 bg-opacity-30">
                        <div className="flex flex-col text-white space-y-2">
                            <div className="flex justify-between items-center">
                                {/* name, age, and height of person */}
                                <div className="text-sm uppercase flex items-center">{`${user.modaterProfileId.firstName} ${user.modaterProfileId.lastName}, `}
                                    <span className="text-lg ml-4">{new Date(Date.now() - new Date(user.modaterProfileId.dateOfBirth)).getFullYear() - 1970}</span>
                                    <span className="text-lg ml-4">{Math.floor(user.modaterProfileId.height / 12)}'{user.modaterProfileId.height % 12}"</span>
                                </div>
                                {/* persons location */}
                                <div className="text-xs uppercase ml-4">{user.modaterProfileId.city}, {user.modaterProfileId.state}</div>
                            </div>
                            {/* vocation and college */}
                            <div className="text-sm card"><span>{user.modaterProfileId.vocation}</span> - <span className="uppercase">{user.modaterProfileId.college}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardProfile; 
