import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

function Loading({ type, numPlaceholders }) {
    
    const populate = () => {
        const arr = [];
        for (let i = 0; i < numPlaceholders; i++) {
            arr.push("");
        }
        return arr;
    }

    switch(type) {
        case 'modaterInfo':
            return (
                populate().map((arr, idx) => {
                    return (
                        <div key={idx} className="flex animate-pulse">
                            <div className="flex-1 border-b border-gray-800 h-16 p-4">
                                <div className="bg-red-400 h-8 w-3/4 rounded-md body-bg"></div>
                            </div>
                            <div className="flex-1 border-b border-gray-800 h-16 p-4">
                                <div className="bg-red-400 h-8 w-3/4 rounded-md body-bg"></div>
                            </div>
                            <div className="flex-1 border-b border-gray-800 h-16 p-4">
                                <div className="bg-red-400 h-8 w-3/4 rounded-md body-bg"></div>
                            </div>
                            <div className="flex-1 border-b border-gray-800 h-16 p-4">
                                <div className="bg-red-400 h-8 w-3/4 rounded-md body-bg"></div>
                            </div>
                        </div>
                    )
                })
            )
        case 'aggregateLikes':
            return (
                populate().map((arr, idx) => {
                    return (
                        <div key={idx} className="flex animate-pulse snap-center">
                            <div className="flex flex-col body-bg h-[230px] w-[160px] rounded-lg overflow-hidden">
                                <div className="h-3/4 w-full bg-[#1f2226]"></div>
                                <div className="flex space-x-4 items-center px-2 py-3">
                                    <div className="w-[100px] h-[12px] bg-gray-700 rounded-sm"></div>
                                    <div className="w-[25px] h-[16px] rounded-full bg-gray-700"></div>
                                </div>
                            </div>
                        </div>
                    )
                })
            )
        case 'mostRecentLikes':
            return (
                populate().map((arr, idx) => {
                    return (
                        <div key={idx} className="flex items-center space-x-4 body-bg px-4 py-3 rounded-md border border-gray-800 overflow-hidden animate-pulse" >
                            <div className="flex items-center -space-x-4 flex-none">
                                <div className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full bg-gray-600 ring-1 ring-gray-800" ></div>
                                <div className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full bg-gray-600 ring-1 ring-gray-800" ></div>
                            </div>
                            <div className="flex flex-col space-y-3 w-full">
                                <div className="flex space-x-2 items-center w-full">
                                    <div className="h-[12px] w-[40%] bg-gray-600 rounded-sm"></div>
                                    <ArrowRightAltIcon className="text-blue-400" fontSize="small" />
                                    <div className="h-[12px] w-[40%] bg-gray-600 rounded-sm"></div>
                                </div>
                                <div className="h-[12px] w-[65%] bg-gray-700 rounded-sm"></div>
                            </div>
                        </div>
                    )
                })
            )
        case 'dating':
            return (
                populate().map((arr, idx) => {
                    return (
                        <div key={idx} className="flex items-center space-x-4 body-bg px-4 py-3 rounded-md border border-gray-800 animate-pulse" >
                            <div className="flex items-center -space-x-4 flex-none">
                                <div className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full bg-gray-600 ring-1 ring-gray-800" ></div>
                                <div className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full bg-gray-600 ring-1 ring-gray-800" ></div>
                            </div>
                            <div className="flex flex-col space-y-3 w-full">
                                <div className="flex space-x-2 items-center w-full">
                                    <div className="h-[12px] w-[40%] bg-gray-600 rounded-sm"></div>
                                    <FavoriteIcon className="text-red-300" fontSize="small" />
                                    <div className="h-[12px] w-[40%] bg-gray-600 rounded-sm"></div>
                                </div>
                                <div className="h-[12px] w-[65%] bg-gray-700 rounded-sm"></div>
                            </div>
                        </div>
                    )
                })
            )
        case 'statistics':
            return (
                populate().map((arr, idx) => {
                    return (
                        <div key={idx} className="flex px-3 items-center space-x-3 body-bg rounded-md animate-pulse h-[40px] w-full" >
                            <div className={`h-[14px] w-[45%] bg-gray-600 rounded-sm`}></div>
                            <div className="h-[14px] w-[20%] bg-gray-700 rounded-sm"></div>
                        </div>
                    )
                })
            )
        case 'generalStats':
            return (
                populate().map((arr, idx) => {
                    return (
                        <div className="flex space-x-8 flex-1" key={idx}>
                            <div className="flex flex-1 h-[125px] rounded-lg flex-col space-y-2 nav-bg p-4 justify-center items-center border border-gray-800">
                                <span className="h-[14px] w-[75%] bg-gray-500 rounded-sm"></span>
                                <span className="h-[24px] w-[35%] bg-blue-400 rounded-sm"></span>
                            </div>
                            <div className="flex flex-1 h-[125px] rounded-lg flex-col space-y-2 nav-bg p-4 justify-center items-center border border-gray-800">
                                <span className="h-[14px] w-[75%] bg-gray-600 rounded-sm"></span>
                                <span className="h-[24px] w-[35%] bg-blue-300 rounded-sm"></span>
                            </div>
                        </div>
                    )
                })
            )
        case 'cardPreview': 
            return (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                    {
                        populate().map((arr, idx) => {
                            return (
                                <div className={`h-[28rem] w-80 shadow-xl shadow-black border border-gray-800 rounded-lg special overflow-hidden relative animate-pulse`} key={idx}>
                                    <div className="h-full w-full relative">
                                        {<div className={`absolute h-[24px] w-[100px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-200 px-2 py-0.5 bg-gray-700 bg-opacity-50 hover:bg-opacity-80 rounded-sm uppercase tracking-wider text-sm`} ></div> }
                                    </div>
                                    <div className={`absolute h-[24px] w-[64px] top-2 left-2 px-2 py-0.5 bg-gray-700 bg-opacity-60 rounded-sm text-sm`}>
                                    </div>
                                    <div className="p-4 absolute top-3/4 h-1/4 w-full bg-gray-700 bg-opacity-20">
                                        <div className="flex flex-col text-white space-y-4">
                                            <div className="flex justify-between items-center">
                                                <div className="h-[20px] w-[45%] bg-gray-700 rounded-sm"></div>
                                                <div className="h-[20px] w-[45%] bg-gray-700 rounded-sm"></div>
                                            </div>
                                            <div className="h-[20px] w-[175px] bg-gray-700 rounded-sm"></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        default: 
            return null
    }
}

export default Loading