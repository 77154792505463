import React, { useState, useContext, useEffect } from "react";
import PhotoCarousel from '../Components/PhotoCarousel';
import ReactCardFlip from "react-card-flip";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  getRefreshTokenBeforeApiCall,
  connectorRateMatch,
} from "./utilities/EndPoints";
import { TokenContext } from "./utilities/accessTokenContext";
import { getRelationToJudaism } from "./utilities/funcs";
import { HEIGHTS } from "../constants";
import "./nav.css";


function MatchCard({
  user,
  cardIndex,
  setCardIndex,
  property,
  selectedMatch,
  connectorOfMatch,
  setUnreviewedMatches,
  disabled,
  setDisabled,
  urls,
  curImageIndex,
  setCurImageIndex
}) {
  const { token, setToken, setLoggedIn, navigate, setError, setSuccess } =
    useContext(TokenContext);

  const [isFlipped, setIsFlipped] = useState(false);

  const clickPrevious = () => {
    if (cardIndex !== 0) {
      setCardIndex((prev) => prev - 1);
    }
  };

  const clickNext = () => {
    if (cardIndex < user.matches.length - 1) {
      setCardIndex((prev) => prev + 1);
    }
  };

  const ratingHandler = (matchId, rating) => {
    getRefreshTokenBeforeApiCall(
      token,
      setToken,
      setLoggedIn,
      navigate,
      connectorRateMatch,
      [
        matchId,
        rating,
        setUnreviewedMatches,
        cardIndex,
        setCardIndex,
        user.matches.length,
        setDisabled,
        setError,
        setSuccess,
      ]
    );
  };

  const ratings = [1, 2, 3, 4, 5];
  // console.log(cardIndex)
  // console.log(selectedMatch)
  // console.log(user)
  // console.log(connectorOfMatch)

  return (
    user.matches.length > 0 && (
      <div className="flex flex-col w-full items-center">
        <div className="flex justify-center items-center">
          <ReactCardFlip isFlipped={isFlipped} flipSpeedBackToFront={0.3}>
            <div className="flex justify-center items-center flex-none">
              <div
                className={`flex-none h-[550px] w-[343px] sm:w-[365px] rounded-lg nav-bg border border-gray-700 overflow-hidden relative tracking-wider`}
              >
                <button
                  className="absolute top-4 right-3 text-white px-2 bg-blue-300 bg-opacity-40 hover:bg-opacity-50 rounded-sm py-1 text-xs uppercase"
                  onClick={() => setIsFlipped((prev) => !prev)}
                >
                  Flip Card
                </button>
                <div className="text-white h-full w-full">
                  <div className="p-4 flex space-x-3 items-center">
                    <span className="text-xs tracking-wider">Connector:</span>
                    <div className="text-xs body-bg px-4 py-1 text-gray-300 rounded-sm shadow-sm shadow-gray-700/50 cursor-pointer">{`${connectorOfMatch.firstName} ${connectorOfMatch.lastName}`}</div>
                  </div>
                  {/* characteristics */}
                  <div className="px-4 w-full sm:text-lg text-blue-300 font-light tracking-wider">
                    Characteristics
                  </div>
                  <div className="flex flex-wrap items-start">
                    {selectedMatch[property].characteristics.map(
                      (characteristic, index) => {
                        return (
                          <div
                            className="body-bg px-3 py-0.5 mt-2.5 rounded-sm text-gray-300 text-sm ml-4"
                            key={index}
                          >
                            {characteristic}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="p-4 absolute top-36 h-56 w-full flex flex-col space-y-1">
                    <div className="flex items-center space-x-20 py-2">
                      <div className="text-md sm:text-lg font-light tracking-wider text-blue-300">
                        A Little About Me
                      </div>
                      <div className="text-sm font-medium">
                        Height: {HEIGHTS[selectedMatch[property].height]}
                      </div>
                    </div>
                    {selectedMatch[property].bio.length > 0 ? (
                      <div className="text-base text-gray-300 font-light overflow-y-scroll tracking-wide">
                        {selectedMatch[property].bio}
                      </div>
                    ) : (
                      <div className="text-xl h-full flex items-center justify-center tracking-wide font-light">
                        No Bio Provided
                      </div>
                    )}
                  </div>
                  <div className="p-4 absolute top-2/3 h-1/3 w-full bg-gray-700 bg-opacity-20">
                    <div className="flex flex-col text-white space-y-3">
                      <div className="flex justify-between items-center">
                        {/* name and age of person */}
                        <div className="text-md uppercase flex items-center">
                          {`${selectedMatch[property].alias}, `}
                          <span className="text-lg ml-2">
                            {new Date(
                              Date.now() -
                                new Date(selectedMatch[property].dateOfBirth)
                            ).getFullYear() - 1970}
                          </span>
                        </div>
                        {/* persons location */}
                        <div className="text-xs uppercase">
                          {selectedMatch[property].city},{" "}
                          {selectedMatch[property].state}
                        </div>
                      </div>
                      {/* vocation and college */}
                      <div className="text-xs tracking-wide uppercase font-light">
                        <span>{selectedMatch[property].vocation}</span> -{" "}
                        <span>{selectedMatch[property].college}</span>
                      </div>
                      {/* activities */}
                      <div className="p-0.5 flex">
                        <div className="text-xs sm:text-sm flex items-center">
                          Favorite Activities
                        </div>
                        <div className="flex flex-wrap items-start -ml-2">
                          {selectedMatch[property].activities.map(
                            (activity, index) => {
                              return (
                                <div
                                  className="nav-bg px-3 py-0.5 mt-2 rounded-sm text-gray-300 text-xs sm:text-sm ml-2"
                                  key={index}
                                >
                                  {activity}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center flex-none">
              <div
                className={`flex-none h-[550px] w-[343px] sm:w-[365px] rounded-lg nav-bg border border-gray-700 overflow-hidden relative tracking-wider`}
              >
                <button
                  className="absolute top-4 right-3 text-white px-2 bg-blue-300 bg-opacity-40 hover:bg-opacity-50 rounded-sm py-1 text-xs uppercase"
                  onClick={() => setIsFlipped((prev) => !prev)}
                >
                  Flip Card
                </button>
                <div className="text-white h-full w-full">
                  <div className="p-4 flex space-x-3 items-center">
                    <span className="text-xs tracking-wider">Connector:</span>
                    <div className="text-xs body-bg px-4 py-1 text-gray-300 rounded-sm shadow-sm shadow-gray-700/50 cursor-pointer">{`${connectorOfMatch.firstName} ${connectorOfMatch.lastName}`}</div>
                  </div>
                  <div className="space-y-0.5">
                    <div className="px-4 text-sm font-light">
                      Aliyah: {selectedMatch[property].aliyah}
                    </div>
                    <div className="px-4 text-sm font-light">
                      Shabbos: {selectedMatch[property].shabbos}
                    </div>
                    <div className="px-4 text-sm font-light">
                      Learns: {selectedMatch[property].learns}
                    </div>
                    <div className="px-4 text-sm font-light">
                      Kosher: {selectedMatch[property].kosher}
                    </div>
                    <div className="px-4 text-sm font-light">
                      Shomer Negiah: {selectedMatch[property].shomerNegiah}
                    </div>
                  </div>
                  <div className="p-4 absolute top-48 h-52 w-full flex flex-col space-y-1">
                    <div className="text-xl font-light tracking-wider">
                      Relation To Judaism
                    </div>
                    <div className="text-base text-gray-300 font-light overflow-y-scroll tracking-wide">
                      {getRelationToJudaism(
                        selectedMatch[property].relationToJudaism
                      )}
                    </div>
                  </div>
                  <div className="p-4 absolute top-2/3 h-1/3 w-full bg-gray-700 bg-opacity-20">
                    <div className="flex flex-col text-white space-y-3">
                      <div className="flex space-x-4 items-center">
                        <span className="text-sm font-medium">
                          High School:
                        </span>
                        <div className="text-sm font-light">
                          {selectedMatch[property].highSchool}
                        </div>
                      </div>
                      <div className="flex space-x-4 items-center">
                        <span className="text-sm font-medium">
                          {user.sex === "Male" ? "Seminary" : "Yeshiva"}:
                        </span>
                        <div className="text-sm font-light">
                          {selectedMatch[property].israelSchool}
                        </div>
                      </div>
                      {/* politics */}
                      <div className="flex space-x-4 items-center">
                        <span className="text-sm font-medium">Politics:</span>
                        <div className="text-sm font-light">
                          {selectedMatch[property].politics}
                        </div>
                      </div>
                      <div className="flex space-x-4 items-center">
                        <span className="text-sm font-medium">Vacation:</span>
                        <div className="text-sm font-light">
                          {selectedMatch[property].vacationPreference}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactCardFlip>
        </div>
        <div className="flex justify-center mt-4 w-full items-center">
          <div className="flex space-x-4 items-center">
            <button
              className="text-white hover:bg-gray-600 p-1 rounded-lg flex items-center"
              onClick={clickPrevious}
            >
              <ArrowBackIosNewOutlinedIcon fontSize="small" />
            </button>
            {user.matches.map((match, index) => {
              return (
                <div
                  className={`h-3 w-3 rounded-full ${
                    index === cardIndex ? "bg-gray-100" : "bg-gray-600"
                  }`}
                  key={index}
                ></div>
              );
            })}
            <button
              className="text-white hover:bg-gray-600 p-1 rounded-lg flex items-center"
              onClick={clickNext}
            >
              <ArrowForwardIosOutlinedIcon fontSize="small" />
            </button>
          </div>
        </div>
        <div>
          <div className="font-medium text-white tracking-wider uppercase special">{`${selectedMatch[property].firstName}`}</div>
            <div
            className={`flex-none h-[28rem] shadow-xl shadow-black/60 w-80 rounded-lg bg-gray-200 special border border-gray-700 overflow-hidden relative mb-20`}
            >
              <PhotoCarousel
                      urls={urls}
                      curImageIndex={curImageIndex}
                      setCurImageIndex={setCurImageIndex}
              />
          </div>
        </div>  
        <div className="w-[365px] flex flex-col space-y-4 mt-8 px-2 md:px-0">
          <div className="w-full flex justify-between items-center text-gray-300">
            <div className="text-xs font-sans font-medium uppercase">
              Not A Fit
            </div>
            <span className="text-md font-sans font-medium uppercase">
              Rating
            </span>
            <div className="text-xs font-sans font-medium uppercase">
              Awesome
            </div>
          </div>
          <div className="w-full flex justify-between items-center text-white text-xl card nav-bg px-4">
            {/* <span className="text-sm font-sans font-medium uppercase">Rating</span> */}
            {ratings.map((rating) => (
              <button
                key={rating}
                onClick={() => ratingHandler(selectedMatch._id, rating)}
                disabled={disabled}
                className="text-xl text-white card px-4 py-2 rounded-full transform hover:scale-150 transition duration-300"
              >
                {rating}
              </button>
            ))}
          </div>
        </div>
      </div>
    )
  );
}

export default MatchCard;
