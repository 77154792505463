import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';

function Modal({ title, onCloseHandler, onPrevHandler, cancelButtonText, onSubmitHandler, submitButtonText, children }) {

    const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
    const elRef = useRef();

    useEffect(() => {
        const onClick = (e) => {
            console.log('clicked')
            if(!elRef?.current?.contains(e.target)) {
                onCloseHandler(e);
            }
        }
        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick)
        }
    }, [])

    return (
        <div className={`w-full h-full absolute flex justify-center items-center z-50 overflow-y-scroll bg-black bg-opacity-30`} style={{top: `${window.scrollY}px`}}>
            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} className="w-[20rem] max-w-[30rem] max-h-[40rem] sm:w-auto relative" ref={elRef}>
                <button className="absolute z-10 top-4 right-4 hover:text-gray-500 text-gray-300" onClick={onCloseHandler}><CloseIcon /></button>
                <div className="flex flex-col bg-black rounded-lg p-4 space-y-8 ring-1 ring-gray-300 ring-opacity-20 text-gray-300 shadow-xl shadow-black">
                    <div className="text-sm sm:text-base font-light tracking-wider mt-10 text-center text-white uppercase">{title}</div>

                    {React.cloneElement(children, {setDisabled: setButtonIsDisabled})}

                    <div className="w-full flex justify-center item-center space-x-8 text-black">
                        <button className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800" onClick={onPrevHandler}>{cancelButtonText}</button>
                        <button className={`text-sm px-3 py-1 rounded-md border border-gray-800 ${buttonIsDisabled ? 'nav-bg text-red-400' : 'bg-blue-400 text-white'}`} onClick={onSubmitHandler} disabled={buttonIsDisabled}>{submitButtonText}</button>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default Modal
