import React, { useContext, useEffect, useState } from 'react'
import { TokenContext } from '../../Components/utilities/accessTokenContext';
import { getRefreshTokenBeforeApiCall, getStatusOfMatchesModater } from '../../Components/utilities/EndPoints';
import LikedMatches from '../../Components/modaterDash/LikedMatches';
import moment from 'moment';
import ToolTip from '../../Components/ToolTip';
import InfoIcon from '@mui/icons-material/Info';
import VerifiedUserOutlined from "@mui/icons-material/VerifiedUserOutlined";
import ConnectorStatusBar from '../../Components/modaterDash/ConnectorStatusBar';


const ModaterMatchesStatus = () => {

    const { token, setToken, setLoggedIn, navigate, user } = useContext(TokenContext);

    const [matches, setMatches] = useState();
    const [matchesLoading, setMatchesLoading] = useState(false);

    const connectorTimeToRateMatch = moment.duration(matches?.averageTimeForConnectorToRateMatch, 'milliseconds').asHours()
    const overallConnectorTimeToRateMatch = moment.duration(matches?.averageOverallTimeForConnectorToRateMatch, 'milliseconds').asHours()

    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getStatusOfMatchesModater, [setMatches, setMatchesLoading]);
    }, [token])

    // console.log(matches)
    // console.log(overallConnectorTimeToRateMatch)

    return (
      <div className="w-full p-6 md:p-12 flex justify-center">
        <div className="w-full max-w-[896px] flex">
          <div className="w-full flex md:flex-row flex-col md:space-x-6 space-y-6 md:space-y-0 md:mt-8">
            <LikedMatches
              title="Matches You've Liked"
              data={matches?.likedMatches}
              status="Current"
              matchesLoading={matchesLoading}
              user={user}
              id={1}
            />
            <div className="nav-bg w-full md:w-1/2 h-[20rem] rounded-md border border-gray-800 shadow-lg shadow-black overflow-y-scroll flex flex-col space-y-2">
              <div className="w-full sticky top-0 nav-bg px-4 pt-4 pb-2 font-bold text-gray-300 flex justify-between items-center z-10">
                <div className="text-gray-300 ">Connector Stats</div>
              </div>
              <div className="flex items-center justify-center py-2">
                <div className="w-1/2 flex justify-center items-center">
                  <div className="h-28 w-28 body-bg rounded-md border border-gray-800 flex flex-col justify-center items-center space-y-3 relative">
                    {(matches?.connectorProfile?.connectorProfileId?.verificationStatus === 'VERIFIED') && (
                      <VerifiedUserOutlined className="text-blue-300 absolute top-0 right-2 px-0.5" />
                    )}
                    <img
                      className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white flex-none"
                      src={
                        matches?.connectorProfile?.modaterProfileId
                          ?.photoUrls[0]?.url
                          ? matches?.connectorProfile?.modaterProfileId
                              ?.photoUrls[0]?.url
                          : "https://modate-photos.s3.amazonaws.com/blank-profile-picture.png"
                      }
                      alt=""
                    />
                    <div className="text-xs text-gray-200 text-center">
                      {matches?.connectorProfile?.connectorProfileId?.firstName}{" "}
                      {matches?.connectorProfile?.connectorProfileId?.lastName}
                    </div>
                  </div>
                </div>
                <div className="flex text-gray-300 flex-col items-center space-y-2 w-1/2 px-4 md:px-8 md:mx-4 text-center">
                  <div className="text-sm">
                    <span className="mr-2">
                      How your Connector stacks up to others
                    </span>
                    <ToolTip title="How quickly your Connector rates your matches compared to how quickly other Connectors rate matches.">
                      <InfoIcon
                        fontSize="small"
                        className="cursor-pointer text-blue-300"
                      />
                    </ToolTip>
                  </div>
                  <div className="body-bg px-3 py-0.5 border border-gray-800 rounded-sm text-xs">
                    {connectorTimeToRateMatch <= 36
                      ? "Excellent"
                      : connectorTimeToRateMatch <= 72
                      ? "Very Good"
                      : connectorTimeToRateMatch <= 108
                      ? "Good"
                      : connectorTimeToRateMatch <=
                        overallConnectorTimeToRateMatch
                      ? "Average"
                      : "Below Average"}
                  </div>
                  <ConnectorStatusBar
                    connectorTimeToRateMatch={connectorTimeToRateMatch}
                    overallConnectorTimeToRateMatch={
                      overallConnectorTimeToRateMatch
                    }
                  />
                </div>
              </div>
              <div className="w-full flex justify-center items-center py-2 px-6 md:px-10">
                <div className="h-42 w-full body-bg text-gray-300 flex flex-col">
                  <div className="w-full sticky body-bg top-0 px-2 pt-2 pb-2 font-bold text-gray-300 flex justify-between items-center z-10">
                    <div className="text-xs text-center">
                      Matches waiting on your Connector
                    </div>
                  </div>
                  <div className="flex space-x-1.5 p-2 w-full overflow-x-scroll snap-x">
                    {matches?.matchesWhereConnectorNeedsToReview?.length > 0 ? (
                      matches?.matchesWhereConnectorNeedsToReview.map(
                        (match) => {
                          const sexKey =
                            user?.modaterProfileId.sex === "Male"
                              ? "girlAlias"
                              : "guyAlias";
                          return (
                            <div className="snap-center flex flex-col space-y-1 nav-bg border border-gray-800 p-2 flex-none w-24 h-24">
                              <div className="text-xs">
                                Alias{" "}
                                <span className="font-sans font-semibold">
                                  {match[sexKey]}
                                </span>
                              </div>
                              <div className="text-xs">
                                Activated{" "}
                                <span className="font-sans font-semibold">
                                  {moment(match.timeActivated).format(
                                    "MM/DD/YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <div className="text-green-400 h-full w-full flex justify-center items-center">
                        0
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ModaterMatchesStatus