import React, { useEffect, useRef, useState } from 'react'
import './nav.css';
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close';

function DeleteAccountModal({ setModal, rejectHandler, disabled }) {

    const elRef = useRef();
    const [deleteText, setDeleteText] = useState("");

    const onClickHandler = (e) => {
        e.stopPropagation();
        setModal(prev => !prev);
    }

    useEffect(() => {
        const onClick = (e) => {
            console.log(elRef.current)
            if(!elRef?.current?.contains(e.target)) {
                setModal(prev => !prev);
            }
        }
        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick)
        }
    }, [])

    const isDisabled = disabled || deleteText !== 'delete';

    return (
        <div className="w-full h-full absolute flex justify-center items-center z-10 bg-black bg-opacity-20">
            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} className="w-72 sm:w-96 relative" ref={elRef}>
                <button className="absolute z-10 top-4 right-4 hover:text-gray-500 text-gray-300" onClick={onClickHandler}><CloseIcon /></button>
                <div className="flex flex-col nav-bg rounded-lg p-4 space-y-8 ring-2 ring-gray-700 ring-opacity-20 text-gray-300">
                    <div className="text-sm sm:text-base font-medium tracking-wider mt-10 underline text-center uppercase">Delete Account</div>
                    <div className="text-xs sm:text-sm text-center tracking-wide font-light">This action is not reversable. All of your data will be deleted. Any match that you are in will be deleted and if you are a connector all of your Modaters will need to find a new one.</div>
                    <motion.div initial={{opacity: 0, y: -200}} animate={{opacity: 1, y: 0}} className="flex flex-col items-center justify-start space-y-2">
                        <div className="ml-2 w-[80%] text-sm font-light">Type "delete" in order to permanently delete your Account</div>
                        <input type="text" className="sidebar-bg outline-none px-4 py-1 w-[80%] border border-gray-800 rounded-sm" placeholder="delete" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                    </motion.div>
                    <div className="w-full flex justify-center item-center space-x-8 text-black">
                        <button className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800" onClick={onClickHandler}>Cancel</button>
                        <button className={`text-sm px-3 py-1 rounded-sm border border-gray-800 ${isDisabled ? 'nav-bg text-red-400' : 'bg-red-400 text-black'}`} onClick={rejectHandler} disabled={isDisabled}>Delete Account</button>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default DeleteAccountModal