import React, { useState } from 'react';
import Loading from '../../Components/Loading';
import moment from 'moment';
import { useLocalStorage } from '../utilities/hooks';

const LikedMatches = ({title, data, status, matchesLoading, user, id}) => {
    
    const [cardView, setCardView] = useLocalStorage(`LikedMathcesCardView${id}`, JSON.parse(localStorage.getItem(`LikedMathcesCardView${id}`) || false));
    // console.log(data?.length)
    // console.log(matchesLoading)
    // console.log(!user)
    return (
        <div className="nav-bg w-full md:w-1/2 h-[20rem] rounded-md border border-gray-800 shadow-lg shadow-black overflow-y-scroll">
            <div className="w-full sticky top-0 nav-bg px-4 pt-4 pb-2 font-bold text-gray-300 flex justify-between items-center z-10">
                <div>{title}</div>
                <div className="cursor-pointer hover:scale-110" onClick={() => setCardView(prev => !prev)}>
                    { cardView ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-layout-list" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <rect x="4" y="4" width="16" height="6" rx="2"></rect>
                            <rect x="4" y="14" width="16" height="6" rx="2"></rect>
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-layout-cards" width="24" height="24" viewBox="0 -2 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <rect x="4" y="4" width="6" height="12" rx="2"></rect>
                            <rect x="14" y="4" width="6" height="12" rx="2"></rect>
                        </svg>
                    }
                </div>
            </div>
            <div className={`w-full p-4 flex ${cardView ? 'flex-row space-x-8 snap-mandatory snap-x overflow-x-scroll' : 'flex-col space-y-3 snap-mandatory snap-y overflow-y-scroll'}`}>
                { (matchesLoading || !user || !data) ?
                    (cardView ? <Loading type="aggregateLikes" numPlaceholders={10} /> : <Loading type="mostRecentLikes" numPlaceholders={10} />) 
                    :
                    data?.length > 0 ?
                        data.map(match => {
                            const sexKey = user.modaterProfileId.sex === 'Male' ? 'girlModaterId' : 'guyModaterId';
                            const reviewKey = user.modaterProfileId.sex === 'Male' ? 'girlReview' : 'guyReview';
                            const updatedKey = user.modaterProfileId.sex === 'Male' ? 'guyReview' : 'girlReview';
                            return (
                                cardView ? 
                                    <div className="snap-center flex flex-col body-bg h-[230px] w-[160px] flex-none rounded-lg overflow-hidden relative hover:opacity-80 hover:cursor-pointer" key={match._id}>
                                        <img src={match[sexKey].photoUrls[0]?.url ? match[sexKey].photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} className="h-3/4 object-center object-cover" alt="" />
                                        { match?.expirationDetails?.expired ? 
                                            <div className="absolute top-0 w-full h-full bg-black bg-opacity-20">
                                                <div className="absolute top-20 left-10 py-1 px-3 -rotate-12 border-2 border-red-400 rounded-sm text-red-400 tracking-widest uppercase font-sans font-bold text-xs">expired</div>
                                            </div>
                                            : (match?.[reviewKey]?.reviewed && !match?.[reviewKey]?.LikedMatch) ?
                                            <div className="absolute top-0 w-full h-full bg-black bg-opacity-20">
                                                <div className="absolute top-20 left-10 py-1 px-3 -rotate-12 border-2 border-red-400 rounded-sm text-red-400 tracking-widest uppercase font-sans font-bold text-xs">not a fit</div>
                                            </div>
                                            : (match?.[reviewKey]?.reviewed && match?.[reviewKey]?.LikedMatch) ? 
                                            <div className="absolute top-0 w-full h-full bg-black bg-opacity-20">
                                                <div className="absolute top-20 left-10 py-1 px-3 -rotate-12 border-2 border-green-400 rounded-sm text-green-400 tracking-widest uppercase font-sans font-bold text-xs">Matched</div>
                                            </div>
                                            : null
                                        }
                                        <div className="h-1/4 w-full flex flex-col py-2 space-y-1 px-2">
                                                <div className="tracking-wider text-xs text-gray-200 truncate">{match[sexKey].firstName} {match[sexKey].lastName}</div>
                                                { match?.isActiveForModaters ? 
                                                    <div className="text-gray-400 flex space-x-1 items-center">
                                                        <span className="text-sm">Expires in</span>
                                                        <span className="text-sm special tracking-widest text-gray-300">{moment(match?.timeTillExpiration).diff(Date.now(), 'days')}</span>
                                                        <span className="text-sm">Days</span>
                                                    </div>
                                                    :
                                                    <div className="text-gray-400 flex items-start text-sm space-x-1">
                                                        <span className="text-xs">Liked on</span>
                                                        <span className="font-sans font-medium text-gray-300 text-xs">{moment(match?.[updatedKey]?.dateUpdated).format('MM/DD/YYYY')}</span>
                                                    </div>
                                                }
                                        </div>
                                        { match?.isActiveForModaters ?
                                            !match[sexKey].activeStatus ? 
                                                <div className={`absolute top-2 right-2 text-xs body-bg px-2 py-0.5 rounded-sm font-bold text-red-400`}>Busy</div> 
                                                : 
                                                <div className={`absolute top-2 right-2 text-xs body-bg px-2 py-0.5 rounded-sm font-bold text-green-400`}>Available</div> 
                                            : null
                                        }
                                    </div> 
                                    : 
                                    <div className="snap-start flex w-full space-x-4 body-bg p-3 rounded-md text-gray-200" key={match._id}>
                                        <img className="shadow-lg shadow-black w-12 h-12 object-cover rounded-full ring-1 ring-white flex-none" src={match[sexKey].photoUrls[0]?.url ? match[sexKey].photoUrls[0]?.url : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                                        <div className="flex flex-col justify-center space-y-2 relative w-full">
                                            <div className="absolute top-0 left-0 flex items-center justify-start space-x-2 w-full">
                                                <div className="text-xs">{match[sexKey].firstName} {match[sexKey].lastName}</div>
                                                { match?.isActiveForModaters ?
                                                    !match[sexKey].activeStatus ? 
                                                        <div className={`text-xs nav-bg px-2 py-0.5 rounded-sm font-bold text-red-400`}>Busy</div> 
                                                        : 
                                                        <div className={`text-xs nav-bg px-2 py-0.5 rounded-sm font-bold text-green-400`}>Available</div> 
                                                    : null
                                                }
                                                { !match?.isActiveForModaters &&
                                                    <div className="text-gray-400 flex items-center text-sm space-x-1">
                                                        <span className="text-xs">Liked on</span>
                                                        <span className="font-sans font-medium text-gray-300 text-xs">{moment(match?.[updatedKey]?.dateUpdated).format('MM/DD/YYYY')}</span>
                                                    </div>
                                                }
                                            </div>
                                            { match?.expirationDetails?.expired ? 
                                                <div className="absolute bottom-0 left-0 px-2 border nav-bg border-red-400 rounded-sm text-red-400 tracking-widest uppercase font-sans font-bold text-xs">expired</div>
                                                : (match?.[reviewKey]?.reviewed && !match?.[reviewKey]?.LikedMatch) ?
                                                <div className="absolute bottom-0 left-0 px-2 border nav-bg border-red-400 rounded-sm text-red-400 tracking-widest uppercase font-sans font-bold text-xs">not a fit</div>
                                                : (match?.[reviewKey]?.reviewed && match?.[reviewKey]?.LikedMatch) ? 
                                                <div className="absolute bottom-0 left-0 px-2 border nav-bg border-pink-400 rounded-sm text-pink-400 tracking-widest uppercase font-sans font-bold text-xs">Matched</div>
                                                : 
                                                <div className="absolute bottom-0 left-0 text-gray-400 flex space-x-3 items-center">
                                                    <div className="px-2 border nav-bg border-green-400 rounded-sm text-green-400 tracking-widest uppercase font-sans font-bold text-xs">active</div>
                                                    <div className="flex space-x-1 items-center">
                                                        <span className="text-xs">Expires in</span>
                                                        <span className="text-sm special tracking-widest text-gray-300">{moment(match?.timeTillExpiration).diff(Date.now(), 'days')}</span>
                                                        <span className="text-xs">Days</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                            )
                        }) :
                        <div className="flex justify-center items-center w-full h-full text-gray-300">
                            nothing to see here
                        </div>
                }
            </div>
        </div>
    )
}

export default LikedMatches