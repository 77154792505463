import React, { useEffect, useRef } from 'react';
import './nav.css';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';

function InfoModal({ header, body, setModal }) {

    const elRef = useRef();

    useEffect(() => {
        const onClick = (e) => {
            if(!elRef?.current?.contains(e.target)) {
                setModal(prev => !prev);
            }
        }
        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick)
        }
    }, [])

    return (
        <div className="w-full h-full absolute flex justify-center items-center z-10 bg-black bg-opacity-20">
            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} className="w-72 sm:w-96 relative" ref={elRef}>
                <button className="absolute z-10 top-4 right-4 hover:text-gray-500 text-gray-300" onClick={(e) => {
                    e.stopPropagation();
                    setModal(prev => !prev)
                }}><CloseIcon /></button>
                <div className="flex flex-col nav-bg rounded-lg p-4 space-y-8 border border-gray-700 text-gray-300">
                    <div className="text-sm sm:text-base font-medium tracking-wider mt-10 underline text-center">{header}</div>
                    <div className="text-xs sm:text-sm text-center tracking-wide font-light">{body}</div>
                    <div className="w-full flex justify-center item-center space-x-8 text-black">
                        <button className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800" onClick={(e) => {
                            e.stopPropagation();
                            setModal(prev => !prev)
                        }}>Got It</button>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default InfoModal
