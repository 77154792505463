import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import CardProfile from '../Components/CardProfile';
import Modal from '../Components/Modal';
import InfoModal from '../Components/InfoModal';
import ExtraInfoForm from '../Components/ExtraInfoForm';
import { TokenContext } from '../Components/utilities/accessTokenContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { changeActiveStatusOfModater, getModater, getRefreshTokenBeforeApiCall, updateExtraInfo } from '../Components/utilities/EndPoints';
import ExtraInfoView from '../Components/ExtraInfoView';
import { getRelationToJudaism } from '../Components/utilities/funcs';
import '../Components/nav.css';

function ModaterProfile() {

    const { token, setToken, setLoggedIn, navigate, setModaters, setError, setSuccess } = useContext(TokenContext);
    const [modater, setModater] = useState();
    const [extraInfo, setExtraInfo] = useState(false);
    const [modal, setModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const { id } = useParams();

    // console.log(modater)

    const schema = yup.object().shape({

        q1: yup.string().required('You need to choose an option').nullable(),
        q2: yup.string().required('You need to choose an option').nullable(),
        q3: yup.string().required('You need to choose an option').nullable(),
        q4: yup.string().required('You need to choose an option').nullable(),
        q5: yup.string().required('You need to choose an option').nullable(),
        q6: yup.string().required('You need to choose an option').nullable(),
        q7: yup.string().required('You need to choose an option').nullable(),
        q8: yup.string().required('You need to choose an option').nullable(),
        
    })

    const { register, handleSubmit, reset, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const submitForm = async data => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, updateExtraInfo, [data, modater._id, setDisabled, setModaters, setModater, setError, setExtraInfo, setSuccess]);
    }

    const changeStatusHandler = () => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, changeActiveStatusOfModater, [modater?._id, setModaters, setModater, setModal, setError, setSuccess, setDisabled])
    }

    const onEditClick = () => {
        setExtraInfo(prev => !prev)
        reset({
            q1: modater?.extraInfo?.q1,
            q2: modater?.extraInfo?.q2,
            q3: modater?.extraInfo?.q3,
            q4: modater?.extraInfo?.q4,
            q5: modater?.extraInfo?.q5,
            q6: modater?.extraInfo?.q6,
            q7: modater?.extraInfo?.q7,
            q8: modater?.extraInfo?.q8
        })
    }

    const handleClick = (modater) => {
        if (modater.isDating.status) {
            setInfoModal(prev => !prev);
        }
        else {
            setModal(prev => !prev);
        }
    }
    
    useEffect(() => {
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getModater, [id, setModater, setError, navigate]);
    }, [token])

    // console.log(modater)

    return (
        <div className="w-full h-full relative">

            { modal && 
                <Modal
                    disabled={disabled}
                    setModal={setModal}
                    rejectHandler={changeStatusHandler}
                    header={`Are you sure you want to ${modater?.activeStatus ? 'deactivate' : 'activate'} ${modater?.firstName} ${modater?.lastName}`}
                    body={`If you proceed your MoDater ${modater?.activeStatus ? 'will not get new matches the next time matches are generated' : 'will get new matches the next time matches are generated'}`}
                />
            }

            { infoModal && 
                <InfoModal 
                    header="You can't activate your Modater when they are in middle of Dating someone"
                    body="Once your modater closes out their dating status, you will once again be able to activate them"
                    setModal={setInfoModal}
                />
            }



            { modater && 
                <div className="w-full md:h-full flex flex-col xl:flex-row py-12 px-2 md:py-6">
                    <div className="w-full h-full flex flex-col-reverse md:flex-row md:mb-12 xl:w-2/3 p-4">
                        <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} transition={{ delay: 0 }} className="w-full h-full  text-gray-300 space-y-4 md:overflow-y-scroll">
                    {/* <Link to="/dashboard/connectorDashboard"><button className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800">Back to Dashboard</button></Link>  */}
                            <div className="flex items-center sticky top-0 body-bg">
                                <div className="text-lg font-bold tracking-wider uppercase special">{`${modater.firstName} ${modater.lastName},`}</div>
                                <div className="text-2xl ml-2 tracking-widest font-light special">{new Date(Date.now() - new Date(modater.dateOfBirth)).getFullYear() - 1970}</div>
                                <div className="text-xl tracking-wider uppercase ml-4 special">{modater?.community ? modater.community : ''}</div>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="tracking-wide font-medium uppercase text-xs">Connector - {modater.connectorId?.firstName} {modater.connectorId?.lastName}</div>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="tracking-wide uppercase text-xs">{modater.vocation} -</div>
                                <div className="tracking-wide uppercase text-xs">{modater.college}</div>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="tracking-wide uppercase text-xs">High School -</div>
                                <div className="tracking-wide uppercase text-xs">{modater.highSchool}</div>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="tracking-wide uppercase text-xs">Year In Israel -</div>
                                <div className="tracking-wide uppercase text-xs">{modater.israelSchool}</div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <div className="tracking-wider font-medium uppercase text-blue-200 text-base special">Location</div>
                                <div className="flex space-x-2 text-xs">
                                    <div className="uppercase tracking-wide">{modater.city},</div>
                                    <div className="uppercase tracking-wide">{modater.state}</div>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-base special">A Little About Me</div>
                                <div className="tracking-wide font-light leading-7 ">{modater.bio}</div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-base special">Vacation Preference</div>
                                <div className="tracking-wide font-light leading-7 ">{modater.vacationPreference}</div>
                            </div>
                            <div className="flex flex-col">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Activities</div>
                                <div className="flex flex-wrap">
                                    {modater.activities.map((activity, index) => {
                                        return <div key={index} className="uppercase text-sm font-medium bg-indigo-300 px-2 py-0.5 mr-3 mt-3 rounded-sm"><span className="text-black">{activity}</span></div>
                                    })}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-base special">Personality Traits</div>
                                <div className="flex flex-wrap">
                                    {modater.characteristics.map((characteristic, index) => {
                                        return <div key={index} className="uppercase text-sm nav-bg px-2 py-0.5 mr-3 mt-3 rounded-sm"><span>{characteristic}</span></div>
                                    })}
                                </div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-base special">Religious Perspective</div>
                                <div className="tracking-wide font-light uppercase text-sm">{getRelationToJudaism(modater.relationToJudaism)}</div>
                            </div>
                            <div className="flex flex-col space-y-1">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-base special">Religious Observance</div>
                                <div className="tracking-wide font-light leading-7 space-x-3"><span>Shabbos:</span><span className="uppercase text-sm">{modater.shabbos}</span></div>
                                <div className="tracking-wide font-light leading-7 space-x-3"><span>Kosher:</span><span className="uppercase text-sm">{modater.kosher}</span></div>
                                <div className="tracking-wide font-light leading-7 space-x-3"><span>Shomer Negiyah:</span><span className="uppercase text-sm">{modater.shomerNegiah}</span></div>
                                <div className="tracking-wide font-light leading-7 space-x-3"><span>Learns:</span><span className="uppercase text-sm">{modater.learns}</span></div>
                                <div className="tracking-wide font-light leading-7 space-x-3"><span>Aliyah:</span><span className="uppercase text-sm">{modater.aliyah}</span></div>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Political Preference</div>
                                <div className="tracking-wide font-light uppercase text-sm">{modater.politics}</div>
                            </div>                      
                            <div className="flex flex-col space-y-2">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Previously Married</div>
                                <div className="tracking-wide font-light uppercase text-sm">{modater.divorcedStatus === 'Yes' ? 'Yes' : 'Never'}</div>
                            </div>                      
                            <div className="flex flex-col space-y-2">
                                <div className="tracking-wide font-medium text-blue-200 uppercase text-md special">Children</div>
                                <div className="tracking-wide font-light uppercase text-sm">{modater.childrenStatus === "Yes" ? "Yes" : "Doesn't Have Children"}</div>
                            </div>                     
                        </motion.div>
    
                        <div className="w-full h-full flex flex-col items-center justify-center mb-8 lg:mb-0 space-y-8">
                            <div className="flex justify-center items-center w-full space-x-8">
                                <div className="text-sm text-gray-300 tracking-widest">Status: <span className={`${modater.activeStatus ? 'text-green-500' : 'text-red-500'} text-sm`}>{modater.activeStatus ? 'ACTIVE' : 'INACTIVE'}</span></div>
                                <button className={`text-blue-200 px-3 py-0.5 rounded-sm nav-bg text-sm`} onClick={() => handleClick(modater)}>Change Status</button>
                            </div>
                            <div className="relative">
                                <CardProfile user={{modaterProfileId: modater}} urls={modater.photoUrls} />
                                { modater?.isDating?.status && 
                                    <motion.div className="absolute top-0 w-full h-full bg-black bg-opacity-30" initial={{opacity: 0}} animate={{opacity: 1}}>
                                        <motion.div 
                                            initial={{opacity: 0, y: -200, x: 0, scale: 1, rotate: 0}} 
                                            animate={{opacity:1, y: 0, x:25, scale: 1.5, rotate: -25}} 
                                            // transition={{ from: 90, duration: .5 }}
                                            className="absolute top-24 left-10 py-1 px-3 border-2 border-green-400 rounded-sm text-green-400 tracking-widest uppercase font-sans font-bold">
                                                Dating
                                        </motion.div>
                                    </motion.div>
                                }
                            </div>
                        </div>
                    </div>
            
                    <motion.div initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0}} transition={{ delay: .1 }} className="w-full h-full xl:w-1/3 text-gray-300 p-4">
                        { extraInfo ?
                            <div className="flex flex-col h-full w-full">
                                <div className="w-full overflow-y-visible xl:overflow-y-scroll">
                                    <ExtraInfoForm 
                                        register={register}
                                        errors={errors}
                                    />
                                </div>
                                <div className="flex justify-center mt-8 space-x-8">
                                    <div className="">
                                        <button className="text-sm nav-bg px-6 py-1 rounded-sm text-blue-200 border border-gray-800 inline-block" onClick={() => setExtraInfo(prev => !prev)}>Cancel</button>
                                    </div>
                                    <div className="">
                                        <button className="text-sm nav-bg px-6 py-1 rounded-sm text-blue-200 border border-gray-800 inline-block" onClick={handleSubmit(submitForm)} disabled={disabled}>Submit Changes</button>
                                    </div>
                                </div>
                            </div> :
                            <div className="flex flex-col h-full w-full">
                                <div className="text-sm font-medium tracking-wider mb-6 font-sans md:text-center xl:text-left text-blue-200 uppercase special">What the Connector had to say about {`${modater.firstName} ${modater.lastName}`}</div>

                                <ExtraInfoView modater={modater} />
                
                                <div className="flex justify-center mt-12">
                                    <button className="text-sm nav-bg px-6 py-1 rounded-sm text-blue-200 border border-gray-800 inline-block" onClick={onEditClick}>Edit Extra Info</button>
                                </div>
                            </div>
                        }
                    </motion.div>

                </div>
            }
        </div>
    )
}

export default ModaterProfile
