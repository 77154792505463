import React, { useState, useEffect, useContext } from 'react'
import AddButton from './AddButton';
import Modal from '../Modal'
import { getRefreshTokenBeforeApiCall, createMatchBetweenModaters } from '../../utilities/EndPoints';
import { TokenContext } from '../../utilities/accessTokenContext';

function MatchModal({ setModal }) {

    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false)
    const { token, setToken, setLoggedIn, navigate, setError, setSuccess } = useContext(TokenContext);

    const onModalClickHandler = (e) => {
        e.stopPropagation();
        setModal(prev => !prev);
        document.body.style.overflow = "auto"
    }

    const ModalChild = ({ setDisabled }) => {
        
        useEffect(() => {
            if (selected.length !== 2 || loading) {
                setDisabled(true);
            }
            else {
                setDisabled(selected.reduce((acc, cur) => {
                    if (!cur) {
                        return acc || true;
                    }
                    return acc;
                }, false) || loading)
            }
        }, [selected, loading])
        
        return (
            <div className="flex sm:flex-row sm:space-x-4 flex-col space-y-4 sm:space-y-0">
                <AddButton selected={selected[0]} setSelected={setSelected} index={0} setDisabled={setDisabled}/>
                <AddButton selected={selected[1]} setSelected={setSelected} index={1} setDisabled={setDisabled}/>
            </div>
        )
    }

    const modalSubmit = (selected) => {
        const data = {modaterIds: [selected[0]?.value, selected[1]?.value]}
        getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, createMatchBetweenModaters, [data, setError, setSuccess, setModal, setLoading, setToken])
    }
    
    return (
        <Modal 
            title="Create match between Modaters" 
            onCloseHandler={onModalClickHandler}
            onSubmitHandler={() => modalSubmit(selected) }
            submitButtonText="Create Match"
            cancelButtonText="Cancel"
            onPrevHandler={onModalClickHandler}
        >
            <ModalChild />
        </Modal>
    )
}

export default MatchModal;

