import React from 'react'
import { Link } from 'react-router-dom';
import ToolTip from '../Components/ToolTip';
import InfoIcon from '@mui/icons-material/Info';
import { motion } from 'framer-motion';
import moment from 'moment';

function CardPreview({imagePreviewUrl, user, matchId, setModal, setId, isAMatch, reviewed, likedMatch, id, timeTillExpiration}) {

    const timeInDays = moment(timeTillExpiration).diff(Date.now(), 'days');

    return (
        <div className={`h-[28rem] w-80 shadow-xl shadow-black border border-gray-800 rounded-lg special overflow-hidden relative ${user.activeStatus ? 'transform hover:scale-105 transition duration-300' : ''}`}>
            {/* image of person */}
            <div className="h-full w-full relative">
                { !isAMatch && reviewed && !likedMatch && matchId === id &&
                    <motion.div className="absolute top-0 w-full h-full bg-black bg-opacity-30 z-50" initial={{opacity: 0}} animate={{opacity: 1}}>
                        <motion.div 
                            // initial={{opacity: 0, y: -100, scale: 1, rotate: 0}} 
                            animate={{opacity:1, y: 0, x:25, scale: 1.3, rotate: -25}} 
                            transition={{ from: 90, duration: .5 }}
                            className="absolute top-24 left-10 py-2 px-3 border-2 border-red-400 text-red-400 rounded-sm tracking-widest uppercase font-sans font-bold">
                                Not A Fit
                        </motion.div>
                    </motion.div>
                }
                {imagePreviewUrl && imagePreviewUrl.url ? <img src={imagePreviewUrl.url} alt="" className="object-cover h-full w-full"/> : <img src="https://modate-photos.s3.amazonaws.com/blank-profile-picture.png" alt="" className="object-cover h-96 w-full"/>}
                {user.activeStatus ? 
                    <Link to={`/dashboard/modaterDashboard/reviewMatch/${matchId}`} className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-200 px-2 py-0.5 bg-gray-700 bg-opacity-50 hover:bg-opacity-80 rounded-sm uppercase tracking-wider text-sm`} >See Details</Link>:
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex space-x-2 items-center">
                        <button className={`text-red-200 px-2 py-0.5 bg-red-500 bg-opacity-50 hover:bg-opacity-80 rounded-sm uppercase tracking-wider text-sm ml-3`} onClick={(e) => {
                            e.stopPropagation();
                            setModal(true);
                            document.body.style.overflow = "hidden";
                            setId(matchId);
                        }}>Reject Match</button>
                        <ToolTip title="You cannot like matches in which the other modater is not active. However, if you would like to reject the match you may do so">
                            <InfoIcon fontSize="small" className="cursor-pointer text-red-300"/> 
                        </ToolTip> 
                    </div>
                }
            </div>
            <div className={`absolute top-2 left-2 px-2 py-0.5 bg-black bg-opacity-60 rounded-sm text-sm ${!user.activeStatus ? 'text-red-400' : 'text-green-400'}`}>
                {!user.activeStatus ? 'Busy' : 'Available'}
            </div>
            <div className={`absolute top-2 right-2 px-2 bg-black bg-opacity-60 rounded-sm  flex items-center space-x-1 text-gray-400`}>
                <span className="text-sm">Expires in</span>
                <span className={`${timeInDays > 20 ? 'text-green-400' : timeInDays > 10 ? 'text-orange-400' : 'text-red-400' }`}>{timeInDays}</span>
                <span className="text-sm">days</span>
            </div>
            {/* details about person */}
            <div className="p-4 absolute top-3/4 h-1/4 w-full bg-gray-700 bg-opacity-20">
                <div className="flex flex-col text-white space-y-2">
                    <div className="flex justify-between items-center">
                        {/* name and age of person */}
                        <div className="text-base uppercase flex items-center">{`${user.firstName} ${user.lastName}, `}
                            <span className="text-xl ml-2">{new Date(Date.now() - new Date(user.dateOfBirth)).getFullYear() - 1970}</span>
                        </div>
                        {/* persons location */}
                        <div className="text-xs uppercase">{user.city}, {user.state}</div>
                    </div>
                    {/* vocation and college */}
                    <div className="text-sm card"><span>{user.vocation}</span> - <span className="uppercase">{user.college}</span></div>
                </div>
            </div>
        </div>
    )
}

export default CardPreview
