import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getRefreshTokenBeforeApiCall, getModaterOptionsAdmin } from '../../utilities/EndPoints';
import { TokenContext } from '../../utilities/accessTokenContext';
import AsyncSelect from 'react-select/async';
import { STYLES } from '../../../constants';
import { components } from 'react-select';
const { Option } = components;

function AddButton({ selected, setSelected, index }) {

    const [show, setShow] = useState(false);
    const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);
    const _isMounted = useRef(true);

    const debounce = (func, delay) => {
        let id;
        return (...args) => {
            return new Promise(resolve => {
                if (id) { clearTimeout(id); }
                id = setTimeout(async () => {
                    const res = await func(...args);
                    resolve(res)
                }, delay);
            })
        }
    }
    
    const loadOptions = (input) => {
        return getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getModaterOptionsAdmin, [input, "GET", _isMounted]);
    }
    
    const debounced = useCallback(debounce(loadOptions, 400), [token]);

    const IconOption = (props) => {
        return <Option {...props}>
            <div className="flex items-center space-x-4">
                { props.data.img ? 
                    <img src={props.data.img} className="h-8 w-8 object-cover rounded-full ring-1 ring-gray-800"/> :
                    <img src="https://modate-photos.s3.amazonaws.com/blank-profile-picture.png" alt="" className="h-8 w-8 object-cover rounded-full ring-1 ring-gray-800"/>
                }
                <div className="text-md tracking-wider font-medium">{props.data.label}</div>
            </div>
        </Option>
    }

    const selectHandler = val => {
        setSelected(prevVals => {
            prevVals[index] = val;
            return [...prevVals]
        });
        setShow(prev => !prev);
    }

    const asyncClick = e => {
        e.stopPropagation();
    }

    const addDaterClick = (e) => {
        e.stopPropagation();
        if (selected) {
            setSelected(prevVals => {
                prevVals[index] = undefined;
                return [...prevVals]
            });
            setShow(false);
        }
        else {
            setShow(prev => !prev);
        }
    }

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    return (
        <div className="flex flex-col sm:w-48 w-full space-y-3">
            <div className={`h-20 w-full body-bg rounded-md border border-gray-800 shadow-black/40 shadow-lg flex justify-center items-center hover:border-gray-700 hover:text-white hover:cursor-pointer ${selected ? 'hover:bg-red-400' : ''}`} onClick={addDaterClick}>
                {!selected ?
                    <AddCircleIcon fontSize="large"/> :
                    <div className={`flex justify-center items-center px-3 py-2 space-x-4`}>
                        <div className="relative w-12 h-12">
                            <img className={`shadow-lg shadow-black h-full w-full object-cover rounded-full ring-1 ring-gray-700 outline-none`} src={selected?.img ? selected?.img : 'https://modate-photos.s3.amazonaws.com/blank-profile-picture.png'} alt="" />
                        </div>
                        <div className={`text-ellipsis overflow-hidden text-sm`}>{selected?.label}</div>
                    </div>
                }
            </div>
            { show && 
                <div onClick={asyncClick}>
                    <AsyncSelect
                        components={{Option:IconOption}}
                        loadOptions={debounced}
                        isClearable
                        onChange={selectHandler}
                        value={selected}
                        styles={STYLES}
                        autoFocus
                    />
                </div>
            }
        </div>
    )
}

export default AddButton
