import React, { useState, useEffect } from 'react';

export const useLocalStorage = (key, defaultValue) => {
    // console.log(defaultValue)
    const [state, setState] = useState(defaultValue);

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [state])

    return [state, setState];
}

