import React, { useEffect } from 'react';

function Banner({ color, error, message, disappearing, setError }) {

    useEffect(() => {
        if (disappearing) {
            const timeId = setTimeout(() => {
                setError(errors => errors.filter(err => err.id !== error.id))
            }, 3000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, []);

    return (
        <div className={`w-full ${color} text-white text-xs text-center`}>
            <div className="px-1 py-0.5 tracking-wider mr-72">
                {error && (error.message ? error.message.toUpperCase() : 'SOMETHING WENT WRONG')}
                {message && message.toUpperCase()}
            </div>
        </div>
    )
}

export default Banner
