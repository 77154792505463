import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import './nav.css';
import { motion } from 'framer-motion'
import CloseIcon from '@mui/icons-material/Close';
import { TokenContext } from './utilities/accessTokenContext';
import AsyncSelect from 'react-select/async';
import { STYLES } from '../constants.js'
import { getModaterOptionsAdmin, getRefreshTokenBeforeApiCall } from './utilities/EndPoints';
import { components } from 'react-select';
const { Option } = components;


function DeleteModaterModal({ setModal, selected, setSelected, rejectHandler, disabled }) {

    const [input, setInput] = useState("");
    const [deleteText, setDeleteText] = useState("");
    const { token, setToken, setLoggedIn, navigate } = useContext(TokenContext);
    const _isMounted = useRef(true);

    const debounce = (func, delay) => {
        let id;
        return (...args) => {
            return new Promise(resolve => {
                if (id) { clearTimeout(id); }
                id = setTimeout(async () => {
                    const res = await func(...args);
                    resolve(res)
                }, delay);
            })
        }
    }
    
    const loadOptions = (input) => {
        return getRefreshTokenBeforeApiCall(token, setToken, setLoggedIn, navigate, getModaterOptionsAdmin, [input, "DELETE", _isMounted]);
    }
    
    const debounced = useCallback(debounce(loadOptions, 400), [token]);

    const checkIfDisabled = () => {
        if (disabled || !selected || deleteText !== 'delete') {
            return true;
        }
        return false;
    }

    const onClickHandler = (e) => {
        setModal(prev => !prev);
        document.body.style.overflow = "auto"
    }

    const onDeleteHandler = () => {
        document.body.style.overflow = "auto";
        rejectHandler()
    }

    const selectHandler = (val) => {
        setSelected(val);
        setDeleteText("");
    }

    const IconOption = (props) => {
        return <Option {...props}>
            <div className="flex items-center space-x-4">
                { props.data.img ? 
                    <img src={props.data.img} className="h-8 w-8 object-cover rounded-full ring-1 ring-gray-800"/> :
                    <img src="https://modate-photos.s3.amazonaws.com/blank-profile-picture.png" alt="" className="h-8 w-8 object-cover rounded-full ring-1 ring-gray-800"/>
                }
                <div className="text-md tracking-wider font-medium">{props.data.label}</div>
            </div>
        </Option>
    }

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    const buttonIsDisabled = checkIfDisabled();

    return (
        <div className="w-full h-[100vh] absolute -top-20 flex justify-center items-center z-50 bg-black bg-opacity-20">
            <motion.div initial={{opacity: 0, x: -200}} animate={{opacity: 1, x: 0}} className="w-72 sm:w-96 relative">
                <button className="absolute z-10 top-4 right-4 hover:text-gray-500 text-gray-300" onClick={onClickHandler}><CloseIcon /></button>
                <div className="flex flex-col bg-black rounded-lg p-4 space-y-8 ring-1 ring-gray-300 ring-opacity-20 text-gray-300 shadow-xl shadow-black">
                    <div className="text-sm sm:text-base font-light tracking-wider mt-10 text-center text-white">Delete Modater Account</div>
                    <div className="flex justify-center">
                        <div className="w-[80%] flex flex-col items-center space-y-2">
                            <div className="ml-2 w-full text-xs sm:text-sm flex justify-start tracking-wide font-light">Search Name</div>
                            <div className="w-full flex space-x-4 justify-start">
                                <AsyncSelect
                                    components={{Option:IconOption}}
                                    loadOptions={debounced}
                                    isClearable
                                    onInputChange={setInput}
                                    onChange={selectHandler}
                                    value={selected}
                                    className="w-[300px]"
                                    styles={STYLES}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        selected && 
                            <motion.div initial={{opacity: 0, y: -200}} animate={{opacity: 1, y: 0}} className="flex flex-col items-center justify-start space-y-2">
                                <div className="ml-2 w-[80%] text-sm font-light">Type "delete" to permanently delete user</div>
                                <input type="text" className="sidebar-bg outline-none px-4 py-1 w-[80%] border border-gray-800 rounded-sm" placeholder="delete" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                            </motion.div>
                    }
                    <div className="w-full flex justify-center item-center space-x-8 text-black">
                        <button className="text-sm nav-bg px-3 py-1 rounded-sm text-blue-200 border border-gray-800" onClick={onClickHandler}>Cancel</button>
                        <button className={`text-sm px-3 py-1 rounded-sm border border-gray-800 ${buttonIsDisabled ? 'nav-bg text-red-400' : 'bg-red-400 text-black'}`} onClick={onDeleteHandler} disabled={buttonIsDisabled}>Delete User</button>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default DeleteModaterModal